import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    Divider,
    IconButton,
    Typography,
    useMediaQuery,
    Stack,
    Paper,
} from "@mui/material";
import {
    AppDotPagination,
    Title,
    AppDotPaginationwBack,
    HeaderFooter,
    Loading,
    PrivateChefMobileDotPagination,
} from "../components/UI";
import {
    PRIVATE_CHEF_PAGINATION,
    toTimeIntervalString,
    toTimeString,
    useCurrentGuest,
    useCurrentRecipients,
    useCurrentServiceOrder,
    useCurrentHost,
    capitalizeFirstLetter,
    djangoEndpointPromise,
    mergeMultipleResults,
    fetchResultType,
    toDateString,
} from "../util";
import logo from "../assets/ellipse.png";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/CreateOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import PrivateChefPhoto from "../assets/privatechef.png";
import {
    LINK_TO_CANCELLATION_POLICY,
    LINK_TO_SATISFACTION_GUARANTEE,
} from "../urls";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function useGetRequestDetails(orderHook) {
    const [requestDetails, setRequestDetails] = useState({});
    const [order, orderTryLoad, _setOrder] = orderHook;

    const orderId = order.order_id;
    const url = "private-chef-menu/retrieve-request-details";
    const tryLoad = useCallback(async () => {
        try {
            if (orderId == null) {
                return;
            }
            const response = await djangoEndpointPromise(url, {
                order_id: orderId,
            });

            if (fetchResultType(response) === "error") {
                throw new Error(
                    "Server Error: Something went wrong on the server."
                );
            }
            setRequestDetails(response.data);
        } catch (error) {
            console.error("Error with backend:", error);
        }
    }, [orderId]);
    useEffect(() => {
        tryLoad();
    }, [tryLoad, orderId]);

    return [requestDetails, tryLoad, setRequestDetails];
}

const StackVariant = ({ children }) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {children}
        </Stack>
    );
};

const Item = ({ left, right, rightFontWeight }) => {
    return (
        <StackVariant>
            {left && <Typography variant="body1">{left}</Typography>}
            <Typography variant="body1" fontWeight={rightFontWeight}>
                {right}
            </Typography>
        </StackVariant>
    );
};

const ItemCard = ({ heading, children, onEdit }) => {
    return (
        <Card
            sx={{
                width: "100%",
            }}
        >
            <CardContent>
                <StackVariant>
                    <Typography variant="h2" marginBottom={0}>
                        {heading}
                    </Typography>
                    <IconButton aria-label="Edit" onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </StackVariant>
                {children}
            </CardContent>
        </Card>
    );
};

const CourseItemList = ({ courses }) => {
    const courseNames = Object.keys(courses);

    return (
        <>
            {courseNames.map((course, index) =>
                courses[course].map((dish, dishIndex) => (
                    <>
                        <Item
                            key={`${course}-${dishIndex}`}
                            left={
                                <Subtitle
                                    subtitle={
                                        dishIndex === 0
                                            ? `${capitalizeFirstLetter(course)}`
                                            : ""
                                    }
                                />
                            }
                            right={
                                <Value value={capitalizeFirstLetter(dish)} />
                            }
                        />
                        {index + 1 < courseNames.length &&
                            dishIndex + 1 === courses[course].length && (
                                <Divider />
                            )}
                    </>
                ))
            )}
        </>
    );
};

const RecipientList = ({ recipients }) => {
    return (
        <>
            {recipients.map((recipient, index) => (
                <>
                    <Item
                        key={recipient.person_id}
                        left={<Subtitle subtitle={`Guest ${index + 1}`} />}
                        right={
                            <Value
                                value={`${capitalizeFirstLetter(
                                    recipient.first_name
                                )} ${capitalizeFirstLetter(
                                    recipient.last_name
                                )}`}
                            />
                        }
                    />
                    <StackVariant>
                        {<Subtitle subtitle={""} /> && (
                            <Typography variant="body1">
                                {<Subtitle subtitle={""} />}
                            </Typography>
                        )}
                        <Typography
                            variant="body1"
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "19.36px",

                                color: "#717171",
                            }}
                        >
                            {capitalizeFirstLetter(recipient.allergies)}
                        </Typography>
                    </StackVariant>

                    {index + 1 < recipients.length && <Divider />}
                </>
            ))}
        </>
    );
};

const CardTitle = ({ title }) => {
    return (
        <span
            style={{
                color: "#000000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "19.36px",
            }}
        >
            {title}
        </span>
    );
};

const Subtitle = ({ subtitle }) => {
    return (
        <span
            style={{
                color: "#717171",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.36px",
            }}
        >
            {subtitle}
        </span>
    );
};

const Value = ({ value }) => {
    return (
        <span
            style={{
                color: "#000000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "19.36px",
            }}
        >
            {value}
        </span>
    );
};

const Loaded = ({
    orderHook,
    guestHook,
    recipientsHook,
    hostHook,
    requestDetails,
}) => {
    const [order, _orderTryLoad, setOrder] = orderHook;
    const [guest, _guestTryLoad, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryLoad, setRecipients] = recipientsHook;
    const [hostInfo, _hostTryLoad, setHost] = hostHook;
    const navigate = useNavigate();
    const startDate = dayjs.utc(order.time_requested).tz(order.timezone);

    const endDate = startDate.add(order.duration_requested, "minute");

    const recipientsElements = recipientsInfo.include_guest_as_recipient
        ? [guest, ...recipientsInfo.recipients]
        : recipientsInfo.recipients;

    const queryString = new URLSearchParams({ order_id: order.order_id }).toString();
    const policiesUrl = `${window.location.origin}/policies?${queryString}`;

    console.log(policiesUrl);
    
    const Mobile = useMediaQuery("(max-width:600px)");
    const Large = useMediaQuery("(min-width:1400px)");
    const minWidthValue = Mobile ? "0px" : "1512px";
    const backgroundColor = Mobile ? "#F2F2F2" : "white";
    const padding = Large ? "163.63px" : "5px";

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: minWidthValue,
                width: "100%",
                overflowX: "auto",
                scrollBehavior: "smooth",
                background: backgroundColor,
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={!Mobile}>
                {Mobile && (
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            flexGrow: 1,
                            width: "100%",
                            height: "100%",
                            overflowY: "auto",
                            paddingBottom: "8px",
                            marginBottom: "0px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "5px",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <PrivateChefMobileDotPagination
                                page={5}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    lineHeight: "24.2px",
                                    textAlign: "center",
                                    marginTop: "-10px",
                                }}
                            >
                                Request Details
                            </Typography>
                            <Stack
                                direction="column"
                                spacing="12px"
                                sx={{ marginTop: "12px", width: "92%" }}
                            >
                                <img
                                    src={PrivateChefPhoto}
                                    alt="Fine Dining Meal"
                                    style={{
                                        width: "100%",
                                        borderRadius: "12px",
                                    }}
                                />
                                <ItemCard
                                    heading={
                                        <CardTitle title="Booking Information" />
                                    }
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[0])
                                    }
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Item
                                            left={
                                                <Subtitle subtitle="Private Chef" />
                                            }
                                            right={
                                                <Value
                                                    value={capitalizeFirstLetter(
                                                        order.private_chef_bld
                                                    )}
                                                />
                                            }
                                        />
                                        <Item
                                            left={
                                                <Subtitle subtitle="Guests" />
                                            }
                                            right={
                                                <Value
                                                    value={
                                                        order.private_chef_menu_num_guests
                                                    }
                                                />
                                            }
                                        />
                                    </Stack>
                                </ItemCard>
                                <ItemCard
                                    heading={
                                        <CardTitle title="Date, Time, & Menu" />
                                    }
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[1])
                                    }
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Item
                                            left={<Subtitle subtitle="Date" />}
                                            right={
                                                <Value
                                                    value={toDateString(
                                                        startDate
                                                    )}
                                                />
                                            }
                                        />
                                        <Item
                                            left={<Subtitle subtitle="Time" />}
                                            right={
                                                <Value
                                                    value={toTimeIntervalString(
                                                        startDate,
                                                        endDate
                                                    )}
                                                />
                                            }
                                        />
                                        <Item
                                            left={<Subtitle subtitle="Menu" />}
                                            right={
                                                <Value
                                                    value={capitalizeFirstLetter(
                                                        requestDetails.menu.name
                                                    )}
                                                />
                                            }
                                        />
                                    </Stack>
                                </ItemCard>
                                <ItemCard
                                    heading={
                                        <CardTitle title="Dish Selection" />
                                    }
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[3])
                                    }
                                >
                                    <Stack direction="column" spacing="15px">
                                        <CourseItemList
                                            courses={requestDetails.courses}
                                        />
                                    </Stack>
                                </ItemCard>

                                <ItemCard
                                    heading={
                                        <CardTitle title="Contact Information" />
                                    }
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[4])
                                    }
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Item
                                            left={<Subtitle subtitle="Guest" />}
                                            right={
                                                <Value
                                                    value={`${capitalizeFirstLetter(
                                                        guest.first_name
                                                    )} ${capitalizeFirstLetter(
                                                        guest.last_name
                                                    )}`}
                                                />
                                            }
                                        />
                                        <Item
                                            left={<Subtitle subtitle="Email" />}
                                            right={
                                                <Value value={guest.email} />
                                            }
                                        />
                                        <Item
                                            left={
                                                <Subtitle subtitle="Phone Number" />
                                            }
                                            right={
                                                <Value
                                                    value={guest.phone_number}
                                                />
                                            }
                                        />
                                    </Stack>
                                </ItemCard>
                                <ItemCard
                                    heading={<CardTitle title="Guests" />}
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[4])
                                    }
                                >
                                    <Stack direction="column" spacing="15px">
                                        <RecipientList
                                            recipients={recipientsElements}
                                        />
                                    </Stack>
                                </ItemCard>
                                <ItemCard
                                    heading={
                                        <CardTitle title="Additional Instructions" />
                                    }
                                    onEdit={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[4])
                                    }
                                >
                                    <Item
                                        left={
                                            <Subtitle
                                                subtitle={
                                                    order.special_requests ===
                                                    ""
                                                        ? "None"
                                                        : order.special_requests
                                                }
                                            />
                                        }
                                    />
                                </ItemCard>

                                <Stack direction="column" spacing="16px">
                                    <Card
                                        sx={{ width: "100%", height: "164px" }}
                                    >
                                        <CardContent>
                                            <Stack
                                                direction="column"
                                                spacing="11px"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        fontWeight: "500",
                                                        lineHeight: "19.36px",
                                                        textAlign: "left",
                                                        color: "black",
                                                        //marginBottom: "17px",
                                                    }}
                                                >
                                                    Order summary
                                                </Typography>
                                                <Item
                                                    left={
                                                        <span
                                                            style={{
                                                                color: "#717171",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "400",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            Subtotal
                                                        </span>
                                                    }
                                                    right={
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "500",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            {"$" +
                                                                requestDetails.priceTotal}
                                                        </span>
                                                    }
                                                />
                                                <Item
                                                    left={
                                                        <span
                                                            style={{
                                                                color: "#717171",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "400",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            Tax
                                                        </span>
                                                    }
                                                    right={
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "500",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            {"$" +
                                                                (
                                                                    requestDetails.priceTotal *
                                                                    0.0725
                                                                ).toFixed(2)}
                                                        </span>
                                                    }
                                                />
                                                <Divider />
                                                <Item
                                                    left={
                                                        <span
                                                            style={{
                                                                color: "#1D1B20",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "400",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            Total
                                                        </span>
                                                    }
                                                    right={
                                                        <span
                                                            style={{
                                                                color: "black",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "700",
                                                                lineHeight:
                                                                    "16.94px",
                                                            }}
                                                        >
                                                            {"$" +
                                                                (
                                                                    requestDetails.priceTotal *
                                                                    1.0725
                                                                ).toFixed(2)}
                                                        </span>
                                                    }
                                                />
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "19.36px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Policies
                                    </Typography>
                                    <Stack
                                        direction="column"
                                        spacing="12px"
                                        sx={{ width: "100%" }}
                                    >
                                        <ButtonBase
                                            component="button"
                                            onClick={() =>
                                                window.open(
                                                    policiesUrl,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            <Paper
                                                sx={{
                                                    padding: 1,
                                                    paddingLeft: 2,
                                                    width: "100%",
                                                }}
                                            >
                                                <StackVariant>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        Satisfaction guarantee
                                                    </Typography>
                                                    <ChevronRightIcon />
                                                </StackVariant>
                                            </Paper>
                                        </ButtonBase>
                                        <ButtonBase
                                            component="button"
                                            onClick={() =>
                                                window.open(
                                                    policiesUrl,
                                                    "_blank"
                                                )
                                            }
                                        >
                                            <Paper
                                                sx={{
                                                    padding: 1,
                                                    paddingLeft: 2,
                                                    width: "100%",
                                                }}
                                            >
                                                <StackVariant>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                            textAlign: "left",
                                                        }}
                                                    >
                                                        Cancellation policy
                                                    </Typography>
                                                    <ChevronRightIcon />
                                                </StackVariant>
                                            </Paper>
                                        </ButtonBase>
                                    </Stack>
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            lineHeight: "19.36px",
                                            textAlign: "left",
                                        }}
                                    >
                                        Your payment method won't be charged
                                        until your service is confirmed.
                                    </Typography>
                                    <Button
                                        onClick={() =>
                                            navigate("/stripe/checkout")
                                        }
                                        sx={{
                                            width: "100%",
                                            height: "44px",
                                            gap: "8px",
                                            borderRadius: "100px",
                                            opacity: "0px",
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                            letterSpacing:
                                                "0.10000000149011612px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Request booking
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Box>
                )}

                {!Mobile && (
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                            paddingBottom: "125px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "97px",
                                marginLeft: "100px",
                                marginRight: "100px",
                                alignItems: "flex-start",
                                width: "100%",
                            }}
                        >
                            <Title title="Request Details" />
                            <AppDotPaginationwBack
                                page={5}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />
                            <Stack
                                direction={Large ? "row" : "column"}
                                spacing="39px"
                                sx={{ marginTop: "18.04px" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        padding: "30px 60px",
                                        gap: "15px",
                                        marginBottom: padding,
                                        width: "798px",
                                        // height: "925.37px",
                                        background: "#EDEDED",
                                        borderRadius: "20px",
                                    }}
                                >
                                    <ItemCard
                                        heading={
                                            <CardTitle title="Booking Information" />
                                        }
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[0])
                                        }
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="15px"
                                        >
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Private Chef" />
                                                }
                                                right={
                                                    <Value
                                                        value={capitalizeFirstLetter(
                                                            order.private_chef_bld
                                                        )}
                                                    />
                                                }
                                            />
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Guests" />
                                                }
                                                right={
                                                    <Value
                                                        value={
                                                            order.private_chef_menu_num_guests
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    </ItemCard>
                                    <ItemCard
                                        heading={
                                            <CardTitle title="Date, Time, & Menu" />
                                        }
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[1])
                                        }
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="15px"
                                        >
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Date" />
                                                }
                                                right={
                                                    <Value
                                                        value={toDateString(
                                                            startDate
                                                        )}
                                                    />
                                                }
                                            />
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Time" />
                                                }
                                                right={
                                                    <Value
                                                        value={toTimeString(
                                                            startDate
                                                        )}
                                                    />
                                                }
                                            />
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Menu" />
                                                }
                                                right={
                                                    <Value
                                                        value={capitalizeFirstLetter(
                                                            requestDetails.menu
                                                                .name
                                                        )}
                                                    />
                                                }
                                            />
                                        </Stack>
                                    </ItemCard>
                                    <ItemCard
                                        heading={
                                            <CardTitle title="Dish Selection" />
                                        }
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[3])
                                        }
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="15px"
                                        >
                                            <CourseItemList
                                                courses={requestDetails.courses}
                                            />
                                        </Stack>
                                    </ItemCard>

                                    <ItemCard
                                        heading={
                                            <CardTitle title="Contact Information" />
                                        }
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[4])
                                        }
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="15px"
                                        >
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Guest" />
                                                }
                                                right={
                                                    <Value
                                                        value={`${capitalizeFirstLetter(
                                                            guest.first_name
                                                        )} ${capitalizeFirstLetter(
                                                            guest.last_name
                                                        )}`}
                                                    />
                                                }
                                            />
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Email" />
                                                }
                                                right={
                                                    <Value
                                                        value={guest.email}
                                                    />
                                                }
                                            />
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Phone Number" />
                                                }
                                                right={
                                                    <Value
                                                        value={
                                                            guest.phone_number
                                                        }
                                                    />
                                                }
                                            />
                                        </Stack>
                                    </ItemCard>
                                    <ItemCard
                                        heading={<CardTitle title="Guests" />}
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[4])
                                        }
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="15px"
                                        >
                                            <RecipientList
                                                recipients={recipientsElements}
                                            />
                                        </Stack>
                                    </ItemCard>
                                    <ItemCard
                                        heading={
                                            <CardTitle title="Additional Instructions" />
                                        }
                                        onEdit={() =>
                                            navigate(PRIVATE_CHEF_PAGINATION[4])
                                        }
                                    >
                                        <Item
                                            left={
                                                <Subtitle
                                                    subtitle={
                                                        order.special_requests ===
                                                        ""
                                                            ? "None"
                                                            : order.special_requests
                                                    }
                                                />
                                            }
                                        />
                                    </ItemCard>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        padding: "31px 34px",
                                        gap: "16px",
                                        isolation: "isolate",
                                        width: "472px",
                                        height: "816px",
                                        background: "#EDEDED",
                                        borderRadius: "12px",
                                        marginRight: "100px",
                                        position: "sticky",
                                        top: "145px",
                                    }}
                                >
                                    <Stack direction="column" spacing="16px">
                                        <img
                                            src={PrivateChefPhoto}
                                            alt="Fine Dining Image"
                                            style={{
                                                width: "100%",
                                                height: "317px",
                                                borderRadius: "12px",
                                            }}
                                        />
                                        <Card
                                            sx={{
                                                width: "100%",
                                                height: "164px",
                                            }}
                                        >
                                            <CardContent>
                                                <Stack
                                                    direction="column"
                                                    spacing="11px"
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Inter",
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                            lineHeight:
                                                                "19.36px",
                                                            textAlign: "left",
                                                            color: "black",
                                                            //marginBottom: "17px",
                                                        }}
                                                    >
                                                        Order summary
                                                    </Typography>
                                                    <Item
                                                        left={
                                                            <span
                                                                style={{
                                                                    color: "#717171",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "400",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                Subtotal
                                                            </span>
                                                        }
                                                        right={
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "500",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                {"$" +
                                                                    requestDetails.priceTotal}
                                                            </span>
                                                        }
                                                    />
                                                    <Item
                                                        left={
                                                            <span
                                                                style={{
                                                                    color: "#717171",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "400",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                Tax
                                                            </span>
                                                        }
                                                        right={
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "500",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                {"$" +
                                                                    (
                                                                        requestDetails.priceTotal *
                                                                        0.0725
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                            </span>
                                                        }
                                                    />
                                                    <Divider />
                                                    <Item
                                                        left={
                                                            <span
                                                                style={{
                                                                    color: "#1D1B20",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "400",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                Total
                                                            </span>
                                                        }
                                                        right={
                                                            <span
                                                                style={{
                                                                    color: "black",
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "700",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                }}
                                                            >
                                                                {"$" +
                                                                    (
                                                                        requestDetails.priceTotal *
                                                                        1.0725
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                            </span>
                                                        }
                                                    />
                                                </Stack>
                                            </CardContent>
                                        </Card>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Policies
                                        </Typography>
                                        <Stack
                                            direction="column"
                                            spacing="12px"
                                            sx={{ width: "100%" }}
                                        >
                                            <ButtonBase
                                                component="button"
                                                onClick={() =>
                                                    window.open(
                                                        policiesUrl,
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <Paper
                                                    sx={{
                                                        padding: 1,
                                                        paddingLeft: 2,
                                                        width: "100%",
                                                    }}
                                                >
                                                    <StackVariant>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "400",
                                                                lineHeight:
                                                                    "16.94px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Satisfaction
                                                            guarantee
                                                        </Typography>
                                                        <ChevronRightIcon />
                                                    </StackVariant>
                                                </Paper>
                                            </ButtonBase>
                                            <ButtonBase
                                                component="button"
                                                onClick={() =>
                                                    window.open(
                                                        policiesUrl,
                                                        "_blank"
                                                    )
                                                }
                                            >
                                                <Paper
                                                    sx={{
                                                        padding: 1,
                                                        paddingLeft: 2,
                                                        width: "100%",
                                                    }}
                                                >
                                                    <StackVariant>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "400",
                                                                lineHeight:
                                                                    "16.94px",
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            Cancellation policy
                                                        </Typography>
                                                        <ChevronRightIcon />
                                                    </StackVariant>
                                                </Paper>
                                            </ButtonBase>
                                        </Stack>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Your payment method won't be charged
                                            until your service is confirmed.
                                        </Typography>
                                        <Button
                                            onClick={() => {
                                                const service_type = 3;

                                                setOrder((prevOrder) => {
                                                    const updatedOrder = {
                                                        ...prevOrder,
                                                        service: service_type,
                                                    };

                                                    djangoEndpointPromise(
                                                        "set_service_type",
                                                        {
                                                            order_id:
                                                                updatedOrder.order_id,
                                                            service_id:
                                                                service_type,
                                                        }
                                                    )
                                                        .then((response) => {
                                                            navigate(
                                                                "/stripe/checkout"
                                                            );
                                                        })
                                                        .catch((error) => {
                                                            console.error(
                                                                "Error calling endpoint:",
                                                                error
                                                            );
                                                        });

                                                    return updatedOrder;
                                                });
                                            }}
                                            sx={{
                                                width: "100%",
                                                height: "44px",
                                                gap: "8px",
                                                borderRadius: "100px",
                                                opacity: "0px",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                letterSpacing:
                                                    "0.10000000149011612px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Request booking
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                )}
            </HeaderFooter>
        </Box>
    );
};

const PrivateChefRequestDetails = () => {
    const orderHook = useCurrentServiceOrder();
    const guestHook = useCurrentGuest();
    const hostHook = useCurrentHost();
    const recipientsHook = useCurrentRecipients();
    const [host, hostTryLoad, _setHost] = hostHook;
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [guest, guestTryLoad, _setGuest] = guestHook;
    const [recipients, recipientsTryLoad, _setRecipients] = recipientsHook;

    const [requestDetails, tryLoad, setRequestDetails] =
        useGetRequestDetails(orderHook);
    // Since guestTryLoad is useCallback() with a dependency on what is essentially order?.order_id,
    // this function will reload when the ID changes.
    useEffect(() => {
        guestTryLoad();
    }, [guestTryLoad]);
    // The same applies for recipientsTryLoad.
    useEffect(() => {
        recipientsTryLoad();
    }, [recipientsTryLoad]);

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <Loading
            fetchResult={mergeMultipleResults(
                order,
                guest,
                recipients,
                host,
                requestDetails
            )}
            onTryAgain={orderTryLoad}
            renderChildren={() => (
                <Loaded
                    orderHook={orderHook}
                    guestHook={guestHook}
                    recipientsHook={recipientsHook}
                    hostHook={hostHook}
                    requestDetails={requestDetails}
                />
            )}
        />
    );
};

export default PrivateChefRequestDetails;
