import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    useMediaQuery,
    Select,
    Stack,
    TextField,
    InputAdornment,
    MenuItem,
    Tooltip,
    Alert,
    IconButton,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    Loading,
    AppDotPagination,
    AppDotPaginationwBack,
    Title,
    MobileTitle,
    BLDCard,
    BLDCardMobile,
    HeaderFooter,
} from "../components/UI";
import {
    djangoEndpointPromise,
    useCurrentServiceOrder,
    useCurrentHost,
    mergeMultipleResults,
    PRIVATE_CHEF_PAGINATION,
} from "../util";
import logo from "../assets/ellipse.png";
import HelpIcon from "@mui/icons-material/HelpOutline";
import BreakfastPhoto from "../assets/breakfast.png";
import LunchPhoto from "../assets/lunch.png";
import DinnerPhoto from "../assets/dinner.png";
import bldphoto from "../assets/macncheese.png";

const MobileToolTip = () => {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <Tooltip
            title="Your reservation number is given by your host. You can often find this number on your confirmation email. If your host did not provide you with a reservation number, enter five zeroes."
            open={open}
            onClose={handleTooltipClose}
            disableHoverListener
        >
            <IconButton onClick={handleTooltipOpen}>
                <HelpIcon
                    sx={{
                        color: "#1D1B20",
                        width: "16px",
                        height: "16px",
                    }}
                />
            </IconButton>
        </Tooltip>
    );
};

const MEALS = {
    titles: ["Breakfast", "Lunch", "Dinner"],
    subtitles: [
        "View our favorite chefs' menu options for early-morning to mid-morning meals.",
        "View our favorite chefs' menu options for early-morning to mid-morning meals.",
        "View our favorite chefs' menu options for early-morning to mid-morning meals.",
    ],
    options: ["breakfast", "lunch", "dinner"],
    images: [BreakfastPhoto, LunchPhoto, DinnerPhoto],
};

const PrivateChefBldUI = ({ orderHook, hostInfo }) => {
    const [order, tryAgain, setOrder] = orderHook;
    const navigate = useNavigate();
    const [validate, setValidate] = useState(false);

    const errorMessages = [];
    const [reservationNumberBlurred, setReservationNumberBlurred] =
        useState(false);
    let isValid = true;
    let reservationNumberError = null;
    if (!MEALS.options.includes(order.private_chef_bld)) {
        isValid = false;
        if (validate) {
            errorMessages.push("Select a meal option to proceed.");
        }
    }
    if (order.res_number === "") {
        isValid = false;
        if (validate) {
            errorMessages.push("Enter your reservation number.");
        }
        if (reservationNumberBlurred) {
            reservationNumberError = "Please enter your reservation number.";
        }
    }
    // if (order.private_chef_menu_num_guests === null) {
    //     isValid = false;
    //     if (validate) {
    //         errorMessages.push('Select a number of guests to proceed.');
    //     }
    // }

    const guestSelect = Array.from({ length: 10 }, (_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
    }));

    const Mobile = useMediaQuery("(max-width:600px)");
    const minWidthValue = Mobile ? "0px" : "1512px";

    return (
        <>
            {Mobile && (
                <Box
                    sx={{
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                    }}
                >
                    <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                        <Box
                            sx={{
                                bgcolor: "#F2F2F2",
                                flexGrow: 1,
                                width: "100%",
                                // overflowY: "auto",
                                paddingBottom: "20px",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "20px",
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <AppDotPagination
                                    page={0}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                    onBack={() =>
                                        navigate(
                                            `/listing/${hostInfo.unique_url}`
                                        )
                                    }
                                />
                                <MobileTitle
                                    title={"What meal would you like to book?"}
                                />
                                <Stack
                                    direction="column"
                                    spacing="30px"
                                    sx={{ width: "91%", alignItems: "center" }}
                                >
                                    {MEALS.options.map((option, i) => (
                                        <BLDCardMobile
                                            key={i}
                                            title={MEALS.titles[i]}
                                            subtitle={MEALS.subtitles[i]}
                                            selected={
                                                option ===
                                                order.private_chef_bld
                                            }
                                            onClick={() => {
                                                const value =
                                                    order.private_chef_bld ===
                                                    option
                                                        ? null
                                                        : option;
                                                setOrder({
                                                    ...order,
                                                    private_chef_bld: value,
                                                });
                                                djangoEndpointPromise(
                                                    "private-chef-menu/input-bld",
                                                    {
                                                        order_id:
                                                            order.order_id,
                                                        bld: value,
                                                    }
                                                );
                                            }}
                                            image={MEALS.images[i]}
                                            imageVariant="fullWidth"
                                        />
                                    ))}
                                </Stack>

                                <Stack
                                    direction="column"
                                    sx={{
                                        width: "91%",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        sx={{
                                            alignItems: "center",
                                            marginTop: "15.96px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "center",
                                                color: "#1D1B20",
                                            }}
                                        >
                                            Reservation Number
                                        </Typography>
                                        <MobileToolTip />
                                    </Stack>
                                    <TextField
                                        sx={{
                                            marginTop: "2px",
                                            //width: "421px",
                                            height: "56px",
                                            borderRadius: "12px",
                                            background: "white",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "12px",
                                                "& fieldset": {
                                                    borderColor: "#CACACA",
                                                    borderWidth: "1px",
                                                },
                                            },
                                        }}
                                        error={reservationNumberError !== null}
                                        helperText={reservationNumberError}
                                        FormHelperTextProps={{
                                            sx: {
                                                marginTop: "0px",
                                                background: "#EDEDED",
                                                width: "100%",
                                                marginLeft: "0px",
                                                paddingLeft: "19px",
                                            },
                                        }}
                                        defaultValue={order.res_number}
                                        onBlur={(event) => {
                                            const value = event.target.value;
                                            setReservationNumberBlurred(true);
                                            setOrder({
                                                ...order,
                                                res_number: value,
                                            });
                                            djangoEndpointPromise("input_res", {
                                                order_id: order.order_id,
                                                res_number: value,
                                            });
                                        }}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    #
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Typography
                                        component={"div"}
                                        sx={{
                                            marginTop: "13px",
                                            fontFamily: "Inter",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "16.94px",
                                            textAlign: "left",
                                            color: "#1D1B20",
                                        }}
                                    >
                                        Number of Guests Total
                                    </Typography>
                                    <Select
                                        sx={{
                                            marginTop: "10px",
                                            width: "70px",
                                            height: "52px",
                                            padding: "0px",
                                            background: "white",
                                            borderRadius: "12px",
                                            borderColor: "black",
                                            borderWidth: "1px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "12px",
                                                "& fieldset": {
                                                    borderColor: "#79747E",
                                                    borderWidth: "1px",
                                                },
                                            },
                                        }}
                                        value={
                                            order.private_chef_menu_num_guests ==
                                            null
                                                ? ""
                                                : order.private_chef_menu_num_guests
                                        }
                                        onChange={(event) => {
                                            const value = parseInt(
                                                event.target.value,
                                                10
                                            );
                                            setOrder({
                                                ...order,
                                                private_chef_menu_num_guests:
                                                    value,
                                            });
                                            djangoEndpointPromise(
                                                "private-chef-menu/input-num-guests",
                                                {
                                                    order_id: order.order_id,
                                                    num_guests: value,
                                                }
                                            );
                                        }}
                                    >
                                        {guestSelect.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                                sx={{
                                                    boxShadow: "none",
                                                    background: "white",
                                                }}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {validate && !isValid && (
                                        <Alert
                                            variant="filled"
                                            severity="error"
                                        >
                                            {errorMessages.map((message) => (
                                                <Typography
                                                    key={message}
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {message}
                                                </Typography>
                                            ))}
                                        </Alert>
                                    )}
                                    <Button
                                        disabled={!isValid}
                                        onClick={async () => {
                                            if (isValid) {
                                                tryAgain();
                                                navigate(
                                                    PRIVATE_CHEF_PAGINATION[1]
                                                );
                                            }
                                        }}
                                        sx={{
                                            //width: "414px",
                                            height: "44px",
                                            marginBottom: "6px",
                                            marginTop: "25px",
                                            borderRadius: "100px",
                                            gap: "8px",
                                        }}
                                        fullWidth
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    </HeaderFooter>
                </Box>
            )}
            {!Mobile && (
                <Box
                    sx={{
                        flexGrow: 1,
                        minWidth: "1512px",
                        width: "100%",
                        overflowX: "auto",
                        scrollBehavior: "smooth",
                    }}
                >
                    <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                        <Box
                            sx={{
                                bgcolor: "#FDFDFD",
                                paddingBottom: "125px",
                                flexGrow: "1",
                                //width: "100%",
                                overflowX: "auto",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "97px",
                                    marginLeft: "100px",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Title title="Customize your experience" />
                                <AppDotPaginationwBack
                                    page={0}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                    onBack={() =>
                                        navigate(
                                            `/listing/${hostInfo.unique_url}`
                                        )
                                    }
                                />
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        width: "1428px",
                                    }}
                                >
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <Stack
                                            direction="column"
                                            spacing="30px"
                                        >
                                            {MEALS.options.map((option, i) => (
                                                <BLDCard
                                                    key={i}
                                                    title={MEALS.titles[i]}
                                                    subtitle={
                                                        MEALS.subtitles[i]
                                                    }
                                                    selected={
                                                        option ===
                                                        order.private_chef_bld
                                                    }
                                                    onClick={() => {
                                                        const value =
                                                            order.private_chef_bld ===
                                                            option
                                                                ? null
                                                                : option;
                                                        setOrder({
                                                            ...order,
                                                            private_chef_bld:
                                                                value,
                                                        });
                                                        djangoEndpointPromise(
                                                            "private-chef-menu/input-bld",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                bld: value,
                                                            }
                                                        );
                                                    }}
                                                    image={MEALS.images[i]}
                                                    imageVariant="fullWidth"
                                                />
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={5}>
                                        <Box
                                            sx={{
                                                width: "472px",
                                                height: "305px",
                                                background: "#EDEDED",
                                                borderRadius: "12px",
                                                paddingLeft: "28px",
                                                marginRight: "100px",
                                                position: "sticky",
                                                top: "220px",
                                                paddingTop: "20px",
                                            }}
                                        >
                                            <Stack direction="column">
                                                <Stack
                                                    direction="row"
                                                    sx={{
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                            textAlign: "center",
                                                            color: "#1D1B20",
                                                        }}
                                                    >
                                                        Reservation Number
                                                    </Typography>
                                                    <Tooltip title="Your reservation number is given by your host. You can often find this number on your confirmation email. If your host did not provide you with a reservation number, enter five zeroes.">
                                                        <IconButton>
                                                            <HelpIcon
                                                                sx={{
                                                                    color: "#1D1B20",
                                                                    width: "16px",
                                                                    height: "16px",
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                                <TextField
                                                    sx={{
                                                        marginTop: "2px",
                                                        width: "421px",
                                                        height: "56px",
                                                        borderRadius: "12px",
                                                        background: "white",
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                borderRadius:
                                                                    "12px",
                                                                "& fieldset": {
                                                                    borderColor:
                                                                        "#CACACA",
                                                                    borderWidth:
                                                                        "1px",
                                                                },
                                                            },
                                                    }}
                                                    error={
                                                        reservationNumberError !==
                                                        null
                                                    }
                                                    helperText={
                                                        reservationNumberError
                                                    }
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            marginTop: "0px",
                                                            background:
                                                                "#EDEDED",
                                                            width: "100%",
                                                            marginLeft: "0px",
                                                            paddingLeft: "19px",
                                                        },
                                                    }}
                                                    defaultValue={
                                                        order.res_number
                                                    }
                                                    onBlur={(event) => {
                                                        const value =
                                                            event.target.value;
                                                        setReservationNumberBlurred(
                                                            true
                                                        );
                                                        setOrder({
                                                            ...order,
                                                            res_number: value,
                                                        });
                                                        djangoEndpointPromise(
                                                            "input_res",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                res_number:
                                                                    value,
                                                            }
                                                        );
                                                    }}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                #
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <Typography
                                                    component={"div"}
                                                    sx={{
                                                        marginTop: "13px",
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                        textAlign: "left",
                                                        color: "#1D1B20",
                                                    }}
                                                >
                                                    Number of Guests Total
                                                </Typography>
                                                <Select
                                                    sx={{
                                                        marginTop: "10px",
                                                        width: "84px",
                                                        height: "52px",
                                                        padding: "0px",
                                                        background: "white",
                                                        borderRadius: "12px",
                                                        borderColor: "black",
                                                        borderWidth: "1px",
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                borderRadius:
                                                                    "12px",
                                                                "& fieldset": {
                                                                    borderColor:
                                                                        "#79747E",
                                                                    borderWidth:
                                                                        "1px",
                                                                },
                                                            },
                                                    }}
                                                    value={
                                                        order.private_chef_menu_num_guests ==
                                                        null
                                                            ? ""
                                                            : order.private_chef_menu_num_guests
                                                    }
                                                    onChange={(event) => {
                                                        const value =
                                                            event.target.value;
                                                        setOrder({
                                                            ...order,
                                                            private_chef_menu_num_guests:
                                                                value,
                                                        });
                                                        djangoEndpointPromise(
                                                            "private-chef-menu/input-num-guests",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                num_guests:
                                                                    value,
                                                            }
                                                        );
                                                    }}
                                                >
                                                    {guestSelect.map(
                                                        (option) => (
                                                            <MenuItem
                                                                key={
                                                                    option.value
                                                                }
                                                                value={
                                                                    option.value
                                                                }
                                                                sx={{
                                                                    boxShadow:
                                                                        "none",
                                                                    background:
                                                                        "white",
                                                                }}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                                {validate && !isValid && (
                                                    <Alert
                                                        variant="filled"
                                                        severity="error"
                                                    >
                                                        {errorMessages.map(
                                                            (message) => (
                                                                <Typography
                                                                    key={
                                                                        message
                                                                    }
                                                                    variant="body1"
                                                                    sx={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {message}
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Alert>
                                                )}
                                                <Button
                                                    disabled={!isValid}
                                                    onClick={async () => {
                                                        if (isValid) {
                                                            tryAgain();
                                                            navigate(
                                                                PRIVATE_CHEF_PAGINATION[1]
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        width: "414px",
                                                        height: "44px",
                                                        marginBottom: "30px",
                                                        marginTop: "25px",
                                                        borderRadius: "100px",
                                                        gap: "8px",
                                                    }}
                                                    fullWidth
                                                >
                                                    Next
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </HeaderFooter>
                </Box>
            )}
        </>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [host, hostTryLoad, _setHost] = hostHook;

    return <PrivateChefBldUI orderHook={orderHook} hostInfo={host} />;
};

const PrivateChefBld = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default PrivateChefBld;
