import {
    AppDotPagination,
    AppDotPaginationwBack,
    COLUMN_FLEX_SPACING,
    Loading,
    Title,
    MassageTypeCard,
    MobileTitle,
    HeaderFooter,
    SelectButtonDynamic,
} from "../components/UI";
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Button,
    Box,
    useMediaQuery,
    Stack,
    Grid,
} from "@mui/material";
import {
    MASSAGE_PAGINATION,
    MUTED_BUTTON_SELECTED_STRINGS,
    djangoEndpointPromise,
    useCurrentServiceOrder,
    MASSAGE_TYPE_REQUESTED,
    MASSAGE_GENDER_REQUESTED,
    mergeMultipleResults,
    useCurrentHost,
    fetchResultType,
} from "../util";
import logo from "../assets/ellipse.png";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { EffectCoverflow } from "swiper/modules";

const MASSAGE_DURATION_REQUESTED = {
    options: [30, 60, 90, 120],
};

const MobileMassageTypeCards = ({ orderHook, MASSAGE_TYPE_REQUESTED }) => {
    const [order, tryAgain, setOrder] = orderHook;
    return (
        <>
            <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                loop={true}
                modules={[EffectCoverflow]}
                style={{
                    width: "100%",
                    paddingBottom: "10px",
                }}
            >
                {MASSAGE_TYPE_REQUESTED.options.map((option, i) => (
                    <SwiperSlide
                        key={i}
                        style={{
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            width: "300px",
                        }}
                    >
                        <MobileMassageTypeCard
                            key={i}
                            title={MASSAGE_TYPE_REQUESTED.titles[i]}
                            subtitle={MASSAGE_TYPE_REQUESTED.subtitles[i]}
                            selected={order.massage_type_requested === option}
                            onClick={() => {
                                const value =
                                    order.massage_type_requested === option
                                        ? null
                                        : option;
                                djangoEndpointPromise("massage/input_type", {
                                    order_id: order.order_id,
                                    massage_type: value,
                                });
                                setOrder({
                                    ...order,
                                    massage_type_requested: value,
                                });
                            }}
                            image={MASSAGE_TYPE_REQUESTED.images[i]}
                            imageVariant="fullWidth"
                            fixWidth
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

const MobileMassageTypeCard = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    const cardStyles = {
        // minWidth: "377px",
        // maxWidth: "377px",
        display: "block",
        width: "300px",
        //top: "340px",
        gap: "0px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card sx={fixWidth ? { ...cardStyles } : cardStyles}>
                <CardActionArea component="div" onClick={onClick}>
                    <CardMedia component="img" image={image} />
                    <CardContent>
                        <Stack direction="column" alignItems="flex-start">
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const MassageTypeCards = ({ orderHook, MASSAGE_TYPE_REQUESTED }) => {
    const [order, tryAgain, setOrder] = orderHook;
    return (
        <>
            <Grid item xs={12} sm={12} md={12} lg={8}>
                <Stack
                    direction="column"
                    spacing="15px"
                    sx={{
                        flexGrow: 0,
                    }}
                >
                    {MASSAGE_TYPE_REQUESTED.options.map((option, i) => (
                        <MassageTypeCard
                            key={i}
                            title={MASSAGE_TYPE_REQUESTED.titles[i]}
                            subtitle={MASSAGE_TYPE_REQUESTED.subtitles[i]}
                            selected={order.massage_type_requested === option}
                            onClick={() => {
                                const value =
                                    order.massage_type_requested === option
                                        ? null
                                        : option;
                                djangoEndpointPromise("massage/input_type", {
                                    order_id: order.order_id,
                                    massage_type: value,
                                });
                                setOrder({
                                    ...order,
                                    massage_type_requested: value,
                                });
                            }}
                            image={MASSAGE_TYPE_REQUESTED.images[i]}
                            imageVariant="fullWidth"
                            fixWidth
                        />
                    ))}
                </Stack>
            </Grid>
        </>
    );
};

const onNext = async (orderId) => {
    try {
        const response = await djangoEndpointPromise(
            "match_order_to_offerings",
            {
                order_id: orderId,
            }
        );

        if (fetchResultType(response) === "error") {
            throw new Error("Problem matching");
        }
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

const MassageTypeLengthTherapistMobileUI = ({
    orderProps,
    hostProps,
    navigationProps,
    validationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;

    const { isValid } = validationProps;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "0px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "0px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "20px",
                                alignItems: "center",
                                padding: "0px 11px 0px 11px",
                            }}
                        >
                            <AppDotPagination
                                page={1}
                                pagination={MASSAGE_PAGINATION}
                            />
                            <MobileTitle title="Customize your experience" />
                            <MobileMassageTypeCards
                                orderHook={orderHook}
                                MASSAGE_TYPE_REQUESTED={
                                    MASSAGE_TYPE_REQUESTED
                                }
                            />
                            <Stack
                                direction="column"
                                spacing="15px"
                                sx={{
                                    alignItems: "flex-start",
                                    marginTop: "15px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        lineHeight: "16.94px",
                                        textAlign: "left",
                                        color: "#1D1B20",
                                    }}
                                >
                                    MASSAGE THERAPIST
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={COLUMN_FLEX_SPACING}
                                    sx={{ marginBottom: "15px" }}
                                >
                                    {MASSAGE_GENDER_REQUESTED.options.map(
                                        (option, i) => (
                                            <Button
                                                key={i}
                                                color={
                                                    MUTED_BUTTON_SELECTED_STRINGS[
                                                    order.massage_gender_requested ===
                                                        option
                                                        ? 1
                                                        : 0
                                                    ]
                                                }
                                                onClick={() => {
                                                    const value =
                                                        order.massage_gender_requested ===
                                                            option
                                                            ? null
                                                            : option;
                                                    djangoEndpointPromise(
                                                        "massage/input_therapist_gender",
                                                        {
                                                            order_id:
                                                                order.order_id,
                                                            massage_therapist_gender:
                                                                value,
                                                        }
                                                    );
                                                    setOrder({
                                                        ...order,
                                                        massage_gender_requested:
                                                            value,
                                                    });
                                                }}
                                                sx={{
                                                    width:
                                                        option === "male"
                                                            ? "80px"
                                                            : option ===
                                                                "female"
                                                                ? "95px"
                                                                : "140px",
                                                    height: "40px",
                                                    border: "1px solid #1E1E1E",
                                                    borderRadius: "100px",
                                                    background:
                                                        order.massage_gender_requested ===
                                                            option
                                                            ? "#0F3562"
                                                            : "#FFFFFF",
                                                    color:
                                                        order.massage_gender_requested ===
                                                            option
                                                            ? "#FFFFFF"
                                                            : "#000000",
                                                }}
                                            >
                                                {
                                                    MASSAGE_GENDER_REQUESTED
                                                        .titles[i]
                                                }
                                            </Button>
                                        )
                                    )}
                                </Stack>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "600",
                                        lineHeight: "16.94px",
                                        textAlign: "left",
                                        color: "#1D1B20",
                                    }}
                                >
                                    LENGTH OF MASSAGE
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={COLUMN_FLEX_SPACING}
                                >
                                    {MASSAGE_DURATION_REQUESTED.options.map(
                                        (option, i) => (
                                            <Button
                                                key={i}
                                                color={
                                                    MUTED_BUTTON_SELECTED_STRINGS[
                                                    order.massage_duration_requested ===
                                                        option
                                                        ? 1
                                                        : 0
                                                    ]
                                                }
                                                onClick={() => {
                                                    const value =
                                                        order.massage_duration_requested ===
                                                            option
                                                            ? null
                                                            : option;
                                                    djangoEndpointPromise(
                                                        "massage/input_duration",
                                                        {
                                                            order_id:
                                                                order.order_id,
                                                            massage_duration:
                                                                value,
                                                        }
                                                    );
                                                    setOrder({
                                                        ...order,
                                                        massage_duration_requested:
                                                            value,
                                                    });
                                                }}
                                                sx={{
                                                    width: "65px",
                                                    height: "40px",
                                                    border: "1px solid #1E1E1E",
                                                    borderRadius: "100px",
                                                    background:
                                                        order.massage_duration_requested ===
                                                            option
                                                            ? "#0F3562"
                                                            : "#FFFFFF",
                                                    color:
                                                        order.massage_duration_requested ===
                                                            option
                                                            ? "#FFFFFF"
                                                            : "#000000",
                                                }}
                                            >
                                                {option}
                                            </Button>
                                        )
                                    )}
                                </Stack>
                            </Stack>
                            <Button
                                disabled={!isValid}
                                onClick={async () => {
                                    if (isValid) {
                                        tryAgain();
                                        await onNext(order.order_id);
                                        navigate(MASSAGE_PAGINATION[2]);
                                    }
                                }}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "95%",
                                    height: "44px",
                                    marginTop: "10px",
                                    marginBottom: "30px",
                                }}
                                fullWidth
                            >
                                Next
                            </Button>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const MassageTypeLengthTherapistDesktopUI = ({
    orderProps,
    hostProps,
    navigationProps,
    validationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;

    const { isValid } = validationProps;
    const Mobile = useMediaQuery("(max-width:600px)");

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: "1512px",
                width: "100%",
                scrollBehavior: "smooth",
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={!Mobile}>
                <Box
                    sx={{
                        bgcolor: "#FFFFFF",
                        paddingBottom: "125px",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing="15.96px"
                        sx={{
                            marginTop: "97px",
                            marginLeft: "100px",
                            marginRight: "100px",
                            alignItems: "flex-start",
                        }}
                    >
                        <Title title="Customize your experience" />
                        <AppDotPaginationwBack
                            page={1}
                            pagination={MASSAGE_PAGINATION}
                        />

                        <Grid
                            container
                            spacing={2}
                            sx={{
                                width: "1250px",
                            }}
                        >
                            <MassageTypeCards
                                orderHook={orderHook}
                                MASSAGE_TYPE_REQUESTED={MASSAGE_TYPE_REQUESTED}
                            />
                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                <Box
                                    sx={{
                                        width: "420px",
                                        height: "346px",
                                        background: "#EDEDED",
                                        borderRadius: "12px",
                                        paddingLeft: "35px",
                                        paddingTop: "37px",
                                        // position: "sticky",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            lineHeight: "24.2px",
                                            textAlign: "left",
                                            marginBottom: "20px",
                                            color: "#1D1B20",
                                        }}
                                    >
                                        Massage Therapist
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        spacing={COLUMN_FLEX_SPACING}
                                    >
                                        {MASSAGE_GENDER_REQUESTED.options.map(
                                            (option, i) => (
                                                <Button
                                                    key={i}
                                                    color={
                                                        MUTED_BUTTON_SELECTED_STRINGS[
                                                        order.massage_gender_requested ===
                                                            option
                                                            ? 1
                                                            : 0
                                                        ]
                                                    }
                                                    onClick={() => {
                                                        const value =
                                                            order.massage_gender_requested ===
                                                                option
                                                                ? null
                                                                : option;
                                                        djangoEndpointPromise(
                                                            "massage/input_therapist_gender",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                massage_therapist_gender:
                                                                    value,
                                                            }
                                                        );
                                                        setOrder({
                                                            ...order,
                                                            massage_gender_requested:
                                                                value,
                                                        });
                                                    }}
                                                    sx={{
                                                        width:
                                                            option === "male"
                                                                ? "80px"
                                                                : option ===
                                                                    "female"
                                                                    ? "95px"
                                                                    : "140px",
                                                        height: "40px",
                                                        border: "1px solid #1E1E1E",
                                                        borderRadius: "100px",
                                                        background:
                                                            order.massage_gender_requested ===
                                                                option
                                                                ? "#0F3562"
                                                                : "#EDEDED",
                                                        color:
                                                            order.massage_gender_requested ===
                                                                option
                                                                ? "#FFFFFF"
                                                                : "#000000",
                                                    }}
                                                >
                                                    {
                                                        MASSAGE_GENDER_REQUESTED
                                                            .titles[i]
                                                    }
                                                </Button>
                                            )
                                        )}
                                    </Stack>
                                    <Typography
                                        sx={{
                                            marginTop: "39px",
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            lineHeight: "24.2px",
                                            textAlign: "left",
                                            marginBottom: "20px",
                                            color: "#1D1B20",
                                        }}
                                    >
                                        Length of Massage
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        spacing={COLUMN_FLEX_SPACING}
                                    >
                                        {MASSAGE_DURATION_REQUESTED.options.map(
                                            (option, i) => (
                                                <Button
                                                    key={i}
                                                    color={
                                                        MUTED_BUTTON_SELECTED_STRINGS[
                                                        order.massage_duration_requested ===
                                                            option
                                                            ? 1
                                                            : 0
                                                        ]
                                                    }
                                                    onClick={() => {
                                                        const value =
                                                            order.massage_duration_requested ===
                                                                option
                                                                ? null
                                                                : option;
                                                        djangoEndpointPromise(
                                                            "massage/input_duration",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                massage_duration:
                                                                    value,
                                                            }
                                                        );
                                                        setOrder({
                                                            ...order,
                                                            massage_duration_requested:
                                                                value,
                                                        });
                                                    }}
                                                    sx={{
                                                        width: "65px",
                                                        height: "40px",
                                                        border: "1px solid #1E1E1E",
                                                        borderRadius: "100px",
                                                        background:
                                                            order.massage_duration_requested ===
                                                                option
                                                                ? "#0F3562"
                                                                : "#EDEDED",
                                                        color:
                                                            order.massage_duration_requested ===
                                                                option
                                                                ? "#FFFFFF"
                                                                : "#000000",
                                                    }}
                                                >
                                                    {option}
                                                </Button>
                                            )
                                        )}
                                    </Stack>
                                    <Button
                                        disabled={!isValid}
                                        onClick={async () => {
                                            if (isValid) {
                                                tryAgain();
                                                await onNext(order.order_id);
                                                navigate(MASSAGE_PAGINATION[2]);
                                            }
                                        }}
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "0px",
                                            gap: "8px",
                                            width: "95%",
                                            height: "44px",
                                            marginTop: "31px",
                                            marginLeft: "-40px",
                                        }}
                                        fullWidth
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </HeaderFooter>
        </Box>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const navigate = useNavigate();
    const [order, tryAgain, setOrder] = orderHook;
    const [hostInfo, hostTryLoad, _setHost] = hostHook;

    let isValid = true;
    if (
        !MASSAGE_TYPE_REQUESTED.options.includes(order.massage_type_requested)
    ) {
        isValid = false;
    }
    if (
        !MASSAGE_GENDER_REQUESTED.options.includes(
            order.massage_gender_requested
        )
    ) {
        isValid = false;
    }
    if (
        !MASSAGE_DURATION_REQUESTED.options.includes(
            order.massage_duration_requested
        )
    ) {
        isValid = false;
    }

    const orderProps = {
        orderHook,
    };

    const hostProps = {
        hostInfo,
    };

    const navigationProps = {
        navigate,
    };

    const validationProps = {
        isValid,
    };

    const combinedProps = {
        orderProps,
        hostProps,
        navigationProps,
        validationProps,
    };

    const Mobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {Mobile ? (
                <MassageTypeLengthTherapistMobileUI {...combinedProps} />
            ) : (
                <MassageTypeLengthTherapistDesktopUI {...combinedProps} />
            )}
        </>
    );
};

const MassageTypeLengthTherapistPage = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default MassageTypeLengthTherapistPage;
