import React, { useState, useEffect, createContext, useContext } from "react";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import {
    secret,
    useCurrentServiceOrder,
    djangoEndpointPromise,
    fetchResultType,
    useCurrentHost,
} from "../util";
import { HeaderFooter, Loading } from "../components/UI";
import { Typography, Box, useMediaQuery, Stack } from "@mui/material";
import nexusLogo from "../assets/nexus.png";
import CircleIcon from "@mui/icons-material/Circle";

const sendEmails = async (orderId, status, sessionId, order, setOrder) => {
    try {
        const url = "send_emails_to_matching_providers";
        const response = await djangoEndpointPromise(url, {
            order_id: orderId,
            status: status,
            session_id: sessionId,
        });

        if (fetchResultType(response) === "error") {
            throw new Error(
                "Server Error: Something went wrong on the server."
            );
        }

        setOrder({
            ...order,
            status: "requested",
        });
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

const ReturnPageMobileUI = ({ orderHook, hostHook }) => {
    const [hostInfo, hostTryLoad, _setHost] = hostHook;
    const [customerEmail, setCustomerEmail] = useState("");
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    background: "#F2F2F2",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            flexGrow: 1,
                            width: "100%",
                            paddingBottom: "8px",
                            marginBottom: "20px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="12px"
                            sx={{
                                alignItems: "center",
                                marginTop: "34px",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "26px",
                                    fontWeight: "500",
                                    lineHeight: "31.47px",
                                    textAlign: "center",
                                }}
                            >
                                Thanks for requesting a service for your stay
                                with{" "}
                                {hostInfo.company_name === null
                                    ? hostInfo.host_name
                                    : hostInfo.company_name}
                                !
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    lineHeight: "21.78px",
                                    textAlign: "center",
                                }}
                            >
                                You’ll receive a confirmation email from our
                                partner, Nexus Upsells, shortly.
                            </Typography>
                            <Stack
                                direction="row"
                                spacing="30px"
                                sx={{
                                    marginTop: "23px",
                                    height: "93px",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={hostInfo.logo_url}
                                    alt="Host Logo"
                                    style={{ height: "80px", width: "auto" }}
                                />
                                <CircleIcon
                                    sx={{
                                        width: "55px",
                                        height: "55px",
                                        color: "#D9D9D9",
                                    }}
                                />
                                <img
                                    src={nexusLogo}
                                    alt="Nexus Logo"
                                    style={{ height: "80px", width: "auto" }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    lineHeight: "24.2px",
                                    textAlign: "center",
                                    marginTop: "21px",
                                }}
                            >
                                They’ll connect you with our recommend provider
                                and <b>confirm your request</b>!
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    lineHeight: "24.2px",
                                    textAlign: "center",
                                    marginTop: "21px",
                                    fontStyle: "italic",
                                }}
                            >
                                Once your request has been confirmed, you'll be
                                emailed with all the details and your payment
                                method will be charged!
                            </Typography>
                            {/* <p>
                            We appreciate your business! A confirmation email will be
                            sent to {customerEmail}. If you have any questions, please
                            email{" "}
                            <a href="mailto:orders@example.com">orders@example.com</a>.
                        </p> */}
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const ReturnPageDesktopUI = ({ orderHook, hostHook }) => {
    const [hostInfo, hostTryLoad, _setHost] = hostHook;
    const [customerEmail, setCustomerEmail] = useState("");
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "1050px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    background: "white",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                            paddingBottom: "125px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="12px"
                            sx={{
                                alignItems: "center",
                                marginTop: "13vh",
                                marginLeft: "15vw",
                                marginRight: "15vw",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    lineHeight: "36.31px",
                                    textAlign: "center",
                                }}
                            >
                                Thanks for requesting a service for your stay
                                with{" "}
                                {hostInfo.company_name === null
                                    ? hostInfo.host_name
                                    : hostInfo.company_name}
                                !
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    lineHeight: "24.2px",
                                    textAlign: "center",
                                }}
                            >
                                You’ll receive a confirmation email from our
                                partner, Nexus Upsells, shortly.
                            </Typography>
                            <Stack
                                direction="row"
                                spacing="96px"
                                sx={{
                                    marginTop: "23px",
                                    height: "167px",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={hostInfo.logo_url}
                                    alt="Host Logo"
                                    style={{ width: "auto", height: "140px" }}
                                />
                                <CircleIcon
                                    sx={{
                                        width: "71px",
                                        height: "71px",
                                        color: "#D9D9D9",
                                    }}
                                />
                                <img
                                    src={nexusLogo}
                                    alt="Nexus Logo"
                                    style={{ width: "auto", height: "140px" }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "22px",
                                    lineHeight: "26.63px",
                                    textAlign: "center",
                                    marginTop: "31px",
                                }}
                            >
                                They’ll connect you with our recommend provider
                                and <b>confirm your request</b>! Once your
                                request has been confirmed, you'll be emailed
                                with all the details and your payment method
                                will be charged!
                            </Typography>
                            {/* <p>
                            We appreciate your business! A confirmation email will be
                            sent to {customerEmail}. If you have any questions, please
                            email{" "}
                            <a href="mailto:orders@example.com">orders@example.com</a>.
                        </p> */}
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [order, _tryLoadOrder, setOrder] = orderHook;
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState("");
    const Mobile = useMediaQuery("(max-width:600px)");

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get("sessionId");

        // Define an async function within the useEffect
        const fetchStripeStatus = async () => {
            try {
                const response = await djangoEndpointPromise(
                    `stripe/session-status?sessionId=${sessionId}`
                );

                // Set the client secret in state
                setStatus(response.status);
                setCustomerEmail(response.customerEmail);
            } catch (error) {
                console.error("Error fetching client secret:", error);
            }
        };

        // Call the async function
        fetchStripeStatus();
    }, []);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get("sessionId");
        if (order && status && sessionId && order.status !== "requested") {
            sendEmails(order.order_id, status, sessionId, order, setOrder);
        }
    }, [order, status]);

    if (status === "open") {
        return <Navigate to="/stripe/checkout" />;
    }

    if (status === "complete") {
        return (
            <>
                {Mobile ? (
                    <ReturnPageMobileUI
                        orderHook={orderHook}
                        hostHook={hostHook}
                    />
                ) : (
                    <ReturnPageDesktopUI
                        orderHook={orderHook}
                        hostHook={hostHook}
                    />
                )}
            </>
        );
    }

    return null;
};

const ReturnPage = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={order}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default ReturnPage;
