import {
    useMediaQuery,
    Box,
    Button,
    Checkbox,
    Divider,
    InputAdornment,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    AppDotPaginationwBack,
    Title,
    COLUMN_FLEX_SPACING,
    Loading,
    HeaderFooter,
    PrivateChefMobileDotPagination,
} from "../components/UI";
import {
    PRIVATE_CHEF_PAGINATION,
    djangoEndpointPromise,
    mergeMultipleResults,
    useCurrentGuest,
    useCurrentRecipients,
    useCurrentServiceOrder,
    useCurrentHost,
    useCurrentMenu,
    capitalizeFirstLetter,
} from "../util";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import MacAndCheesePhoto from "../assets/macncheese.png";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MassageGuestRecipientInfoPage from "./MassageGuestRecipientInfoPage";

const Loaded = ({ orderHook, guestHook, recipientsHook, hostInfo, menuInfo }) => {
    const [order, _orderTryAgain, setOrder] = orderHook;
    const [guest, _guestTryAgain, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryAgain, setRecipients] = recipientsHook;
    const navigate = useNavigate();
    const [guestFirstNameBlurred, setGuestFirstNameBlurred] = useState(false);
    const [guestLastNameBlurred, setGuestLastNameBlurred] = useState(false);
    const [guestPhoneNumberBlurred, setGuestPhoneNumberBlurred] =
        useState(false);
    const [guestEmailBlurred, setGuestEmailBlurred] = useState(false);
    const [guestAllergies, setGuestAllergies] = useState(
        guest ? guest.allergies : null
    );

    const recipientTextFieldsRefs = useRef([]);

    // Function to handle dynamic ref assignment
    const setRef = (index, ref) => {
        recipientTextFieldsRefs.current[index] = ref;
    };

    // Recipient 1 text fields need UI state because of the include_guest_as_recipient checkbox.
    const [recipient1FirstName, setRecipient1FirstName] = useState(
        recipientsInfo.include_guest_as_recipient
            ? ""
            : recipientsInfo.recipients.length < 1
            ? ""
            : recipientsInfo.recipients[0].first_name
    );
    const [recipient1Allergies, setRecipient1Allergies] = useState(
        recipientsInfo.include_guest_as_recipient
            ? ""
            : recipientsInfo.recipients.length < 1
            ? ""
            : recipientsInfo.recipients[0].allergies
    );
    // If include_guest_as_recipient, recipient 2 corresponds to index 0, otherwise index 1.
    const recipient2Index = recipientsInfo.include_guest_as_recipient ? 0 : 1;
    let guestFirstNameError = null;
    let guestLastNameError = null;
    let guestPhoneNumberError = null;
    let guestEmailError = null;

    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    let isValid = true;
    // The Guest is only created when the first blur occurs.
    if (guest === null) {
        isValid = false;
    } else {
        if (guest.first_name === "") {
            isValid = false;
            if (guestFirstNameBlurred) {
                guestFirstNameError = "Required";
            }
        }
        if (guest.last_name === "") {
            isValid = false;
            if (guestLastNameBlurred) {
                guestLastNameError = "Required";
            }
        }
        if (guest.phone_number === "") {
            isValid = false;
            if (guestPhoneNumberBlurred) {
                guestPhoneNumberError = "Required";
            }
        }
        if (guest.email === "") {
            isValid = false;
            if (guestEmailBlurred) {
                guestEmailError = "Required";
            }
        } else if (isValidEmail(guest.email) === false) {
            isValid = false;
            guestEmailError = "Invalid Email Address";
        }
    }

    const initialFalseState = new Array(
        order.private_chef_menu_num_guests
    ).fill(false);
    const initialNullState = new Array(order.private_chef_menu_num_guests).fill(
        null
    );

    let recipientFirstNameBlurred = initialFalseState;
    let recipientAllergiesBlurred = initialFalseState;
    const [recipientErrors, setRecipientErrors] = useState({
        firstNameErrors: initialNullState,
        allergiesErrors: initialNullState,
    });

    const handleBlur = (index, field) => {
        if (field === "first_name") {
            const newBlurred = [...recipientFirstNameBlurred];
            newBlurred[index] = true;
            recipientFirstNameBlurred = newBlurred;
        } else if (field === "allergies") {
            const newBlurred = [...recipientAllergiesBlurred];
            newBlurred[index] = true;
            recipientAllergiesBlurred = newBlurred;
        }

        // Perform validation
        validateRecipients();
    };

    const validateRecipients = () => {
        let newErrors = {
            firstNameErrors: initialNullState,
            allergiesErrors: initialNullState,
        };

        if (recipientsInfo.include_guest_as_recipient) {
            recipientsInfo.recipients.forEach((recipient, index) => {
                const { first_name, allergies } = recipient;

                if (first_name !== "" || allergies !== "") {
                    if (first_name === "") {
                        isValid = false;
                        if (recipientFirstNameBlurred[index + 1]) {
                            newErrors.firstNameErrors[index + 1] = "Required";
                        }
                    }
                }
            });
        } else {
            recipientsInfo.recipients.forEach((recipient, index) => {
                const { first_name, allergies } = recipient;

                if (first_name === "") {
                    isValid = false;
                    if (recipientFirstNameBlurred[index]) {
                        newErrors.firstNameErrors[index] = "Required";
                    }
                }
            });
        }
    };

    validateRecipients();

    const num_recipients = recipientsInfo.include_guest_as_recipient
        ? 1 + recipientsInfo.recipients.length
        : recipientsInfo.recipients.length;

    if (num_recipients !== order.private_chef_menu_num_guests) {
        isValid = false;
    }

    if (
        recipientsInfo.recipients.length > 0 &&
        recipientsInfo.recipients[0].first_name === ""
    ) {
        isValid = false;
    }

    function setRecipientsAndUpdate(recipientsInfo, guestAllergies) {
        setRecipients(recipientsInfo);
        djangoEndpointPromise("private-chef-menu/input-recipients", {
            order_id: order.order_id,
            recipients: recipientsInfo.recipients,
            include_guest_as_recipient:
                recipientsInfo.include_guest_as_recipient,
            guest_allergies: guestAllergies,
        });
    }
    function setGuestAndUpdate(guest) {
        setGuest(guest);
        djangoEndpointPromise("input_guest_information", {
            order_id: order.order_id,
            first_name: guest.first_name,
            last_name: guest.last_name,
            phone_number: guest.phone_number,
            email: guest.email,
        });
    }
    function createGuestIfNull(guest) {
        if (guest === null) {
            return {
                first_name: "",
                last_name: "",
                phone_number: "",
                email: "",
            };
        }
        return guest;
    }

    const applyChangeToRecipients = () => {
        const recipientsCopy = Array.from(
            { length: order.private_chef_menu_num_guests },
            () => ({
                first_name: "",
                allergies: "",
            })
        );

        for (let i = 0; i < recipientTextFieldsRefs.current.length; i += 2) {
            const firstName = recipientTextFieldsRefs.current[i].value;
            const allergies = recipientTextFieldsRefs.current[i + 1].value;

            recipientsCopy[i] = {
                first_name: capitalizeFirstLetter(firstName),
                allergies: allergies,
            };
        }
        if (recipientsInfo.include_guest_as_recipient) {
            recipientsCopy[0] = {
                first_name: "",
                allergies: "",
            };
        }

        let x = recipientsCopy.filter(
            (recipient) =>
                recipient.first_name !== "" || recipient.allergies !== ""
        );

        if (
            !recipientsInfo.include_guest_as_recipient &&
            recipient1FirstName === "" &&
            recipient1Allergies === ""
        ) {
            x.unshift({
                first_name: "",
                allergies: "",
            });
        }

        return x;
    };

    const Mobile = useMediaQuery("(max-width:600px)");
    const Large = useMediaQuery("(min-width:1400px)");
    const minWidthValue = Mobile ? "0px" : "1512px";
    const backgroundColor = Mobile ? "#F2F2F2" : "white";

    const textFieldstyle = {
        marginBottom: "0px",
        marginTop: "6px",
        width: "324px",
        height: "56px",
        background: "white",
        borderRadius: "12px",
        border: "1px solid #CACACA",
        "& .MuiOutlinedInput-root": {
            borderRadius: "12px",
            "& fieldset": {
                borderColor: "#79747E",
                borderWidth: "1px",
            },
        },
        "& .MuiInputLabel-root": {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
            textAlign: "left",
            color: "#9D9D9D",
            paddingTop: "12px",
            paddingLeft: "16px",
        },
        "& .MuiInputBase-input": {
            fontSize: "14px",
            color: "#000000",
            paddingLeft: "4px",
            paddingTop: "7px",
            fontFamily: "Inter",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
        },
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: minWidthValue,
                width: "100%",
                overflowX: "auto",
                scrollBehavior: "smooth",
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={!Mobile}>
                {Mobile && (
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "20px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="0px"
                            sx={{
                                marginTop: "5px",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <PrivateChefMobileDotPagination
                                page={4}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "500",
                                    lineHeight: "24.2px",
                                    textAlign: "center",
                                    marginTop: "0px",
                                }}
                            >
                                Contact Information
                            </Typography>
                            <Stack direction="column" spacing="12px" sx={{ width: "85%" }}>
                                <Typography
                                    sx={{
                                        marginTop: "25px",
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        textAlign: "left",
                                        letterSpacing: "0.10000000149011612px",
                                    }}
                                >
                                    Who should we contact?
                                </Typography>

                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    label="First Name"
                                    defaultValue={
                                        guest === null ||
                                        guest.first_name === null
                                            ? ""
                                            : capitalizeFirstLetter(
                                                  guest.first_name
                                              )
                                    }
                                    onBlur={(event) => {
                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                        setGuestFirstNameBlurred(true);
                                        setGuestAndUpdate({
                                            ...createGuestIfNull(guest),
                                            first_name: capitalizedValue,
                                        });
                                        event.target.value = capitalizedValue;
                                    }}
                                    error={!!guestFirstNameError}
                                    helperText={guestFirstNameError}
                                />
                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    label="Last Name"
                                    defaultValue={
                                        guest === null ||
                                        guest.last_name === null
                                            ? ""
                                            : capitalizeFirstLetter(
                                                  guest.last_name
                                              )
                                    }
                                    onBlur={(event) => {
                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                        setGuestLastNameBlurred(true);
                                        setGuestAndUpdate({
                                            ...createGuestIfNull(guest),
                                            last_name: capitalizedValue,
                                        });
                                        event.target.value = capitalizedValue;
                                    }}
                                    error={!!guestLastNameError}
                                    helperText={guestLastNameError}
                                    fullWidth
                                />
                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    label="Phone Number"
                                    defaultValue={
                                        guest === null ||
                                        guest.phone_number === null
                                            ? ""
                                            : guest.phone_number
                                    }
                                    onBlur={(event) => {
                                        setGuestPhoneNumberBlurred(true);
                                        setGuestAndUpdate({
                                            ...createGuestIfNull(guest),
                                            phone_number: event.target.value,
                                        });
                                    }}
                                    error={!!guestPhoneNumberError}
                                    helperText={guestPhoneNumberError}
                                    fullWidth
                                />
                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    label="Email"
                                    defaultValue={
                                        guest === null || guest.email === null
                                            ? ""
                                            : guest.email
                                    }
                                    onBlur={(event) => {
                                        setGuestEmailBlurred(true);
                                        setGuestAndUpdate({
                                            ...createGuestIfNull(guest),
                                            email: event.target.value,
                                        });
                                    }}
                                    error={!!guestEmailError}
                                    helperText={guestEmailError}
                                    fullWidth
                                />
                                <Divider
                                    sx={{
                                        marginBottom: "10px",
                                        marginTop: "5px",
                                    }}
                                />
                            </Stack>
                            <Stack
                                direction="column"
                                spacing="10px"
                                sx={{
                                    marginTop: "5px",
                                    width: "85%"
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "19.36px",
                                        textAlign: "left",
                                    }}
                                >
                                    Who is receiving this service?
                                </Typography>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "16.94px",
                                        textAlign: "left",
                                        color: "black",
                                        marginBottom: "0px",
                                    }}
                                >
                                    Guest 1
                                </Typography>
                                {/* checkboxes are controlled unlike text fields which are not */}
                                <FormControlLabel
                                    sx={{
                                        marginTop: "-5px",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "16.94px",
                                        textAlign: "left",
                                    }}
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon />}
                                            checkedIcon={<CheckBoxIcon />}
                                            checked={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            onChange={(event) => {
                                                recipientsInfo.include_guest_as_recipient =
                                                    event.target.checked;
                                                if (
                                                    !recipientsInfo.include_guest_as_recipient &&
                                                    recipientTextFieldsRefs
                                                        .current.length > 0
                                                ) {
                                                    recipientTextFieldsRefs.current[0].value =
                                                        recipient1FirstName;
                                                    recipientTextFieldsRefs.current[1].value =
                                                        recipient1Allergies;
                                                }
                                                setRecipientsAndUpdate(
                                                    {
                                                        ...recipientsInfo,
                                                        recipients:
                                                            applyChangeToRecipients(),
                                                        include_guest_as_recipient:
                                                            event.target
                                                                .checked,
                                                    },
                                                    guestAllergies
                                                );
                                            }}
                                        />
                                    }
                                    label="Guest 1 is same as contact"
                                />
                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    defaultValue={
                                        guest === null ||
                                        guest.first_name === null
                                            ? ""
                                            : guest.first_name
                                    }
                                    label="First Name"
                                    disabled={
                                        recipientsInfo.include_guest_as_recipient
                                    }
                                    value={
                                        recipientsInfo.include_guest_as_recipient
                                            ? capitalizeFirstLetter(
                                                  guest.first_name
                                              )
                                            : capitalizeFirstLetter(
                                                  recipient1FirstName
                                              )
                                    }
                                    onChange={(event) => {
                                        if (
                                            !recipientsInfo.include_guest_as_recipient
                                        ) {
                                            setRecipient1FirstName(
                                                event.target.value
                                            );
                                        }
                                    }}
                                    onBlur={(event) => {
                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                        setRecipientsAndUpdate(
                                            {
                                                ...recipientsInfo,
                                                recipients:
                                                    applyChangeToRecipients(),
                                            },
                                            guestAllergies
                                        );
                                        event.target.value = capitalizedValue;
                                    }}
                                    error={!!recipientErrors.firstNameErrors[0]}
                                    helperText={
                                        recipientErrors.firstNameErrors[0]
                                    }
                                    inputRef={(el) => setRef(0, el)}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    sx={textFieldstyle}
                                    id="standard-size-normal"
                                    variant="standard"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position="start"></InputAdornment>
                                        ),
                                    }}
                                    defaultValue={
                                        guest === null ||
                                        guest.allergies === null
                                            ? ""
                                            : capitalizeFirstLetter(
                                                  guest.allergies
                                              )
                                    }
                                    label="Allergies/Meal Preferences"
                                    value={
                                        recipientsInfo.include_guest_as_recipient
                                            ? capitalizeFirstLetter(
                                                  guestAllergies
                                              )
                                            : capitalizeFirstLetter(
                                                  recipient1Allergies
                                              )
                                    }
                                    onChange={(event) => {
                                        if (
                                            !recipientsInfo.include_guest_as_recipient
                                        ) {
                                            setRecipient1Allergies(
                                                event.target.value
                                            );
                                        }
                                        if (
                                            recipientsInfo.include_guest_as_recipient
                                        ) {
                                            setGuestAllergies(
                                                event.target.value
                                            );
                                        }
                                    }}
                                    onBlur={(event) => {
                                        setRecipientsAndUpdate(
                                            {
                                                ...recipientsInfo,
                                                recipients:
                                                    applyChangeToRecipients(),
                                            },
                                            guestAllergies
                                        );
                                    }}
                                    error={!!recipientErrors.allergiesErrors[0]}
                                    helperText={
                                        recipientErrors.allergiesErrors[0]
                                    }
                                    inputRef={(el) => setRef(1, el)}
                                    fullWidth
                                    margin="dense"
                                />
                            </Stack>

                            {Array.from(
                                {
                                    length:
                                        order.private_chef_menu_num_guests - 1,
                                },
                                (_, i) => i
                            ).map((num, index) => (
                                <>
                                    <Stack direction="column" spacing="0px" sx={{ width: "85%" }}>
                                        <Divider
                                            sx={{
                                                marginBottom: "2px",
                                                marginTop: "10px",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                marginTop: "15px",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                                color: "black",
                                                marginBottom: "15px",
                                            }}
                                        >
                                            Guest {num + 2}
                                        </Typography>

                                        <TextField
                                            sx={textFieldstyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            label="First Name"
                                            defaultValue={
                                                recipient2Index + num <
                                                recipientsInfo.recipients.length
                                                    ? capitalizeFirstLetter(
                                                          recipientsInfo
                                                              .recipients[
                                                              recipient2Index +
                                                                  num
                                                          ].first_name
                                                      )
                                                    : ""
                                            }
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipientsAndUpdate(
                                                    {
                                                        ...recipientsInfo,
                                                        recipients:
                                                            applyChangeToRecipients(),
                                                    },
                                                    guestAllergies
                                                );
                                                event.target.value = capitalizedValue;
                                            }}
                                            inputRef={(el) =>
                                                setRef(2 * index + 2, el)
                                            }
                                            error={
                                                !!recipientErrors
                                                    .firstNameErrors[
                                                    recipient2Index + num
                                                ]
                                            }
                                            helperText={
                                                recipientErrors.firstNameErrors[
                                                    recipient2Index + num
                                                ]
                                            }
                                        />
                                        <TextField
                                            sx={textFieldstyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            inputRef={(el) =>
                                                setRef(2 * index + 3, el)
                                            }
                                            label="Allergies/Meal Preferences"
                                            defaultValue={
                                                recipient2Index + num <
                                                recipientsInfo.recipients.length
                                                    ? capitalizeFirstLetter(
                                                          recipientsInfo
                                                              .recipients[
                                                              recipient2Index +
                                                                  num
                                                          ].allergies
                                                      )
                                                    : ""
                                            }
                                            onBlur={(event) => {
                                                // handleBlur(
                                                //     recipient2Index + num,
                                                //     "allergies"
                                                // );
                                                setRecipientsAndUpdate(
                                                    {
                                                        ...recipientsInfo,
                                                        recipients:
                                                            applyChangeToRecipients(),
                                                    },
                                                    guestAllergies
                                                );
                                            }}
                                            error={
                                                !!recipientErrors
                                                    .allergiesErrors[
                                                    recipient2Index + num
                                                ]
                                            }
                                            helperText={
                                                recipientErrors.allergiesErrors[
                                                    recipient2Index + num
                                                ]
                                            }
                                        />
                                    </Stack>
                                </>
                            ))}

                            <Stack
                                direction="column"
                                spacing="15px"
                                sx={{ width: "85%" }}
                            >
                                <Divider
                                    sx={{
                                        marginBottom: "0px",
                                        marginTop: "5px",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "19.36px",
                                        textAlign: "left",
                                    }}
                                >
                                    Additional instructions
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#717171",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "16.94px",
                                        textAlign: "left",
                                    }}
                                >
                                    Add any additional requests below! Our chef
                                    will reach out to confirm the details after
                                    checkout.
                                </Typography>
                                <TextField
                                    onBlur={(event) => {
                                        djangoEndpointPromise(
                                            "service-order/current/special_requests",
                                            {
                                                order_id: order.order_id,
                                                special_requests:
                                                    event.target.value,
                                            }
                                        );
                                        setOrder({
                                            ...order,
                                            special_requests:
                                                event.target.value,
                                        });
                                    }}
                                    defaultValue={
                                        order.special_requests === null
                                            ? ""
                                            : capitalizeFirstLetter(
                                                order.special_requests
                                            )
                                    }
                                    label=""
                                    multiline
                                    rows={4}
                                    fullWidth
                                    margin="dense"
                                    InputProps={{
                                        style: {
                                            borderRadius: "12px",
                                            border: "1px solid #79747E",
                                            height: "134px",
                                            background: "white",
                                        },
                                    }}
                                    sx={{
                                        boxSizing: "border-box",
                                        width: "100%",
                                    }}
                                />
                                <Button
                                    disabled={!isValid}
                                    onClick={() =>
                                        navigate(PRIVATE_CHEF_PAGINATION[5])
                                    }
                                    sx={{
                                        height: "44px",
                                        width: "100%",
                                        borderRadius: "100px",
                                        gap: "8px",
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "0.10000000149011612px",
                                        textAlign: "center",
                                    }}
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Stack>
                    </Box>
                )}
                {!Mobile && (
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                            // height: "100vh",
                            // overflowY: "auto",
                            paddingBottom: "125px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={COLUMN_FLEX_SPACING}
                            sx={{
                                marginTop: "97px",
                                marginLeft: "100px",
                                alignItems: "flex-start",
                                width: "100%",
                            }}
                        >
                            <Title title="Contact Information" />
                            <AppDotPaginationwBack
                                page={4}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />
                            <Stack
                                direction={Large ? "row" : "column"}
                                spacing="39px"
                                sx={{ marginTop: "18.04px" }}
                            >
                                <Box
                                    sx={{
                                        width: "798px",
                                        top: "340px",
                                        left: "103px",
                                        padding: "43px 50px 43px 50px",
                                        gap: "26px",
                                        borderRadius: "20px",
                                        opacity: "0px",
                                        background: "#EDEDED",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who should we contact?
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            spacing="26px"
                                            sx={{
                                                marginBottom: "0px",
                                            }}
                                        >
                                            <TextField
                                                sx={textFieldstyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                fullWidth
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="First Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.first_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.first_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestFirstNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        first_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestFirstNameError}
                                                helperText={guestFirstNameError}
                                            />
                                            <TextField
                                                sx={textFieldstyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Last Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.last_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.last_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestLastNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        last_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestLastNameError}
                                                helperText={guestLastNameError}
                                                fullWidth
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing="26px"
                                            sx={{
                                                marginTop: "-5px",
                                            }}
                                        >
                                            <TextField
                                                sx={textFieldstyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Phone Number"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.phone_number === null
                                                        ? ""
                                                        : guest.phone_number
                                                }
                                                onBlur={(event) => {
                                                    setGuestPhoneNumberBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        phone_number:
                                                            event.target.value,
                                                    });
                                                }}
                                                error={!!guestPhoneNumberError}
                                                helperText={
                                                    guestPhoneNumberError
                                                }
                                                fullWidth
                                            />
                                            <TextField
                                                sx={textFieldstyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Email"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.email === null
                                                        ? ""
                                                        : guest.email
                                                }
                                                onBlur={(event) => {
                                                    setGuestEmailBlurred(true);
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        email: event.target
                                                            .value,
                                                    });
                                                }}
                                                error={!!guestEmailError}
                                                helperText={guestEmailError}
                                                fullWidth
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        direction="column"
                                        spacing="10px"
                                        sx={{
                                            marginTop: "5px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who is receiving this service?
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                                color: "black",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            Guest 1
                                        </Typography>
                                        {/* checkboxes are controlled unlike text fields which are not */}
                                        <FormControlLabel
                                            sx={{
                                                marginTop: "-5px",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                            control={
                                                <Checkbox
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon />
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon />
                                                    }
                                                    checked={
                                                        recipientsInfo.include_guest_as_recipient
                                                    }
                                                    onChange={(event) => {
                                                        recipientsInfo.include_guest_as_recipient =
                                                            event.target.checked;

                                                        if (
                                                            !recipientsInfo.include_guest_as_recipient &&
                                                            recipientTextFieldsRefs
                                                                .current
                                                                .length > 0
                                                        ) {
                                                            recipientTextFieldsRefs.current[0].value =
                                                                recipient1FirstName;
                                                            recipientTextFieldsRefs.current[1].value =
                                                                recipient1Allergies;
                                                        }
                                                        setRecipientsAndUpdate(
                                                            {
                                                                ...recipientsInfo,
                                                                recipients:
                                                                    applyChangeToRecipients(),
                                                                include_guest_as_recipient:
                                                                    event.target
                                                                        .checked,
                                                            },
                                                            guestAllergies
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Guest 1 is same as contact"
                                        />
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        spacing="26px"
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "-5px",
                                        }}
                                    >
                                        <TextField
                                            sx={textFieldstyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.first_name === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                            }
                                            label="First Name"
                                            disabled={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1FirstName
                                                      )
                                            }
                                            onChange={(event) => {
                                                if (
                                                    !recipientsInfo.include_guest_as_recipient
                                                ) {
                                                    setRecipient1FirstName(
                                                        event.target.value
                                                    );
                                                }
                                            }}
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipientsAndUpdate(
                                                    {
                                                        ...recipientsInfo,
                                                        recipients:
                                                            applyChangeToRecipients(),
                                                    },
                                                    guestAllergies
                                                );
                                                event.target.value = capitalizedValue;
                                            }}
                                            error={
                                                !!recipientErrors
                                                    .firstNameErrors[0]
                                            }
                                            inputRef={(el) => setRef(0, el)}
                                            helperText={
                                                recipientErrors
                                                    .firstNameErrors[0]
                                            }
                                            fullWidth
                                            margin="dense"
                                        />
                                        <TextField
                                            sx={textFieldstyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.last_name === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          guest.allergies
                                                      )
                                            }
                                            label="Allergies/Meal Preferences"
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guestAllergies
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1Allergies
                                                      )
                                            }
                                            onChange={(event) => {
                                                if (
                                                    !recipientsInfo.include_guest_as_recipient
                                                ) {
                                                    setRecipient1Allergies(
                                                        event.target.value
                                                    );
                                                }
                                                if (
                                                    recipientsInfo.include_guest_as_recipient
                                                ) {
                                                    setGuestAllergies(
                                                        event.target.value
                                                    );
                                                }
                                            }}
                                            onBlur={(event) => {
                                                setRecipientsAndUpdate(
                                                    {
                                                        ...recipientsInfo,
                                                        recipients:
                                                            applyChangeToRecipients(),
                                                    },
                                                    guestAllergies
                                                );
                                            }}
                                            error={
                                                !!recipientErrors
                                                    .allergiesErrors[0]
                                            }
                                            inputRef={(el) => setRef(1, el)}
                                            helperText={
                                                recipientErrors
                                                    .allergiesErrors[0]
                                            }
                                            fullWidth
                                            margin="dense"
                                        />
                                    </Stack>

                                    {Array.from(
                                        {
                                            length:
                                                order.private_chef_menu_num_guests -
                                                1,
                                        },
                                        (_, i) => i
                                    ).map((num, index) => (
                                        <>
                                            <Stack
                                                direction="column"
                                                spacing="0px"
                                            >
                                                <Typography
                                                    sx={{
                                                        marginTop: "15px",
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                        textAlign: "left",
                                                        color: "black",
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    Guest {num + 2}
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    spacing="26px"
                                                    sx={{
                                                        marginTop: "0px",
                                                    }}
                                                >
                                                    <TextField
                                                        sx={textFieldstyle}
                                                        id="standard-size-normal"
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start"></InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        label="First Name"
                                                        defaultValue={
                                                            recipient2Index +
                                                                num <
                                                            recipientsInfo
                                                                .recipients
                                                                .length
                                                                ? capitalizeFirstLetter(
                                                                      recipientsInfo
                                                                          .recipients[
                                                                          recipient2Index +
                                                                              num
                                                                      ]
                                                                          .first_name
                                                                  )
                                                                : ""
                                                        }
                                                        onBlur={(event) => {
                                                            const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                            setRecipientsAndUpdate(
                                                                {
                                                                    ...recipientsInfo,
                                                                    recipients:
                                                                        applyChangeToRecipients(),
                                                                },
                                                                guestAllergies
                                                            );
                                                            event.target.value = capitalizedValue;
                                                        }}
                                                        error={
                                                            !!recipientErrors
                                                                .firstNameErrors[
                                                                recipient2Index +
                                                                    num
                                                            ]
                                                        }
                                                        helperText={
                                                            recipientErrors
                                                                .firstNameErrors[
                                                                recipient2Index +
                                                                    num
                                                            ]
                                                        }
                                                        inputRef={(el) =>
                                                            setRef(
                                                                2 * index + 2,
                                                                el
                                                            )
                                                        }
                                                    />
                                                    <TextField
                                                        sx={textFieldstyle}
                                                        id="standard-size-normal"
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            startAdornment: (
                                                                <InputAdornment position="start"></InputAdornment>
                                                            ),
                                                        }}
                                                        fullWidth
                                                        label="Allergies/Meal Preferences"
                                                        defaultValue={
                                                            recipient2Index +
                                                                num <
                                                            recipientsInfo
                                                                .recipients
                                                                .length
                                                                ? capitalizeFirstLetter(
                                                                      recipientsInfo
                                                                          .recipients[
                                                                          recipient2Index +
                                                                              num
                                                                      ]
                                                                          .allergies
                                                                  )
                                                                : ""
                                                        }
                                                        onBlur={(event) => {
                                                            // handleBlur(
                                                            //     recipient2Index + num,
                                                            //     "allergies"
                                                            // );
                                                            setRecipientsAndUpdate(
                                                                {
                                                                    ...recipientsInfo,
                                                                    recipients:
                                                                        applyChangeToRecipients(),
                                                                },
                                                                guestAllergies
                                                            );
                                                        }}
                                                        error={
                                                            !!recipientErrors
                                                                .allergiesErrors[
                                                                recipient2Index +
                                                                    num
                                                            ]
                                                        }
                                                        helperText={
                                                            recipientErrors
                                                                .allergiesErrors[
                                                                recipient2Index +
                                                                    num
                                                            ]
                                                        }
                                                        inputRef={(el) =>
                                                            setRef(
                                                                2 * index + 3,
                                                                el
                                                            )
                                                        }
                                                    />
                                                </Stack>
                                            </Stack>
                                        </>
                                    ))}
                                </Box>
                                <Box
                                    sx={{
                                        width: "472px",
                                        //height: "663px",
                                        padding: "31px 34px 31px 34px",
                                        gap: "15px",
                                        borderRadius: "12px",
                                        opacity: "0px",
                                        background: "#EDEDED",
                                        marginRight: "100px",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <img
                                            src={menuInfo.menu.menu_image}
                                            alt="Back Massage w/ Stones Laid on Spine"
                                            style={{
                                                width: "100%",
                                                height: "265px",
                                                borderRadius: "12px",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Additional instructions
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#717171",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Add any additional requests below!
                                            Our chef will reach out to confirm
                                            the details after checkout.
                                        </Typography>
                                        <TextField
                                            onBlur={(event) => {
                                                djangoEndpointPromise(
                                                    "service-order/current/special_requests",
                                                    {
                                                        order_id:
                                                            order.order_id,
                                                        special_requests:
                                                            event.target.value,
                                                    }
                                                );
                                                setOrder({
                                                    ...order,
                                                    special_requests:
                                                        event.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                order.special_requests === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                        order.special_requests
                                                    )
                                            }
                                            label=""
                                            multiline
                                            rows={4}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                    border: "1px solid #79747E",
                                                    height: "140px",
                                                    background: "white",
                                                },
                                            }}
                                            sx={{
                                                boxSizing: "border-box",
                                                width: "100%",
                                            }}
                                        />
                                        <Button
                                            disabled={!isValid}
                                            onClick={() =>
                                                navigate(
                                                    PRIVATE_CHEF_PAGINATION[5]
                                                )
                                            }
                                            sx={{
                                                height: "44px",
                                                width: "100%",
                                                borderRadius: "100px",
                                                gap: "8px",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                letterSpacing:
                                                    "0.10000000149011612px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                )}
            </HeaderFooter>
        </Box>
    );
};

const PrivateChefGuestRecipientInfo = () => {
    const orderHook = useCurrentServiceOrder();
    const guestHook = useCurrentGuest();
    const recipientsHook = useCurrentRecipients();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [guest, guestTryLoad, _setGuest] = guestHook;
    const [recipients, recipientsTryLoad, _setRecipients] = recipientsHook;
    const [host, hostTryLoad, _setHost] = hostHook;
    const [menu, setMenu] = useCurrentMenu();

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);
    // Since guestTryLoad is useCallback() with a dependency on what is essentially order?.order_id,
    // this function will reload when the ID changes.
    useEffect(() => {
        guestTryLoad();
    }, [guestTryLoad]);
    // The same applies for recipientsTryLoad.
    useEffect(() => {
        recipientsTryLoad();
    }, [recipientsTryLoad]);    
    return (
        <Loading
            fetchResult={mergeMultipleResults(order, guest, recipients, host)}
            onTryAgain={orderTryLoad}
            renderChildren={() => (
                <Loaded
                    orderHook={orderHook}
                    guestHook={guestHook}
                    recipientsHook={recipientsHook}
                    hostInfo={host}
                    menuInfo={menu}
                />
            )}
        />
    );
};

export default PrivateChefGuestRecipientInfo;
