import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    useMediaQuery,
    Stack,
    Alert,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    Loading,
    LoadingIndicator,
    AppDotPagination,
    AppDotPaginationwBack,
    Title,
    MobileTitle,
    HeaderFooter,
    CustomCalendar,
} from "../components/UI";
import {
    djangoEndpointPromise,
    fetchResultType,
    useCurrentServiceOrder,
    PRIVATE_CHEF_PAGINATION,
    mergeMultipleResults,
    useCurrentHost,
    useCurrentMenu,
    MUTED_BUTTON_SELECTED_STRINGS,
} from "../util";
import logo from "../assets/ellipse.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const joinMenu = async (orderId, setMenu) => {
    try {
        const url = "private-chef-menu/input-and-join-menu";

        const result = await djangoEndpointPromise(url, {
            order_id: orderId,
        });

        if (fetchResultType(result) === "error") {
            throw new Error(
                "Server Error: Something went wrong on the server."
            );
        }
        setMenu(result.data);
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

function useGetMenus(order, date) {
    const [menus, setMenus] = useState(null);
    const url = "private-chef-menu/retrieve-applicable-menus";
    const dateString = date === null ? "" : date.toISOString();

    const orderId = order.order_id;
    const tryLoad = useCallback(async () => {
        try {
            if (dateString === "") {
                return;
            }
            if (orderId === undefined) {
                setMenus({
                    error: ["Order ID or datestring is undefined", orderId],
                });
                return;
            } else {
                setMenus({});
            }

            const response = await djangoEndpointPromise(url, {
                order_id: orderId,
                date: dateString,
            });

            if (fetchResultType(response) === "error") {
                throw new Error(
                    "Server Error: Something went wrong on the server."
                );
            }

            setMenus(response.data);
        } catch (error) {
            console.error("Error with backend:", error);
        }
    }, [dateString, orderId]);
    useEffect(() => {
        tryLoad();
    }, [tryLoad]);

    return [menus, setMenus];
}

const MenuTypeButton = ({ menuInfo, selected, onClick }) => {
    return (
        <Button
            onClick={onClick}
            variant={selected ? "contained" : "outlined"}
            sx={{
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 20px",
                width: "100%",
                height: "40px",
                backgroundColor: selected ? "#0F3562" : "white",

                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
                borderRadius: "12px",
                color: selected ? "white" : "black",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <span>{menuInfo.menu_name}</span>
                <ChevronRightIcon
                    sx={{ color: selected ? "white" : "black" }}
                />
            </Box>
        </Button>
    );
};

const PrivateChefCalendarUI = ({
    orderHook,
    hostInfo,
    date,
    setDate,
    setMenu,
}) => {
    const [order, tryAgain, setOrder] = orderHook;
    const navigate = useNavigate();
    const [validate, setValidate] = useState(false);

    const [menus, setMenus] = useGetMenus(order, date);

    const menusResultType = menus === null ? null : fetchResultType(menus);

    const availableMenusElement =
        menusResultType === null ? (
            <Typography variant="body1">
                Select a date to view available menus.
            </Typography>
        ) : menusResultType === "loading" ? (
            <LoadingIndicator />
        ) : menusResultType === "error" ? (
            <>
                <Typography variant="body1">
                    An error occurred while loading the menus.
                </Typography>
            </>
        ) : menus.length === 0 ? (
            <>
                <Typography variant="body1">No menus available.</Typography>
            </>
        ) : (
            <>
                {menus.map((menu, index) => (
                    <MenuTypeButton
                        key={index}
                        menuInfo={menu}
                        selected={
                            menu.menu_id ===
                            Number(order.private_chef_menu_requested)
                        }
                        onClick={() => {
                            const value =
                                menu.menu_id ===
                                Number(order.private_chef_menu_requested)
                                    ? null
                                    : menu.menu_id;
                            setOrder({
                                ...order,
                                private_chef_menu_requested: value,
                            });

                            setIsValid(value === null ? false : true);

                            djangoEndpointPromise(
                                "private-chef-menu/input-menu-id",
                                {
                                    order_id: order.order_id,
                                    menu_id: value,
                                }
                            );
                        }}
                    />
                ))}
            </>
        );

    const errorMessages = [];

    const [isValid, setIsValid] = useState(
        order.private_chef_menu_requested !== null
    );

    const Mobile = useMediaQuery("(max-width:600px)");
    const minWidthValue = Mobile ? "0px" : "1512px";
    const mobileCalendarWidth = "100%";

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: minWidthValue,
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={!Mobile}>
                    {Mobile && (
                        <Box
                            sx={{
                                bgcolor: "#F2F2F2",
                                paddingBottom: "20px",
                                flexGrow: 1,
                                width: "100%",
                                overflowX: "auto",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "20px",
                                    alignItems: "center",
                                    padding: "0px 16px 0px 16px",
                                }}
                            >
                                <AppDotPagination
                                    page={1}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                />
                                <MobileTitle title={"Select a Date"} />

                                <CustomCalendar
                                    timezone={order.timezone}
                                    date={date}
                                    setDate={setDate}
                                    width={mobileCalendarWidth}
                                    height={366}
                                    dateHeight={40}
                                    borderRadius={24}
                                />

                                <Stack
                                    direction="column"
                                    sx={{
                                        gap: "20px",
                                        alignItems: "flex-start",
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        component="div"
                                        sx={{
                                            marginLeft: "16px",
                                            fontFamily: "Inter",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "19.36px",
                                            textAlign: "left",
                                            color: "#1D1B20",
                                            // marginTop: "0px",
                                            //marginLeft: "35px",
                                            //marginBottom: "0px",
                                        }}
                                    >
                                        Available Menus
                                    </Typography>

                                    {availableMenusElement}

                                    {validate && !isValid && (
                                        <Alert
                                            variant="filled"
                                            severity="error"
                                        >
                                            {errorMessages.map((message) => (
                                                <Typography
                                                    key={message}
                                                    variant="body1"
                                                    sx={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {message}
                                                </Typography>
                                            ))}
                                        </Alert>
                                    )}
                                    <Button
                                        disabled={!isValid}
                                        onClick={async () => {
                                            if (isValid) {
                                                tryAgain();
                                                await joinMenu(
                                                    order.order_id,
                                                    setMenu
                                                );
                                                navigate(
                                                    PRIVATE_CHEF_PAGINATION[2]
                                                );
                                            }
                                        }}
                                        sx={{
                                            width: "100%",
                                            height: "44px",
                                            // marginBottom: "30px",
                                            // marginLeft: "25px",
                                            // marginTop: "25px",
                                            borderRadius: "100px",
                                            gap: "8px",
                                            alignItems: "center",
                                        }}
                                        fullWidth
                                    >
                                        Next
                                    </Button>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                    {!Mobile && (
                        <Box
                            sx={{
                                bgcolor: "#FDFDFD",
                                paddingBottom: "125px",
                                flexGrow: "1",
                                //width: "100%",
                                overflowX: "auto",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "97px",
                                    marginLeft: "100px",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Title title="Select a date" />
                                <AppDotPaginationwBack
                                    page={1}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                />
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        width: "1428px",
                                    }}
                                >
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <Box
                                            sx={{
                                                width: "798px",
                                            }}
                                        >
                                            <CustomCalendar
                                                timezone={order.timezone}
                                                date={date}
                                                setDate={setDate}
                                                width={798}
                                                height={575}
                                                dateHeight={78}
                                                borderRadius={8}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={5}>
                                        <Box
                                            sx={{
                                                width: "515px",
                                                gap: "20px",
                                                borderRadius: "12px",
                                                opacity: "0px",
                                                background: "#EDEDED",
                                                padding: "37px 36px 28px 36px",
                                                //paddingTop: "20px",
                                            }}
                                        >
                                            <Stack
                                                direction="column"
                                                spacing="12px"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontSize: "20px",
                                                        fontWeight: "600",
                                                        lineHeight: "24.2px",
                                                        textAlign: "left",
                                                        color: "#1D1B20",
                                                    }}
                                                >
                                                    Available Menus
                                                </Typography>
                                                {availableMenusElement}

                                                {validate && !isValid && (
                                                    <Alert
                                                        variant="filled"
                                                        severity="error"
                                                    >
                                                        {errorMessages.map(
                                                            (message) => (
                                                                <Typography
                                                                    key={
                                                                        message
                                                                    }
                                                                    variant="body1"
                                                                    sx={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {message}
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Alert>
                                                )}
                                                <Button
                                                    disabled={!isValid}
                                                    onClick={async () => {
                                                        if (isValid) {
                                                            tryAgain();
                                                            await joinMenu(
                                                                order.order_id,
                                                                setMenu
                                                            );
                                                            navigate(
                                                                PRIVATE_CHEF_PAGINATION[2]
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        height: "44px",
                                                        marginTop: "20px",
                                                        borderRadius: "100px",
                                                        gap: "8px",
                                                    }}
                                                    fullWidth
                                                >
                                                    Next
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    )}
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    const [date, setDate] = useState(
        order.time_requested === null
            ? null
            : dayjs.utc(order.time_requested).tz(order.timezone)
    );

    const [menu, setMenu] = useCurrentMenu();

    return (
        <>
            <PrivateChefCalendarUI
                orderHook={orderHook}
                hostInfo={host}
                date={date}
                setDate={setDate}
                setMenu={setMenu}
            />
        </>
    );
};

const PrivateChefCalendar = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default PrivateChefCalendar;
