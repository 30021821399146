import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCurrentServiceOrder, fetchResultType } from "../util";
import { LoadingIndicator } from "./UI";

const ProtectedRoutes = () => {
    const [order, orderTryLoad, setOrder] = useCurrentServiceOrder();

    const resultType = fetchResultType(order);

    return resultType === "loading" ? (
        <LoadingIndicator />
    ) : order.order_id === undefined ||
      ("status" in order && order.status === "requested") ? (
        <Navigate to={"/invalid-order"} />
    ) : (
        <Outlet />
    );
};

export default ProtectedRoutes;
