import React, { useState, useEffect, createContext, useContext } from "react";
import { BrowserRouter as Router, Navigate } from "react-router-dom";
import {
    secret,
    useCurrentServiceOrder,
    djangoEndpointPromise,
    fetchResultType,
    useCurrentHost,
} from "../util";
import { HeaderFooter, Loading } from "../components/UI";
import { Typography, Box, useMediaQuery, Stack } from "@mui/material";
import nexusLogo from "../assets/nexus.png";
import CircleIcon from "@mui/icons-material/Circle";

const PoliciesMobileUI = ({ orderHook, hostHook }) => {
    const [hostInfo, hostTryLoad, _setHost] = hostHook;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    background: "#F2F2F2",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            flexGrow: 1,
                            width: "100%",
                            height: "100%",
                            overflowY: "auto",
                            paddingBottom: "8px",
                            marginBottom: "20px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="12px"
                            sx={{
                                alignItems: "center",
                                marginTop: "20px",
                                paddingRight: "15px",
                                paddingLeft: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "21.78px",
                                    textAlign: "center",
                                }}
                            >
                                We partner with Nexus Concierge to offer the following policies:
                            </Typography>
                            <Stack
                                direction="row"
                                spacing="38px"
                                sx={{
                                    marginTop: "10px",
                                    height: "93px",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={hostInfo.logo_url}
                                    alt="Host Logo"
                                    style={{ height: "80px", width: "auto" }}
                                />
                                <CircleIcon
                                    sx={{
                                        width: "55px",
                                        height: "55px",
                                        color: "#D9D9D9",
                                    }}
                                />
                                <img
                                    src={nexusLogo}
                                    alt="Nexus Logo"
                                    style={{ height: "80px", width: "auto" }}
                                />
                            </Stack>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "26px",
                                    fontWeight: "500",
                                    lineHeight: "31.47px",
                                    textAlign: "left",
                                    marginTop: "15px",
                                }}
                            >
                                <b>Cancellation Policy</b>
                            </Typography>                            
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "21px",
                                }}
                            >
                                <b>For Orders Under $150:</b> Cancellations made more than 48 hours before the scheduled service date are eligible for a <b>full refund</b>.
                                <br></br>
                                <br></br>
                                <b>For Orders Over $150:</b> Cancellations made within 24 hours of the confirmation email being sent to the email address specified during the request process are eligible for a <b>full refund</b>, unless the cancellation is made within 48 hours of the scheduled service time. Cancellations made more than 48 hours before the scheduled service time are eligible for a <b>70% refund</b>.
                                <br></br>
                                <br></br>
                                <i>If, for any reason, you have a negative experience, or if you have questions about this policy, please do not hesitate to reach out to your host or Nexus Concierge at support@nexusupsells.com.</i>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "26px",
                                    fontWeight: "500",
                                    lineHeight: "31.47px",
                                    textAlign: "left",
                                    marginTop: "15px",
                                }}
                            >
                                <b>Satisfaction Guarantee</b>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "21px",
                                }}
                            >
                                While we take pride in our providers and are confident that you'll receive only the best services for your order, we are also prepared in the event that a provider fails to meet our standards. <i>We guarantee <b>full refunds</b> for any of the following situations where proof is provided:</i>
                                <br></br>
                                <br></br>
                                <b>1. Service Provider No-Show:</b> If the Service Provider fails to show up for the scheduled service without prior notice.
                                <br></br>
                                <br></br>
                                <b>2. Service Cancellation by the Company or Provider:</b> If the service is canceled by either the Company or the Service Provider due to unforeseen circumstances (e.g., illness, emergency), and rescheduling is not possible.
                                <br></br>
                                <br></br>
                                <b>3. Significant Delay:</b> If the Service Provider arrives significantly late.
                                <br></br>
                                <br></br>
                                <b>4. Service Not as Described:</b> If the service provided does not match the preferences specified by your request.
                                <br></br>
                                <br></br>
                                <b>5. Service Interruption or Incompletion:</b> If the service is interrupted or is not completed to the specifications of your request.
                                <br></br>
                                <br></br>
                                If any changes are made to your request that you agree to with your service provider, please note that any refund policies cannot be extended to cover these changes.  If your service provider notifies you about any changes prior to receiving your service (including the situations above), and you agree to receive the service, you may no longer be eligible for a refund.
                                <br></br>
                                <br></br>
                                <i>If, for any reason, you have a negative experience, or if you have questions about this policy, please do not hesitate to reach out to your host or Nexus Concierge at support@nexusupsells.com.</i>
                            </Typography>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const PoliciesDesktopUI = ({ orderHook, hostHook }) => {
    const [hostInfo, hostTryLoad, _setHost] = hostHook;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "1050px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    background: "white",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                            paddingBottom: "125px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="12px"
                            sx={{
                                alignItems: "center",
                                marginTop: "6vh",
                                marginLeft: "15vw",
                                marginRight: "15vw",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                    lineHeight: "21.78px",
                                    textAlign: "center",
                                }}
                            >
                                We partner with Nexus Concierge to offer the following policies:
                            </Typography>
                            <Stack
                                direction="row"
                                spacing="192px"
                                sx={{
                                    marginTop: "23px",
                                    height: "167px",
                                    alignItems: "center",
                                }}
                            >
                                <img
                                    src={hostInfo.logo_url}
                                    alt="Host Logo"
                                    style={{ width: "auto", height: "140px" }}
                                />
                                <CircleIcon
                                    sx={{
                                        width: "71px",
                                        height: "71px",
                                        color: "#D9D9D9",
                                    }}
                                />
                                <img
                                    src={nexusLogo}
                                    alt="Nexus Logo"
                                    style={{ width: "auto", height: "140px" }}
                                />
                            </Stack>                            
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "26px",
                                    fontWeight: "500",
                                    lineHeight: "31.47px",
                                    textAlign: "left",
                                }}
                            >
                                <b>Cancellation Policy</b>
                            </Typography>                            
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "21px",
                                }}
                            >
                                <b>For Orders Under $150:</b> Cancellations made more than 48 hours before the scheduled service date are eligible for a <b>full refund</b>.
                                <br></br>
                                <br></br>
                                <b>For Orders Over $150:</b> Cancellations made within 24 hours of the confirmation email being sent to the email address specified during the request process are eligible for a <b>full refund</b>, unless the cancellation is made within 48 hours of the scheduled service time. Cancellations made more than 48 hours before the scheduled service time are eligible for a <b>70% refund</b>.
                                <br></br>
                                <br></br>
                                <i>If, for any reason, you have a negative experience, or if you have questions about this policy, please do not hesitate to reach out to your host or Nexus Concierge at support@nexusupsells.com.</i>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "26px",
                                    fontWeight: "500",
                                    lineHeight: "31.47px",
                                    textAlign: "left",
                                    marginTop: "50px",
                                }}
                            >
                                <b>Satisfaction Guarantee</b>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "21px",
                                }}
                            >
                                While we take pride in our providers and are confident that you'll receive only the best services for your order, we are also prepared in the event that a provider fails to meet our standards. We guarantee full refunds for any of the following situations where proof is provided:
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "21px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <b>1. Service Provider No-Show:</b> If the Service Provider fails to show up for the scheduled service without prior notice.
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    paddingLeft: "30px",
                                }}
                            >
                                <b>2. Service Cancellation by the Company or Provider:</b> If the service is canceled by either the Company or the Service Provider due to unforeseen circumstances (e.g., illness, emergency), and rescheduling is not possible.
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    paddingLeft: "30px",
                                }}
                            >
                                <b>3. Significant Delay:</b> If the Service Provider arrives significantly late without an agreed change to the service's start time.
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    paddingLeft: "30px",
                                }}
                            >
                                <b>4. Service Not as Described:</b> If the service provided does not match the preferences specified by your request.
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    paddingLeft: "30px",
                                }}
                            >   <b>5. Service Interruption or Incompletion:</b> If the service is interrupted or is not completed to the specifications of your request.
                                <br></br>
                                <br></br>
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                    lineHeight: "24.2px",
                                    textAlign: "left",
                                    marginTop: "5px",
                                }}
                            >
                                If any changes are made to your request that you agree to with your service provider, please note that any refund policies cannot be extended to cover these changes.  If your service provider notifies you about any changes prior to receiving your service (including the situations above), and you agree to receive the service, you may no longer be eligible for a refund.
                                <br></br>
                                <br></br>
                                <i>If, for any reason, you have a negative experience, or if you have questions about this policy, please do not hesitate to reach out to your host or Nexus Concierge at support@nexusupsells.com.</i>
                            </Typography>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [order, _tryLoadOrder, setOrder] = orderHook;
    const [status, setStatus] = useState(null);
    const Mobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {Mobile ? (
                <PoliciesMobileUI
                    orderHook={orderHook}
                    hostHook={hostHook}
                />
            ) : (
                <PoliciesDesktopUI
                    orderHook={orderHook}
                    hostHook={hostHook}
                />
            )}
        </>
    );
}

const Policies = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;  

    const params = new URLSearchParams(window.location.search);
    const orderIdFromUrl = params.get('order_id');

    console.log(orderIdFromUrl);

    useEffect(() => {
        if (orderIdFromUrl) {
            // Update the order context with the order_id from the URL
            _setOrder({ order_id: orderIdFromUrl });

            // Use the new order_id to load the host context
            hostTryLoad();
        }
    }, [orderIdFromUrl, _setOrder, hostTryLoad]);


    return (
        <>
            <Loading
                fetchResult={order}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default Policies;
