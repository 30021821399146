import React, { useState, useEffect } from "react";
import { djangoEndpointPromise, fetchResultType } from "../util";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Divider,
    Button,
    Grid,
    useMediaQuery,
    Chip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import logo from "../assets/nexusproviderlogo.png";
import vector from "../assets/vector.png";
import { LoadingIndicator } from "../components/UI";

const RequestDescription = ({ chipState, isDesktop }) => {
    let requestTitle = "";
    let requestDesc = "";
    let requestAction = "";

    switch (chipState) {
        case "accepted":
            requestTitle = "Request Accepted!";
            requestDesc = "You've accepted this service request.";
            requestAction =
                "You will receive an email with all the details for this request. If your calendar is integrated with Nexus, this booking has already been added to your calendar.";
            break;
        case "denied":
            requestTitle = "Request Denied.";
            requestDesc = "You have denied this service request.";
            requestAction = "No further action is required.";
            break;
        case "expired":
            requestTitle = "Request No Longer Available.";
            requestDesc = "This service request is no longer available.";
            requestAction = "No further action is required.";
            break;
        default:
            requestTitle = "New Request Pending";
            requestDesc = "You've received a new service request!";
    }

    return (
        <>
            {isDesktop ? (
                <>
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "22px",
                            color: "#010424",
                            lineHeight: "21px",
                            letterSpacing: "0.1px",
                        }}
                    >
                        {requestTitle}
                    </Typography>
                    <Box
                        sx={{
                            paddingTop: "16px",
                            maxWidth: "578px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "16px",
                                color: "#2D2D2D",
                                lineHeight: "30px",
                            }}
                        >
                            {requestDesc}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "9px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "442px",
                                bgcolor: "#E5E5E5",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "8px",
                            width: "430px",
                        }}
                    >
                        {chipState === "no answer" ? (
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "#2D2D2D",
                                    lineHeight: "30px",
                                }}
                            >
                                Review the details and{" "}
                                <Typography
                                    display={"inline"}
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    accept
                                </Typography>{" "}
                                or{" "}
                                <Typography
                                    display={"inline"}
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 700,
                                        fontSize: "14px",
                                    }}
                                >
                                    decline
                                </Typography>{" "}
                                the request below
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "#2D2D2D",
                                    lineHeight: "25px",
                                }}
                            >
                                {requestAction}
                            </Typography>
                        )}
                    </Box>
                </>
            ) : (
                <>
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "22px",
                            color: "#010424",
                            lineHeight: "21px",
                            letterSpacing: "0.1px",
                        }}
                    >
                        {requestTitle}
                    </Typography>

                    <Box
                        sx={{
                            paddingTop: "12px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "16px",
                                color: "#2D2D2D",
                                lineHeight: "25px",
                            }}
                        >
                            {requestDesc}
                        </Typography>
                    </Box>
                    <Box sx={{ width: "90.9%" }}>
                        <Box
                            sx={{
                                paddingTop: "12px",
                                width: "100%",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "100%",
                                    bgcolor: "#E5E5E5",
                                    height: "1px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "12px",
                            }}
                        >
                            {chipState === "no answer" ? (
                                <Typography
                                    component="div"
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "#2D2D2D",
                                        lineHeight: "25px",
                                    }}
                                >
                                    Review the details and{" "}
                                    <Typography
                                        display={"inline"}
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                        }}
                                    >
                                        accept
                                    </Typography>{" "}
                                    or{" "}
                                    <Typography
                                        display={"inline"}
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: "14px",
                                        }}
                                    >
                                        decline
                                    </Typography>{" "}
                                    the request below.
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "25px",
                                    }}
                                >
                                    {requestAction}
                                </Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "12px",
                                width: "100%",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "100%",
                                    bgcolor: "#E5E5E5",
                                    height: "1px",
                                }}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

const AppointmentFrame = ({ text1, text2, padTop = "10px" }) => {
    return (
        <Box
            sx={{
                paddingTop: padTop,
                paddingLeft: "4%",
                width: "96%",
                height: "auto",
                textAlign: "left",
                overflowWrap: "break-word",
            }}
        >
            <Box display="flex" alignItems="center">
                <Box sx={{ width: "46%" }}>
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#2D2D2D",
                            lineHeight: "30px",
                        }}
                    >
                        {text1}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        paddingLeft: "6%",
                        width: "48%",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "#2D2D2D",
                            lineHeight: "30px",
                        }}
                    >
                        {text2}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const ChipComponent = ({ chipState }) => {
    let text = "";
    let backgroundColor = "";
    let isContained = false;

    switch (chipState) {
        case "accepted":
            text = "Accepted";
            backgroundColor = "#00E4DA";
            isContained = true;
            break;
        case "denied":
            text = "Denied";
            backgroundColor = "#FF0000";
            isContained = true;
            break;
        case "expired":
            text = "Expired";
            backgroundColor = "#2D2D2D";
            isContained = true;
            break;
        default:
            text = "No Answer";
    }
    return (
        <div>
            {isContained ? (
                <Chip
                    label={
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                letterSpacing: "0.16px",
                                fontWeight: 400,
                                color: "#FFFFFF",
                            }}
                        >
                            {text}
                        </Typography>
                    }
                    size="medium"
                    variant="contained"
                    sx={{
                        height: "32px",
                        borderColor: "#777777",
                        backgroundColor: { backgroundColor },
                    }}
                />
            ) : (
                <Chip
                    label={
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                letterSpacing: "0.16px",
                                fontWeight: 400,
                                color: "#777777",
                            }}
                        >
                            {text}
                        </Typography>
                    }
                    size="medium"
                    variant="outlined"
                    sx={{
                        height: "32px",
                        borderColor: "#777777",
                    }}
                />
            )}
        </div>
    );
};

const AcceptButton = ({ width = "100%", setChipState, uniqueId }) => (
    <Button
        variant="contained"
        sx={{
            borderRadius: "20px",
            width: width,
            backgroundColor: "#010424",
        }}
        onClick={() => onAccept(uniqueId, setChipState)}
    >
        <Typography
            sx={{
                textTransform: "None",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14px",
                color: "#FFFFFF",
                lineHeight: "20px",
                letterSpacing: "0.1px",
            }}
        >
            Accept
        </Typography>
    </Button>
);

const DenyButton = ({ width = "100%", setChipState, uniqueId }) => (
    <Button
        variant="outlined"
        sx={{
            borderRadius: "20px",
            width: width,
            borderColor: "black",
            backgroundColor: "#FFFFFF",
        }}
        onClick={() => onDeny(uniqueId, setChipState)}
    >
        <Typography
            sx={{
                textTransform: "None",
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "14px",
                color: "#010424",
                lineHeight: "20px",
                letterSpacing: "0.1px",
            }}
        >
            Deny
        </Typography>
    </Button>
);

const onAccept = async (uniqueId, setChipState) => {
    try {
        const response = await djangoEndpointPromise(
            "provider_response_handler",
            {
                hash_value: uniqueId,
                provider_response: "accept",
            }
        );

        if (fetchResultType(response) === "error") {
            if (response?.error_code === "expired") {
                alert(response.error);
            }
            throw new Error("Failed to accept.");
        }

        setChipState("accepted");
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

const onDeny = async (uniqueId, setChipState) => {
    try {
        const response = await djangoEndpointPromise(
            "provider_response_handler",
            {
                hash_value: uniqueId,
                provider_response: "deny",
            }
        );

        if (fetchResultType(response) === "error") {
            if (response?.error_code === "expired") {
                alert(response.error);
            }
            throw new Error("Failed to accept.");
        }

        setChipState("denied");
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

const ProviderRequestMassageMobileUI = ({
    requestData,
    chipState,
    setChipState,
    uniqueId,
}) => {
    return (
        <>
            <Box
                alignItems="center"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "375px",
                }}
            >
                <Box
                    sx={{
                        paddingTop: "9px",
                    }}
                >
                    <img
                        src={logo}
                        style={{
                            width: "127px",
                            height: "auto",
                            maxHeight: 56,
                        }}
                        alt="Logo"
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: "22px",
                    }}
                ></Box>
                <RequestDescription chipState={chipState} isDesktop={false} />
                <Box
                    sx={{
                        display: "flex",
                        width: "90.9%",
                        paddingTop: "12px",
                    }}
                >
                    <Box
                        sx={{
                            height: "18px",
                            alignSelf: "flex-start",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#010424",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                            }}
                        >
                            Appointment Details
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        paddingTop: "16px",
                    }}
                ></Box>
                <Box
                    border={1}
                    borderColor="#E5E5E5"
                    sx={{
                        width: "90.9%",
                        // height: "564px",
                    }}
                >
                    <AppointmentFrame text1="Date:" text2={requestData.date} />
                    <AppointmentFrame text1="Time:" text2={requestData.time} />
                    <AppointmentFrame
                        text1="Duration:"
                        text2={requestData.duration}
                    />
                    <AppointmentFrame
                        text1="Payout:"
                        text2={requestData.payout}
                    />
                    <Box
                        sx={{
                            paddingTop: "10px",
                            paddingLeft: "4%",
                            width: "96%",
                            height: "30px",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "46%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "#2D2D2D",
                                        lineHeight: "30px",
                                        textAlign: "left",
                                    }}
                                >
                                    Request Status:
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    paddingLeft: "6%",
                                    width: "48%",
                                    textAlign: "left",
                                }}
                            >
                                <ChipComponent chipState={chipState} />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "13px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "100%",
                                bgcolor: "#E5E5E5",
                                height: "1px",
                            }}
                        />
                    </Box>
                    <AppointmentFrame
                        text1="Number of Guests:"
                        text2={requestData.num_of_guests}
                    />
                    <AppointmentFrame
                        text1="Type:"
                        text2={
                            requestData.massage_type.charAt(0).toUpperCase() +
                            requestData.massage_type.slice(1) +
                            " Massage"
                        }
                    />
                    <AppointmentFrame
                        text1="Gender Requested:"
                        text2={
                            requestData.massage_gender_requested
                                .charAt(0)
                                .toUpperCase() +
                            requestData.massage_gender_requested.slice(1)
                        }
                    />
                    <AppointmentFrame
                        text1="Contact Name:"
                        text2={requestData.guests[0]}
                    />
                    {requestData.num_of_guests > 1 && (
                        <AppointmentFrame
                            text1=""
                            text2={"requestData.guests[1]"}
                            padTop="0px"
                        />
                    )}
                    <AppointmentFrame
                        text1="Special Requests:"
                        text2={
                            requestData.special_requests !== null
                                ? requestData.special_requests
                                : "None"
                        }
                    />
                          
                    <Box
                        sx={{
                            paddingTop: "13px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "100%",
                                bgcolor: "#E5E5E5",
                                height: "1px",
                            }}
                        />
                    </Box>
                    <AppointmentFrame
                        text1="Address:"
                        text2={
                            requestData.listing_address_1 +
                            requestData.listing_address_2 +
                            ", " +
                            requestData.listing_city +
                            ", " +
                            requestData.listing_state +
                            " " +
                            requestData.listing_zip
                        }
                    />
                    <AppointmentFrame
                        text1="Access Information:"
                        text2={requestData.access_info}
                    />
                    <AppointmentFrame
                        text1="Parking Instructions:"
                        text2={requestData.parking_instructions}
                    />
                    <AppointmentFrame
                        text1="Additional Instructions:"
                        text2={requestData.additional_instructions}
                    />
                    <AppointmentFrame
                        text1="Order ID:"
                        text2={
                            requestData.order_id !== null
                                ? requestData.order_id
                                : "None"
                        }
                    />
                          
                    {chipState === "no answer" ? (
                        <Box>
                            <Box
                                sx={{
                                    paddingTop: "15px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "22px",
                                    paddingBottom: "10px",
                                    paddingLeft: "4%",
                                    paddingRight: "4%",
                                    width: "92%",
                                    height: "44px",
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        sx={{
                                            width: "46%",
                                        }}
                                    >
                                        <AcceptButton
                                            setChipState={setChipState}
                                            uniqueId={uniqueId}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "46%",
                                        }}
                                    >
                                        <DenyButton
                                            setChipState={setChipState}
                                            uniqueId={uniqueId}
                                        />
                                    </Box>
                                </Box>
                            </Box>{" "}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                paddingTop: "15px",
                            }}
                        ></Box>
                    )}
                </Box>
                {!(chipState === "no answer") && (
                    <Box
                        sx={{
                            width: "90.9%",
                            paddingTop: "17px",
                            height: "75px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "25px",
                                color: "#2D2D2D",
                            }}
                        >
                            If you have any questions, contact customer support
                            at admin@nexusupsells.com or 385-200-0937
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

const ProviderRequestPrivateChefMobileUI = ({
    requestData,
    chipState,
    setChipState,
    uniqueId,
}) => {
    return (
        <>
            <Box
                alignItems="center"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "375px",
                }}
            >
                <Box
                    sx={{
                        paddingTop: "9px",
                    }}
                >
                    <img
                        src={logo}
                        style={{
                            width: "127px",
                            height: "auto",
                            maxHeight: 56,
                        }}
                        alt="Logo"
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: "22px",
                    }}
                ></Box>
                <RequestDescription chipState={chipState} isDesktop={false} />
                <Box
                    sx={{
                        display: "flex",
                        width: "90.9%",
                        paddingTop: "12px",
                    }}
                >
                    <Box
                        sx={{
                            height: "18px",
                            alignSelf: "flex-start",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#010424",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                            }}
                        >
                            Appointment Details
                        </Typography>
                    </Box>
                </Box>

                <Box
                    sx={{
                        paddingTop: "16px",
                    }}
                ></Box>
                <Box
                    border={1}
                    borderColor="#E5E5E5"
                    sx={{
                        width: "90.9%",
                        // height: "564px",
                    }}
                >
                    <AppointmentFrame text1="Date:" text2={requestData.date} />
                    <AppointmentFrame text1="Time:" text2={requestData.time} />
                    <AppointmentFrame
                        text1="Payout:"
                        text2={requestData.payout}
                    />
                    <Box
                        sx={{
                            paddingTop: "10px",
                            paddingLeft: "4%",
                            width: "96%",
                            height: "30px",
                        }}
                    >
                        <Box display="flex" alignItems="center">
                            <Box
                                sx={{
                                    width: "46%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        color: "#2D2D2D",
                                        lineHeight: "30px",
                                        textAlign: "left",
                                    }}
                                >
                                    Request Status:
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    paddingLeft: "6%",
                                    width: "48%",
                                    textAlign: "left",
                                }}
                            >
                                <ChipComponent chipState={chipState} />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "13px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "100%",
                                bgcolor: "#E5E5E5",
                                height: "1px",
                            }}
                        />
                    </Box>

                    <AppointmentFrame
                        text1="Menu:"
                        text2={requestData.menu_name}
                    />
                    <AppointmentFrame
                        text1="Dishes:"
                        text2={
                            requestData.dishes &&
                            requestData.dishes.length > 0 &&
                            requestData.dishes.map((dish, index) => (
                                <span key={index}>
                                    {dish.servings} servings of {dish.name} (
                                    {dish.course})<br />
                                </span>
                            ))
                        }
                    />
                    <Box
                        sx={{
                            paddingTop: "13px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "100%",
                                bgcolor: "#E5E5E5",
                                height: "1px",
                            }}
                        />
                    </Box>
                    <AppointmentFrame
                        text1="Party Size:"
                        text2={requestData.num_of_guests}
                    />
                    <AppointmentFrame
                        text1="Guests:"
                        text2={
                            requestData.recipients &&
                            requestData.recipients.length > 0 &&
                            requestData.recipients.map((rec, index) => (
                                <span key={index}>
                                    {rec.full_name}
                                    {rec.preferences && (
                                        <>
                                            <br />
                                            <em>*{rec.preferences}</em>
                                        </>
                                    )}
                                    <br />
                                </span>
                            ))
                        }
                    />
                    <AppointmentFrame
                        text1="Special Requests:"
                        text2={
                            requestData.special_requests !== null
                                ? requestData.special_requests
                                : "None"
                        }
                    />

                    <Box
                        sx={{
                            paddingTop: "13px",
                        }}
                    >
                        <Divider
                            sx={{
                                width: "100%",
                                bgcolor: "#E5E5E5",
                                height: "1px",
                            }}
                        />
                    </Box>
                    <AppointmentFrame
                        text1="Address:"
                        text2={
                            requestData.listing_address_1 +
                            requestData.listing_address_2 +
                            ", " +
                            requestData.listing_city +
                            ", " +
                            requestData.listing_state +
                            " " +
                            requestData.listing_zip
                        }
                    />
                    <AppointmentFrame
                        text1="Access Information:"
                        text2={requestData.access_info}
                    />
                    <AppointmentFrame
                        text1="Parking Instructions:"
                        text2={requestData.parking_instructions}
                    />
                    <AppointmentFrame
                        text1="Additional Instructions:"
                        text2={requestData.additional_instructions}
                    />
                    <AppointmentFrame
                        text1="Order ID:"
                        text2={
                            requestData.order_id !== null
                                ? requestData.order_id
                                : "None"
                        }
                    />

                    {chipState === "no answer" ? (
                        <Box>
                            <Box
                                sx={{
                                    paddingTop: "15px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "22px",
                                    paddingBottom: "10px",
                                    paddingLeft: "4%",
                                    paddingRight: "4%",
                                    width: "92%",
                                    height: "44px",
                                }}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        sx={{
                                            width: "46%",
                                        }}
                                    >
                                        <AcceptButton
                                            setChipState={setChipState}
                                            uniqueId={uniqueId}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "46%",
                                        }}
                                    >
                                        <DenyButton
                                            setChipState={setChipState}
                                            uniqueId={uniqueId}
                                        />
                                    </Box>
                                </Box>
                            </Box>{" "}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                paddingTop: "15px",
                            }}
                        ></Box>
                    )}
                </Box>
                {!(chipState === "no answer") && (
                    <Box
                        sx={{
                            width: "90.9%",
                            paddingTop: "17px",
                            height: "75px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "25px",
                                color: "#2D2D2D",
                            }}
                        >
                            If you have any questions, contact customer support
                            at admin@nexusupsells.com or 385-200-0937
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    );
};

const ProviderRequestPrivateChefDesktopUI = ({
    requestData,
    chipState,
    setChipState,
    uniqueId,
}) => {
    return (
        <>
            <AppBar
                elevation={0}
                sx={{
                    boxShadow: "none",
                    borderBottom: "1px solid #E5E5E5",
                    bgcolor: "white",
                    height: "83px",
                    justifyContent: "center",
                }}
            >
                <Toolbar>
                    <Box
                        display="flex"
                        sx={{
                            width: 127,
                            maxWidth: "100%",
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: 56,
                            }}
                            alt="Logo"
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Grid container spacing={0} wrap="nowrap">
                <Grid item sx={{}}>
                    <Box sx={{ width: "275px", maxWidth: "275px" }}>
                        <Box sx={{ width: "274px", maxWidth: "275px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#2D2D2D",
                                    lineHeight: "30px",
                                    letterSpacing: "0.15px",
                                    textAlign: "left",
                                    paddingTop: "80px",
                                    paddingLeft: "16px",
                                }}
                            >
                                Provider Name
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "100%",
                                    maxWidth: "265px",
                                    bgcolor: "#E5E5E5",
                                    height: "1px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        width: "258px",
                                        maxWidth: "258px",
                                        height: "40px",
                                        backgroundColor: "#00E4DA33",
                                        display: "flex", // Use flex display
                                        alignItems: "center", // Align elements vertically
                                        paddingLeft: "8px",
                                    }}
                                >
                                    <img
                                        src={vector}
                                        style={{
                                            height: "auto",
                                            maxHeight: 56,
                                        }}
                                        alt="vector icon"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#010424",
                                            lineHeight: "21px",
                                            letterSpacing: "0.1px",
                                            paddingLeft: "17px",
                                        }}
                                    >
                                        Pending Request
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        height: "100vh",
                    }}
                >
                    <Divider orientation="vertical" flexItem />
                </Box>
                <Grid item xs>
                    <Box
                        sx={{
                            paddingTop: "50px",
                            paddingLeft: "43px",
                            textAlign: "left",
                        }}
                    >
                        <RequestDescription
                            chipState={chipState}
                            isDesktop={true}
                        />
                        <Box
                            sx={{
                                paddingTop: "8px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "442px",
                                    bgcolor: "#E5E5E5",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "16px",
                                width: "350px",
                                height: "18px",
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        color: "#010424",
                                        lineHeight: "30px",
                                        letterSpacing: "0.1px",
                                    }}
                                >
                                    Appointment Details
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "16px",
                            }}
                        ></Box>
                        <Box
                            border={1}
                            borderColor="#E5E5E5"
                            sx={{
                                width: "443px",
                                // height: "564px",
                            }}
                        >
                            <AppointmentFrame
                                text1="Date:"
                                text2={requestData.date}
                            />
                            <AppointmentFrame
                                text1="Time:"
                                text2={requestData.time}
                            />
                            <AppointmentFrame
                                text1="Payout:"
                                text2={requestData.payout}
                            />
                            <Box
                                sx={{
                                    paddingTop: "12px",
                                    paddingLeft: "17px",
                                    width: "357px",
                                    height: "30px",
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: "175px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "#2D2D2D",
                                                lineHeight: "30px",
                                            }}
                                        >
                                            Request Status:
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingLeft: "40px",
                                            width: "143px",
                                        }}
                                    >
                                        <ChipComponent chipState={chipState} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <AppointmentFrame
                                text1="Menu:"
                                text2={requestData.menu_name}
                            />
                            <AppointmentFrame
                                text1="Dishes:"
                                text2={
                                    requestData.dishes &&
                                    requestData.dishes.length > 0 &&
                                    requestData.dishes.map((dish, index) => (
                                        <span key={index}>
                                            {dish.servings} servings of{" "}
                                            {dish.name} ({dish.course})<br />
                                        </span>
                                    ))
                                }
                            />
                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <AppointmentFrame
                                text1="Party Size:"
                                text2={requestData.num_of_guests}
                            />
                            <AppointmentFrame
                                text1="Guests:"
                                text2={
                                    requestData.recipients &&
                                    requestData.recipients.length > 0 &&
                                    requestData.recipients.map((rec, index) => (
                                        <span key={index}>
                                            {rec.full_name}
                                            {rec.preferences && (
                                                <>
                                                    <br />
                                                    <em>*{rec.preferences}</em>
                                                </>
                                            )}
                                            <br />
                                        </span>
                                    ))
                                }
                            />
                            <AppointmentFrame
                                text1="Special Requests:"
                                text2={
                                    requestData.special_requests !== null
                                        ? requestData.special_requests
                                        : "None"
                                }
                            />

                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <AppointmentFrame
                                text1="Address:"
                                text2={
                                    requestData.listing_address_1 +
                                    requestData.listing_address_2 +
                                    ", " +
                                    requestData.listing_city +
                                    ", " +
                                    requestData.listing_state +
                                    " " +
                                    requestData.listing_zip
                                }
                            />
                            <AppointmentFrame
                                text1="Access Information:"
                                text2={requestData.access_info}
                            />
                            <AppointmentFrame
                                text1="Parking Instructions:"
                                text2={requestData.parking_instructions}
                            />
                            <AppointmentFrame
                                text1="Additional Instructions:"
                                text2={requestData.additional_instructions}
                            />
                            <AppointmentFrame
                                text1="Order ID:"
                                text2={
                                    requestData.order_id !== null
                                        ? requestData.order_id
                                        : "None"
                                }
                            />

                            {chipState === "no answer" ? (
                                <Box>
                                    <Box
                                        sx={{
                                            paddingTop: "15px",
                                        }}
                                    >
                                        <Divider
                                            sx={{
                                                width: "100%",
                                                bgcolor: "#E5E5E5",
                                                height: "1px",
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingTop: "22px",
                                            paddingBottom: "10px",
                                            paddingLeft: "19px",
                                            width: "390px",
                                            height: "44px",
                                        }}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <AcceptButton
                                                    width="191px"
                                                    setChipState={setChipState}
                                                    uniqueId={uniqueId}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    paddingLeft: "24px",
                                                }}
                                            >
                                                <DenyButton
                                                    width="191px"
                                                    setChipState={setChipState}
                                                    uniqueId={uniqueId}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        paddingTop: "15px",
                                    }}
                                ></Box>
                            )}
                        </Box>
                        {!(chipState === "no answer") && (
                            <Box
                                sx={{
                                    width: "443px",
                                    paddingTop: "17px",
                                    height: "50px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "25px",
                                        color: "#2D2D2D",
                                    }}
                                >
                                    If you have any questions, contact customer
                                    support at admin@nexusupsells.com or
                                    385-200-0937
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

const ProviderRequestMassageDesktopUI = ({
    requestData,
    chipState,
    setChipState,
    uniqueId,
}) => {
    return (
        <>
            <AppBar
                elevation={0}
                sx={{
                    boxShadow: "none",
                    borderBottom: "1px solid #E5E5E5",
                    bgcolor: "white",
                    height: "83px",
                    justifyContent: "center",
                }}
            >
                <Toolbar>
                    <Box
                        display="flex"
                        sx={{
                            width: 127,
                            maxWidth: "100%",
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: 56,
                            }}
                            alt="Logo"
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Grid container spacing={0} wrap="nowrap">
                <Grid item sx={{}}>
                    <Box sx={{ width: "275px", maxWidth: "275px" }}>
                        <Box sx={{ width: "274px", maxWidth: "275px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#2D2D2D",
                                    lineHeight: "30px",
                                    letterSpacing: "0.15px",
                                    textAlign: "left",
                                    paddingTop: "80px",
                                    paddingLeft: "16px",
                                }}
                            >
                                Provider Name
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "100%",
                                    maxWidth: "265px",
                                    bgcolor: "#E5E5E5",
                                    height: "1px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        width: "258px",
                                        maxWidth: "258px",
                                        height: "40px",
                                        backgroundColor: "#00E4DA33",
                                        display: "flex", // Use flex display
                                        alignItems: "center", // Align elements vertically
                                        paddingLeft: "8px",
                                    }}
                                >
                                    <img
                                        src={vector}
                                        style={{
                                            height: "auto",
                                            maxHeight: 56,
                                        }}
                                        alt="vector icon"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#010424",
                                            lineHeight: "21px",
                                            letterSpacing: "0.1px",
                                            paddingLeft: "17px",
                                        }}
                                    >
                                        Pending Request
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        height: "100vh",
                    }}
                >
                    <Divider orientation="vertical" flexItem />
                </Box>
                <Grid item xs>
                    <Box
                        sx={{
                            paddingTop: "50px",
                            paddingLeft: "43px",
                            textAlign: "left",
                        }}
                    >
                        <RequestDescription
                            chipState={chipState}
                            isDesktop={true}
                        />
                        <Box
                            sx={{
                                paddingTop: "8px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "442px",
                                    bgcolor: "#E5E5E5",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "16px",
                                width: "350px",
                                height: "18px",
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        color: "#010424",
                                        lineHeight: "30px",
                                        letterSpacing: "0.1px",
                                    }}
                                >
                                    Appointment Details
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "16px",
                            }}
                        ></Box>
                        <Box
                            border={1}
                            borderColor="#E5E5E5"
                            sx={{
                                width: "443px",
                                // height: "564px",
                            }}
                        >
                            <AppointmentFrame
                                text1="Date:"
                                text2={requestData.date}
                            />
                            <AppointmentFrame
                                text1="Time:"
                                text2={requestData.time}
                            />
                            <AppointmentFrame
                                text1="Duration:"
                                text2={requestData.duration}
                            />
                            <AppointmentFrame
                                text1="Payout:"
                                text2={requestData.payout}
                            />
                            <Box
                                sx={{
                                    paddingTop: "12px",
                                    paddingLeft: "17px",
                                    width: "357px",
                                    height: "30px",
                                }}
                            >
                                <Box display="flex" alignItems="center">
                                    <Box
                                        sx={{
                                            width: "175px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "#2D2D2D",
                                                lineHeight: "30px",
                                            }}
                                        >
                                            Request Status:
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingLeft: "40px",
                                            width: "143px",
                                        }}
                                    >
                                        <ChipComponent chipState={chipState} />
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <AppointmentFrame
                                text1="Number of Guests:"
                                text2={requestData.num_of_guests}
                            />
                            <AppointmentFrame
                                text1="Massage Type:"
                                text2={
                                    requestData.massage_type
                                        .charAt(0)
                                        .toUpperCase() +
                                    requestData.massage_type.slice(1) +
                                    " Massage"
                                }
                            />
                            <AppointmentFrame
                                text1="Gender Requested:"
                                text2={
                                    requestData.massage_gender_requested
                                        .charAt(0)
                                        .toUpperCase() +
                                    requestData.massage_gender_requested.slice(
                                        1
                                    )
                                }
                            />
                            <AppointmentFrame
                                text1="Contact Name:"
                                text2={requestData.guests[0]}
                            />
                            {requestData.num_of_guests > 1 && (
                                <Box
                                    sx={{
                                        paddingTop: "0px",
                                        paddingLeft: "17px",
                                        width: "357px",
                                        height: "30px",
                                    }}
                                >
                                    <Box display="flex" alignItems="center">
                                        <Box
                                            sx={{
                                                width: "175px",
                                            }}
                                        ></Box>
                                        <Box
                                            sx={{
                                                paddingLeft: "40px",
                                                width: "143px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Poppins",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    color: "#2D2D2D",
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                requestData.guests[1]
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            <AppointmentFrame
                                text1="Special Requests:"
                                text2={
                                    requestData.special_requests !== null
                                        ? requestData.special_requests
                                        : "None"
                                }
                            />

                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#E5E5E5",
                                        height: "1px",
                                    }}
                                />
                            </Box>
                            <AppointmentFrame
                                text1="Address:"
                                text2={
                                    requestData.listing_address_1 +
                                    requestData.listing_address_2 +
                                    ", " +
                                    requestData.listing_city +
                                    ", " +
                                    requestData.listing_state +
                                    " " +
                                    requestData.listing_zip
                                }
                            />
                            <AppointmentFrame
                                text1="Access Information:"
                                text2={requestData.access_info}
                            />
                            <AppointmentFrame
                                text1="Parking Instructions:"
                                text2={requestData.parking_instructions}
                            />
                            <AppointmentFrame
                                text1="Additional Instructions:"
                                text2={requestData.additional_instructions}
                            />
                            <AppointmentFrame
                                text1="Order ID:"
                                text2={
                                    requestData.order_id !== null
                                        ? requestData.order_id
                                        : "None"
                                }
                            />

                            {chipState === "no answer" ? (
                                <Box>
                                    <Box
                                        sx={{
                                            paddingTop: "15px",
                                        }}
                                    >
                                        <Divider
                                            sx={{
                                                width: "100%",
                                                bgcolor: "#E5E5E5",
                                                height: "1px",
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            paddingTop: "22px",
                                            paddingBottom: "10px",
                                            paddingLeft: "19px",
                                            width: "390px",
                                            height: "44px",
                                        }}
                                    >
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <AcceptButton
                                                    width="191px"
                                                    setChipState={setChipState}
                                                    uniqueId={uniqueId}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    paddingLeft: "24px",
                                                }}
                                            >
                                                <DenyButton
                                                    width="191px"
                                                    setChipState={setChipState}
                                                    uniqueId={uniqueId}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        paddingTop: "15px",
                                    }}
                                ></Box>
                            )}
                        </Box>
                        {!(chipState === "no answer") && (
                            <Box
                                sx={{
                                    width: "443px",
                                    paddingTop: "17px",
                                    height: "50px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "25px",
                                        color: "#2D2D2D",
                                    }}
                                >
                                    If you have any questions, contact customer
                                    support at admin@nexusupsells.com or
                                    385-200-0937
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

const ProviderRequest = () => {
    const [requestData, setRequestData] = useState(null);
    const [errorLoading, setErrorLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [chipState, setChipState] = useState("no answer");

    const { uniqueId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await djangoEndpointPromise(
                    `get_service_orderoffering_info?hash_value=${uniqueId}`
                );
                if (fetchResultType(response) === "error") {
                    throw new Error(
                        "Link error or something went wrong on the server."
                    );
                }

                const data = response;
                setRequestData(data.data);
                setChipState(
                    !data.data.request_available
                        ? "expired"
                        : data.data.request_status === "requestable" ||
                            data.data.request_status === "requested"
                            ? "no answer"
                            : data.data.request_status
                );
                setIsLoading(false);
            } catch (error) {
                // Handle errors in communicating with your backend server
                console.error("Error with backend:", error);
                setIsLoading(false);
                setErrorLoading(true);
            }
        };

        fetchData(); // Call the async function inside useEffect
    }, [uniqueId]);
    const isMobile = useMediaQuery("(max-width:600px)");
    const isMassage = requestData && requestData.service_type === 1;
    const isPrivateChef = requestData && requestData.service_type === 3;
    return (
        <div>
            {isLoading ? (
                <LoadingIndicator />
            ) : errorLoading ? (
                <div>Unknown link or error...</div>
            ) : isMobile ? (
                isMassage ? (
                    <ProviderRequestMassageMobileUI
                        requestData={requestData}
                        chipState={chipState}
                        setChipState={setChipState}
                        uniqueId={uniqueId}
                    />
                ) : isPrivateChef ? (
                    <ProviderRequestPrivateChefMobileUI
                        requestData={requestData}
                        chipState={chipState}
                        setChipState={setChipState}
                        uniqueId={uniqueId}
                    />
                ) : (
                    <div>Service type not supported on mobile.</div>
                )
            ) : isMassage ? (
                <ProviderRequestMassageDesktopUI
                    requestData={requestData}
                    chipState={chipState}
                    setChipState={setChipState}
                    uniqueId={uniqueId}
                />
            ) : isPrivateChef ? (
                <ProviderRequestPrivateChefDesktopUI
                    requestData={requestData}
                    chipState={chipState}
                    setChipState={setChipState}
                    uniqueId={uniqueId}
                />
            ) : (
                <div>Service type not supported on desktop.</div>
            )}
        </div>
    );
};

export default ProviderRequest;
