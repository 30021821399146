import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Divider,
    Button,
    useMediaQuery,
    Stack,
    Alert,
} from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
    Loading,
    LoadingIndicator,
    AppDotPaginationwBack,
    Title,
    PrivateChefP3Card,
    PrivateChefP3CardMobile,
    HeaderFooter,
    PrivateChefMobileDotPagination,
} from "../components/UI";
import {
    djangoEndpointPromise,
    fetchResultType,
    useCurrentServiceOrder,
    PRIVATE_CHEF_PAGINATION,
    mergeMultipleResults,
    useCurrentHost,
    useCurrentMenu,
    capitalizeFirstLetter,
    toTimeIntervalString,
    MUTED_BUTTON_SELECTED_STRINGS,
    toTimeString,
} from "../util";
import logo from "../assets/ellipse.png";
import CircleIcon from "@mui/icons-material/Circle";
import MacAndCheesePhoto from "../assets/macncheese.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function useGetTimeslots(orderHook) {
    const [timeslots, setTimeslots] = useState({});
    const [order, orderTryLoad, _setOrder] = orderHook;

    const orderId = order.order_id;
    const url = `private-chef-menu/retrieve-selected-menu-timeslots?order_id=${encodeURIComponent(
        orderId
    )}`;
    const tryLoad = useCallback(async () => {
        try {
            if (orderId == null) {
                return;
            }
            const response = await djangoEndpointPromise(url);

            if (fetchResultType(response) === "error") {
                throw new Error(
                    "Server Error: Something went wrong on the server."
                );
            }
            setTimeslots(response.data);
        } catch (error) {
            console.error("Error with backend:", error);
        }
    }, [orderId]);
    useEffect(() => {
        tryLoad();
    }, [tryLoad, orderId]);

    return [timeslots, tryLoad, setTimeslots];
}

const BulletPoint = ({ item }) => {
    return (
        <Stack
            direction="row"
            sx={{
                alignItems: "center",
            }}
        >
            <CircleIcon sx={{ height: "8px", marginRight: "1px" }} />
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "19.36px",
                    textAlign: "left",
                    color: "black",
                }}
            >
                {item}
            </Typography>
        </Stack>
    );
};

const PrivateChefTimesUI = ({
    orderHook,
    hostInfo,
    menuInfo,
    timeslotsInfo,
}) => {
    const [order, tryAgain, setOrder] = orderHook;
    const navigate = useNavigate();
    const [validate, setValidate] = useState(false);

    const date = dayjs.utc(order.time_requested).tz(order.timezone);

    const errorMessages = [];

    const isSelected = timeslotsInfo.timeslots.some((slot) => {
        const datestring = date.toISOString().slice(0, 11);
        const beginServerDateFmt = slot;

        const begin = new dayjs.tz(
            datestring + beginServerDateFmt,
            order.timezone
        );

        const beginISOString = begin.toISOString().split(".")[0] + "Z";

        // Check if the order's requested time matches the begin time
        return order.time_requested === beginISOString;
    });
    // this comparison can be made as-is because both are server format

    const [isValid, setIsValid] = useState(isSelected);

    const Mobile = useMediaQuery("(max-width:600px)");
    const Small = useMediaQuery("(min-width: 601px) and (max-width:900px)");
    const Large = useMediaQuery("(min-width:1400px)");
    const minWidthValue = Mobile ? "0px" : "1050px";

    const TimeButtonWidth = Mobile ? "100%" : "173px";

    const TimeButton = ({ label, isSelected, onClick }) => {
        return (
            <Button
                onClick={onClick}
                variant={isSelected ? "contained" : "outlined"}
                sx={{
                    boxSizing: "border-box",
                    padding: "0px",
                    gap: "8px",
                    width: TimeButtonWidth,
                    height: "40px",
                    border: "1px solid #1E1E1E",
                    borderRadius: "100px",
                    background: isSelected ? "#0F3562" : "#EDEDED",
                    color: isSelected ? "white" : "black",
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
                }}
            >
                {label}
            </Button>
        );
    };

    const tempTimeWidth = Mobile ? "100%" : "362px";
    const tempTimeLeftMargin = Mobile ? "0px" : "35px";

    const TempTimeDisplay = () => {
        const containerStyle = {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            width: tempTimeWidth,
            height: "auto",
            marginTop: "20px",
            marginBottom: "5px",
            marginLeft: tempTimeLeftMargin,
            marginRight: "75px",
        };
        return (
            <div style={containerStyle}>
                {timeslotsInfo.timeslots.map((slot, index) => {
                    const datestring = date.toISOString().slice(0, 11);

                    const dateAtMidnightInTimeZone =
                        dayjs
                            .tz(datestring + "00:00:00", order.timezone)
                            .toISOString()
                            .split(".")[0] + "Z";

                    const beginServerDateFmt = slot;
                    /*const endServerDateFmt = slot[1];*/

                    const begin = new dayjs.tz(
                        datestring + beginServerDateFmt,
                        order.timezone
                    );

                    /* const end = new dayjs.tz(
                        datestring + endServerDateFmt,
                        order.timezone
                    );*/

                    const beginISOString =
                        begin.toISOString().split(".")[0] + "Z";

                        const duration = 90; //set manually for now

                        //const duration = end.diff(begin, "minute");

                    // this comparison can be made as-is because both are server format

                    const formattedTime = toTimeString(begin);
                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TimeButton
                                label={formattedTime}
                                key={index}
                                isSelected={
                                    order.time_requested === beginISOString
                                }
                                onClick={() => {
                                    const selected =
                                        order.time_requested === beginISOString;
                                    setOrder({
                                        ...order,
                                        time_requested: selected
                                            ? dateAtMidnightInTimeZone
                                            : beginISOString,
                                        duration_requested: selected
                                            ? null
                                            : duration,
                                    });
                                    djangoEndpointPromise(
                                        "service-order/current/time_requested",
                                        {
                                            order_id: order.order_id,
                                            time_requested: selected
                                                ? dateAtMidnightInTimeZone
                                                : beginISOString,
                                            duration: selected
                                                ? null
                                                : duration,
                                        }
                                    );
                                    setIsValid(!selected);
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: minWidthValue,
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={!Mobile}>
                    {Mobile && (
                        <Box
                            sx={{
                                bgcolor: "#F2F2F2",
                                flexGrow: 1,
                                width: "100%",
                                height: "100%",
                                overflowY: "auto",
                                paddingBottom: "20px",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="5px"
                                sx={{
                                    alignItems: "center",
                                    width: "100%",
                                }}
                            >
                                <PrivateChefMobileDotPagination
                                    page={2}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                />
                                <Stack
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                        gap: "15px",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                >
                                    <PrivateChefP3CardMobile
                                        imageVariant="fullWidth"
                                        image={menuInfo.menu.menu_image}
                                        title={menuInfo.menu.name}
                                        subtitle={menuInfo.menu.description}
                                        price={menuInfo.basePrice}
                                    />
                                    <Box
                                        sx={{
                                            gap: "10px",
                                            width: "92%",
                                            //height: "319px",
                                            boxShadow:
                                                "0px 2px 4px 0px #00000040",
                                            borderRadius: "12px",
                                            paddingTop: "24px",
                                            paddingLeft: "17px",
                                            background: "white",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "700",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Popular Menu Items
                                        </Typography>
                                        <Divider
                                            sx={{
                                                width: "322px",
                                                height: "0px",
                                                border: "1px solid #C3C3C3",
                                                marginTop: "25px",
                                                marginBottom: "31px",
                                            }}
                                        />
                                        <Stack
                                            direction="column"
                                            spacing="25px"
                                            sx={{ paddingBottom: "30px" }}
                                        >
                                            {menuInfo.popular_dishes.map(
                                                (dishName, index) => (
                                                    <BulletPoint
                                                        key={index}
                                                        item={capitalizeFirstLetter(
                                                            dishName
                                                        )}
                                                    />
                                                )
                                            )}
                                        </Stack>
                                    </Box>

                                    <Stack
                                        direction="column"
                                        sx={{ width: "92%" }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                                color: "#1D1B20",
                                                marginTop: "15px",
                                                //marginLeft: "40px",
                                                //marginBottom: "8px",
                                                height: "auto",
                                            }}
                                        >
                                            Available Times
                                        </Typography>
                                        <TempTimeDisplay />
                                        {validate && !isValid && (
                                            <Alert
                                                variant="filled"
                                                severity="error"
                                            >
                                                {errorMessages.map(
                                                    (message) => (
                                                        <Typography
                                                            key={message}
                                                            variant="body1"
                                                            sx={{
                                                                textAlign:
                                                                    "left",
                                                            }}
                                                        >
                                                            {message}
                                                        </Typography>
                                                    )
                                                )}
                                            </Alert>
                                        )}
                                        <Button
                                            disabled={!isValid}
                                            onClick={async () => {
                                                if (isValid) {
                                                    tryAgain();
                                                    navigate(
                                                        PRIVATE_CHEF_PAGINATION[3]
                                                    );
                                                }
                                            }}
                                            sx={{
                                                height: "44px",
                                                //marginBottom: "30px",
                                                marginTop: "15px",
                                                borderRadius: "100px",
                                                //marginLeft: "35px",
                                                gap: "8px",
                                            }}
                                            fullWidth
                                        >
                                            Next
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                    {!Mobile && (
                        <Box
                            sx={{
                                bgcolor: "#FDFDFD",
                                paddingBottom: "125px",
                                flexGrow: "1",
                                //width: "100%",
                                overflowX: "auto",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "97px",
                                    marginLeft: "100px",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Title title="Select a time" />
                                <AppDotPaginationwBack
                                    page={2}
                                    pagination={PRIVATE_CHEF_PAGINATION}
                                />
                                <Stack
                                    display="flex"
                                    flexDirection={Large ? "row" : "column"}
                                    sx={{
                                        gap: "40px",
                                    }}
                                >
                                    <Stack
                                        direction={Small ? "column" : "row"}
                                        spacing="40px"
                                    >
                                        <PrivateChefP3Card
                                            imageVariant="fullWidth"
                                            image={menuInfo.menu.menu_image}
                                            title={menuInfo.menu.name}
                                            subtitle={menuInfo.menu.description}
                                            price={menuInfo.basePrice}
                                        />
                                        <Box
                                            sx={{
                                                gap: "10px",
                                                width: "377px",
                                                //height: "319px",
                                                boxShadow:
                                                    "0px 2px 4px 0px #00000040",
                                                borderRadius: "12px",
                                                paddingTop: "24px",
                                                paddingLeft: "17px",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    lineHeight: "19.36px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Popular Menu Items
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    width: "322px",
                                                    height: "0px",
                                                    border: "1px solid #C3C3C3",
                                                    marginTop: "25px",
                                                    marginBottom: "31px",
                                                }}
                                            />
                                            <Stack
                                                direction="column"
                                                spacing="25px"
                                                sx={{ paddingBottom: "30px" }}
                                            >
                                                {menuInfo.popular_dishes.map(
                                                    (dishName, index) => (
                                                        <BulletPoint
                                                            key={index}
                                                            item={capitalizeFirstLetter(
                                                                dishName
                                                            )}
                                                        />
                                                    )
                                                )}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                    <Box
                                        sx={{
                                            width: "472px",
                                            height: "346px",
                                            background: "#EDEDED",
                                            borderRadius: "12px",
                                            marginRight: "100px",
                                            paddingBottom: "5px",
                                        }}
                                    >
                                        <Stack direction="column">
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "20px",
                                                    fontWeight: "600",
                                                    lineHeight: "24.2px",
                                                    textAlign: "left",
                                                    color: "#1D1B20",
                                                    marginTop: "37px",
                                                    marginLeft: "40px",
                                                    marginBottom: "8px",
                                                    height: "auto",
                                                }}
                                            >
                                                Available Times
                                            </Typography>
                                            <TempTimeDisplay />
                                            {validate && !isValid && (
                                                <Alert
                                                    variant="filled"
                                                    severity="error"
                                                >
                                                    {errorMessages.map(
                                                        (message) => (
                                                            <Typography
                                                                key={message}
                                                                variant="body1"
                                                                sx={{
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {message}
                                                            </Typography>
                                                        )
                                                    )}
                                                </Alert>
                                            )}
                                            <Button
                                                disabled={!isValid}
                                                onClick={async () => {
                                                    if (isValid) {
                                                        tryAgain();
                                                        navigate(
                                                            PRIVATE_CHEF_PAGINATION[3]
                                                        );
                                                    }
                                                }}
                                                sx={{
                                                    width: "414px",
                                                    height: "44px",
                                                    //marginBottom: "30px",
                                                    marginTop: "25px",
                                                    borderRadius: "100px",
                                                    marginLeft: "35px",
                                                    gap: "8px",
                                                }}
                                                fullWidth
                                            >
                                                Next
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>
                    )}
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook, timeslotsInfo, menu }) => {
    const [host, hostTryLoad, _setHost] = hostHook;

    return (
        <>
            <PrivateChefTimesUI
                orderHook={orderHook}
                hostInfo={host}
                menuInfo={menu}
                timeslotsInfo={timeslotsInfo}
            />
        </>
    );
};

const PrivateChefTimes = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    const [timeslotsInfo, tryTimeslotsLoad, setTimeslots] =
        useGetTimeslots(orderHook);

    const [menu, setMenu] = useCurrentMenu();

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host, timeslotsInfo)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                    tryTimeslotsLoad();
                }}
                renderChildren={() => (
                    <Loaded
                        orderHook={orderHook}
                        hostHook={hostHook}
                        timeslotsInfo={timeslotsInfo}
                        menu={menu}
                    />
                )}
            />
        </>
    );
};

export default PrivateChefTimes;
