import {
    useMediaQuery,
    Box,
    Button,
    Checkbox,
    InputAdornment,
    FormControlLabel,
    Radio,
    Stack,
    TextField,
    Typography,
    Grid,
    Divider,
} from "@mui/material";
import {
    AppDotPagination,
    AppDotPaginationwBack,
    MobileTitle,
    PrivateChefMobileDotPagination,
    Title,
    Loading,
    HeaderFooter,
} from "../components/UI";
import {
    MASSAGE_PAGINATION,
    MASSAGE_TYPE_REQUESTED,
    djangoEndpointPromise,
    mergeMultipleResults,
    useCurrentGuest,
    useCurrentRecipients,
    useCurrentServiceOrder,
    capitalizeFirstLetter,
    useCurrentHost,
} from "../util";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import logo from "../assets/ellipse.png";
import IndivMassagePhoto from "../assets/indivmassage.png";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const MassageGuestRecipientInfoMobileUI = ({
    orderProps,
    hostProps,
    guestProps,
    blurProps,
    recipientsProps,
    navigationProps,
    disclaimerProps,
    errorProps,
    validationProps,
    updateProps,
}) => {
    const { orderHook } = orderProps;

    const { hostInfo } = hostProps;

    const { guestHook } = guestProps;

    const {
        setGuestFirstNameBlurred,
        setGuestLastNameBlurred,
        setGuestPhoneNumberBlurred,
        setGuestEmailBlurred,
        setRecipient1FirstNameBlurred,
        setRecipient2FirstNameBlurred,
        setRecipient1LastNameBlurred,
        setRecipient2LastNameBlurred,
    } = blurProps;

    const {
        recipient1FirstName,
        setRecipient1FirstName,
        recipient1LastName,
        setRecipient1LastName,
        recipient2Index,
        recipientsHook,
        recipientTextFieldsRefs,
        setRef,
    } = recipientsProps;

    const [order, _orderTryAgain, setOrder] = orderHook;
    const [guest, _guestTryAgain, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryAgain, setRecipients] = recipientsHook;

    const { navigate } = navigationProps;

    const { disclaimer1Checked, setDisclaimer1Checked } = disclaimerProps;

    const {
        guestFirstNameError,
        guestLastNameError,
        guestPhoneNumberError,
        guestEmailError,
        recipient1FirstNameError,
        recipient2FirstNameError,
        recipient1LastNameError,
        recipient2LastNameError,
    } = errorProps;

    const { isValid } = validationProps;

    const {
        setRecipientsAndUpdate,
        setGuestAndUpdate,
        createGuestIfNull,
        applyChangeToRecipients,
    } = updateProps;

    const textFieldStyle = {
        marginTop: "0px",
        width: "92%%",
        height: "56px",
        backgroundColor: "#FFFFFF",
        borderRadius: "12px",
        border: "1px solid #CACACA",
        "& .MuiInputLabel-root": {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
            textAlign: "left",
            color: "#9D9D9D",
            paddingTop: "12px",
            paddingLeft: "16px",
        },
        "& .MuiInputBase-input": {
            fontSize: "14px",
            color: "#000000",
            paddingLeft: "4px",
            paddingTop: "7px",
            fontFamily: "Inter",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
        },
    };

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "0px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "20px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "20px",
                                    alignItems: "center",
                                    padding: "0px 11px 0px 11px",
                                }}
                            >
                                <PrivateChefMobileDotPagination
                                    page={3}
                                    pagination={MASSAGE_PAGINATION}
                                />
                                <MobileTitle title="Contact Information" />
                                <Box
                                    sx={{
                                        width: "100%",
                                        gap: "26px",
                                        borderRadius: "20px",
                                        opacity: "0px",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who is booking?
                                        </Typography>
                                        <Stack direction="column" spacing="6px">
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                fullWidth
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="First Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.first_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.first_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestFirstNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        first_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestFirstNameError}
                                                helperText={guestFirstNameError}
                                            />
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Last Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.last_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.last_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestLastNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        last_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestLastNameError}
                                                helperText={guestLastNameError}
                                                fullWidth
                                            />

                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Phone Number"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.phone_number === null
                                                        ? ""
                                                        : guest.phone_number
                                                }
                                                onBlur={(event) => {
                                                    setGuestPhoneNumberBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        phone_number:
                                                            event.target.value,
                                                    });
                                                }}
                                                error={!!guestPhoneNumberError}
                                                helperText={
                                                    guestPhoneNumberError
                                                }
                                                fullWidth
                                            />
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Email"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.email === null
                                                        ? ""
                                                        : guest.email
                                                }
                                                onBlur={(event) => {
                                                    setGuestEmailBlurred(true);
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        email: event.target
                                                            .value,
                                                    });
                                                }}
                                                error={!!guestEmailError}
                                                helperText={guestEmailError}
                                                fullWidth
                                            />
                                        </Stack>
                                    </Stack>

                                    <Divider
                                        sx={{
                                            width: "100%",
                                            bgcolor: "#D3D3D3",
                                            height: "1px",
                                            margin: "10px 0px 13px 0px",
                                        }}
                                    />

                                    <Stack
                                        direction="column"
                                        spacing="10px"
                                        sx={{
                                            marginTop: "5px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who is receiving this service?
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                                color: "black",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            Guest 1
                                        </Typography>

                                        <FormControlLabel
                                            sx={{
                                                marginTop: "-5px",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                            control={
                                                <Checkbox
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon />
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon />
                                                    }
                                                    checked={
                                                        recipientsInfo.include_guest_as_recipient
                                                    }
                                                    onChange={(event) => {
                                                        recipientsInfo.include_guest_as_recipient =
                                                            event.target.checked;
                                                        if (
                                                            !recipientsInfo.include_guest_as_recipient &&
                                                            recipientTextFieldsRefs
                                                                .current
                                                                .length > 0
                                                        ) {
                                                            recipientTextFieldsRefs.current[0].value =
                                                                recipient1FirstName;
                                                            recipientTextFieldsRefs.current[1].value =
                                                                recipient1LastName;
                                                        }
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        ) {
                                                                            recipients[0].first_name =
                                                                                "";
                                                                            recipients[0].last_name =
                                                                                "";
                                                                            setRecipient1FirstName(
                                                                                ""
                                                                            );
                                                                            setRecipient1LastName(
                                                                                ""
                                                                            );
                                                                        }
                                                                    }
                                                                ),
                                                            include_guest_as_recipient:
                                                                event.target
                                                                    .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Guest 1 is same as contact"
                                        />
                                    </Stack>

                                    <Stack
                                        direction="column"
                                        spacing="10px"
                                        sx={{
                                            marginTop: "5px",
                                        }}
                                    >
                                        <TextField
                                            sx={textFieldStyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.last_name === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                            }
                                            label="First Name"
                                            disabled={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1FirstName
                                                      )
                                            }
                                            onChange={(event) => {
                                                setRecipient1FirstName(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipient1FirstNameBlurred(
                                                    true
                                                );
                                                setRecipientsAndUpdate({
                                                    ...recipientsInfo,
                                                    recipients:
                                                        applyChangeToRecipients(
                                                            (recipients) => {
                                                                recipients[0].first_name =
                                                                    capitalizedValue;
                                                            }
                                                        ),
                                                });
                                                event.target.value = capitalizedValue;
                                            }}
                                            inputRef={(el) => setRef(0, el)}
                                            error={!!recipient1FirstNameError}
                                            helperText={
                                                recipient1FirstNameError
                                            }
                                            fullWidth
                                            margin="dense"
                                        />
                                        <TextField
                                            sx={textFieldStyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.last_name === null
                                                    ? ""
                                                    : guest.last_name
                                            }
                                            label="Last Name"
                                            disabled={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guest.last_name
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1LastName
                                                      )
                                            }
                                            onChange={(event) => {
                                                setRecipient1LastName(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipient1LastNameBlurred(
                                                    true
                                                );
                                                setRecipientsAndUpdate({
                                                    ...recipientsInfo,
                                                    recipients:
                                                        applyChangeToRecipients(
                                                            (recipients) => {
                                                                recipients[0].last_name =
                                                                    capitalizedValue;
                                                            }
                                                        ),
                                                });
                                                event.target.value = capitalizedValue;
                                            }}
                                            inputRef={(el) => setRef(1, el)}
                                            error={!!recipient1LastNameError}
                                            helperText={recipient1LastNameError}
                                            fullWidth
                                            margin="dense"
                                        />
                                    </Stack>
                                    {order.massage_recipients === 2 && (
                                        <>
                                            <Typography
                                                sx={{
                                                    marginTop: "15px",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "16.94px",
                                                    textAlign: "left",
                                                    color: "black",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                Guest 2
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing="26px"
                                                sx={{
                                                    marginTop: "0px",
                                                }}
                                            >
                                                <TextField
                                                    sx={textFieldStyle}
                                                    id="standard-size-normal"
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start"></InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    label="First Name"
                                                    defaultValue={
                                                        recipient2Index <
                                                        recipientsInfo
                                                            .recipients.length
                                                            ? capitalizeFirstLetter(
                                                                  recipientsInfo
                                                                      .recipients[
                                                                      recipient2Index
                                                                  ].first_name
                                                              )
                                                            : ""
                                                    }
                                                    onBlur={(event) => {
                                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                        setRecipient2FirstNameBlurred(
                                                            true
                                                        );
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        recipients[
                                                                            recipient2Index
                                                                        ].first_name =
                                                                            capitalizedValue;
                                                                    }
                                                                ),
                                                        });
                                                        event.target.value = capitalizedValue;
                                                    }}
                                                    inputRef={(el) =>
                                                        setRef(2, el)
                                                    }
                                                    error={
                                                        !!recipient2FirstNameError
                                                    }
                                                    helperText={
                                                        recipient2FirstNameError
                                                    }
                                                />
                                                <TextField
                                                    sx={textFieldStyle}
                                                    id="standard-size-normal"
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start"></InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    label="Last Name"
                                                    defaultValue={
                                                        recipient2Index <
                                                        recipientsInfo
                                                            .recipients.length
                                                            ? capitalizeFirstLetter(
                                                                  recipientsInfo
                                                                      .recipients[
                                                                      recipient2Index
                                                                  ].last_name
                                                              )
                                                            : ""
                                                    }
                                                    onBlur={(event) => {
                                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                        setRecipient2LastNameBlurred(
                                                            true
                                                        );
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        recipients[
                                                                            recipient2Index
                                                                        ].last_name =
                                                                            capitalizedValue;
                                                                    }
                                                                ),
                                                        });
                                                      event.target.value = capitalizedValue;
                                                    }}
                                                    inputRef={(el) =>
                                                        setRef(3, el)
                                                    }
                                                    error={
                                                        !!recipient2LastNameError
                                                    }
                                                    helperText={
                                                        recipient2LastNameError
                                                    }
                                                />
                                            </Stack>
                                        </>
                                    )}
                                </Box>

                                <Divider
                                    sx={{
                                        width: "100%",
                                        bgcolor: "#D3D3D3",
                                        height: "1px",
                                        margin: "5px 0px 8px 0px",
                                    }}
                                />
                                <Box
                                    sx={{
                                        width: "100%",
                                        //height: "663px",
                                        // padding: "31px 34px 31px 34px",
                                        gap: "15px",
                                        borderRadius: "12px",
                                        opacity: "0px",
                                        // background: "#EDEDED",
                                        // marginRight: "100px",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Additional instructions
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#717171",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                        >
                                            If your second guest would like to
                                            receive a different massage type or
                                            gender, please specify here.
                                        </Typography>
                                        <TextField
                                            onBlur={(event) => {
                                                djangoEndpointPromise(
                                                    "service-order/current/special_requests",
                                                    {
                                                        order_id:
                                                            order.order_id,
                                                        special_requests:
                                                            event.target.value,
                                                    }
                                                );
                                                setOrder({
                                                    ...order,
                                                    special_requests:
                                                        event.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                order.special_requests === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          order.special_requests
                                                      )
                                            }
                                            label=""
                                            multiline
                                            rows={4}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                    border: "1px solid #79747E",
                                                    height: "140px",
                                                    background: "white",
                                                },
                                            }}
                                            sx={{
                                                boxSizing: "border-box",
                                                width: "100%",
                                            }}
                                        />

                                        <FormControlLabel
                                            checked={disclaimer1Checked}
                                            onChange={(event) => {
                                                setDisclaimer1Checked(
                                                    event.target.checked
                                                );
                                            }}
                                            control={<Radio />}
                                            label="I agree that all recipients are 14 years or older"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                "& .MuiFormControlLabel-label":
                                                    {
                                                        fontFamily: "Inter",
                                                        color: "black",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                        marginLeft: "0px",
                                                        textAlign: "left",
                                                    },
                                            }}
                                        />
                                        <Button
                                            disabled={!isValid}
                                            onClick={() =>
                                                navigate(MASSAGE_PAGINATION[4])
                                            }
                                            sx={{
                                                height: "44px",
                                                width: "100%",
                                                borderRadius: "100px",
                                                gap: "8px",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                letterSpacing:
                                                    "0.10000000149011612px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </Stack>
                                </Box>
                            </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const MassageGuestRecipientInfoDesktopUI = ({
    orderProps,
    hostProps,
    guestProps,
    blurProps,
    recipientsProps,
    navigationProps,
    disclaimerProps,
    errorProps,
    validationProps,
    updateProps,
}) => {
    const { orderHook } = orderProps;

    const { hostInfo } = hostProps;

    const { guestHook } = guestProps;

    const {
        setGuestFirstNameBlurred,
        setGuestLastNameBlurred,
        setGuestPhoneNumberBlurred,
        setGuestEmailBlurred,
        setRecipient1FirstNameBlurred,
        setRecipient2FirstNameBlurred,
        setRecipient1LastNameBlurred,
        setRecipient2LastNameBlurred,
    } = blurProps;

    const {
        recipient1FirstName,
        setRecipient1FirstName,
        recipient1LastName,
        setRecipient1LastName,
        recipient2Index,
        recipientsHook,
        recipientTextFieldsRefs,
        setRef,
    } = recipientsProps;

    const [order, _orderTryAgain, setOrder] = orderHook;
    const [guest, _guestTryAgain, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryAgain, setRecipients] = recipientsHook;

    const { navigate } = navigationProps;

    const { disclaimer1Checked, setDisclaimer1Checked } = disclaimerProps;

    const {
        guestFirstNameError,
        guestLastNameError,
        guestPhoneNumberError,
        guestEmailError,
        recipient1FirstNameError,
        recipient2FirstNameError,
        recipient1LastNameError,
        recipient2LastNameError,
    } = errorProps;

    const { isValid } = validationProps;

    const {
        setRecipientsAndUpdate,
        setGuestAndUpdate,
        createGuestIfNull,
        applyChangeToRecipients,
    } = updateProps;

    const textFieldStyle = {
        marginTop: "0px",
        width: "324px",
        height: "56px",
        background: "white",
        borderRadius: "12px",
        border: "1px solid #CACACA",
        "& .MuiInputLabel-root": {
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "16px",
            textAlign: "left",
            color: "#9D9D9D",
            paddingTop: "12px",
            paddingLeft: "16px",
        },
        "& .MuiInputBase-input": {
            fontSize: "14px",
            color: "#000000",
            paddingLeft: "4px",
            paddingTop: "7px",
            fontFamily: "Inter",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
        },
    };

    const massageImage = () => {
        if (order.massage_type_requested === "swedish") {
            return MASSAGE_TYPE_REQUESTED.images[0];
        } else if (order.massage_type_requested === "deep tissue") {
            return MASSAGE_TYPE_REQUESTED.images[1];
        } else if (order.massage_type_requested === "hot stone") {
            return MASSAGE_TYPE_REQUESTED.images[2];
        } else {
            return null;
        }
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: "1512px",
                width: "100%",
                scrollBehavior: "smooth",
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                <Box
                    sx={{
                        bgcolor: "#FFFFFF",
                        paddingBottom: "125px",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing="15.96px"
                        sx={{
                            marginTop: "97px",
                            marginLeft: "100px",
                            // marginRight: "100px",
                            alignItems: "flex-start",
                        }}
                    >
                        <Title title="Contact Information" />
                        <AppDotPaginationwBack
                            page={3}
                            pagination={MASSAGE_PAGINATION}
                        />
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                width: "1428px",
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={7}>
                                <Box
                                    sx={{
                                        width: "798px",
                                        padding: "43px 50px 43px 50px",
                                        gap: "26px",
                                        borderRadius: "20px",
                                        opacity: "0px",
                                        background: "#EDEDED",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who is booking?
                                        </Typography>
                                        <Stack direction="row" spacing="26px">
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                fullWidth
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="First Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.first_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.first_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestFirstNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        first_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestFirstNameError}
                                                helperText={guestFirstNameError}
                                            />
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Last Name"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.last_name === null
                                                        ? ""
                                                        : capitalizeFirstLetter(
                                                              guest.last_name
                                                          )
                                                }
                                                onBlur={(event) => {
                                                    const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                    setGuestLastNameBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        last_name:
                                                            capitalizedValue,
                                                    });
                                                    event.target.value = capitalizedValue;
                                                }}
                                                error={!!guestLastNameError}
                                                helperText={guestLastNameError}
                                                fullWidth
                                            />
                                        </Stack>
                                        <Stack
                                            direction="row"
                                            spacing="26px"
                                            sx={{
                                                marginTop: "-5px",
                                            }}
                                        >
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Phone Number"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.phone_number === null
                                                        ? ""
                                                        : guest.phone_number
                                                }
                                                onBlur={(event) => {
                                                    setGuestPhoneNumberBlurred(
                                                        true
                                                    );
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        phone_number:
                                                            event.target.value,
                                                    });
                                                }}
                                                error={!!guestPhoneNumberError}
                                                helperText={
                                                    guestPhoneNumberError
                                                }
                                                fullWidth
                                            />
                                            <TextField
                                                sx={textFieldStyle}
                                                id="standard-size-normal"
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                                label="Email"
                                                defaultValue={
                                                    guest === null ||
                                                    guest.email === null
                                                        ? ""
                                                        : guest.email
                                                }
                                                onBlur={(event) => {
                                                    setGuestEmailBlurred(true);
                                                    setGuestAndUpdate({
                                                        ...createGuestIfNull(
                                                            guest
                                                        ),
                                                        email: event.target
                                                            .value,
                                                    });
                                                }}
                                                error={!!guestEmailError}
                                                helperText={guestEmailError}
                                                fullWidth
                                            />
                                        </Stack>
                                    </Stack>

                                    <Stack
                                        direction="column"
                                        spacing="10px"
                                        sx={{
                                            marginTop: "5px",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Who is receiving this service?
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                                color: "black",
                                                marginBottom: "0px",
                                            }}
                                        >
                                            Guest 1
                                        </Typography>

                                        <FormControlLabel
                                            sx={{
                                                marginTop: "-5px",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                            control={
                                                <Checkbox
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon />
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon />
                                                    }
                                                    checked={
                                                        recipientsInfo.include_guest_as_recipient
                                                    }
                                                    onChange={(event) => {
                                                        recipientsInfo.include_guest_as_recipient =
                                                            event.target.checked;
                                                        if (
                                                            !recipientsInfo.include_guest_as_recipient &&
                                                            recipientTextFieldsRefs
                                                                .current
                                                                .length > 0
                                                        ) {
                                                            recipientTextFieldsRefs.current[0].value =
                                                                recipient1FirstName;
                                                            recipientTextFieldsRefs.current[1].value =
                                                                recipient1LastName;
                                                        }
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        if (
                                                                            event
                                                                                .target
                                                                                .checked
                                                                        ) {
                                                                            recipients[0].first_name =
                                                                                "";
                                                                            recipients[0].last_name =
                                                                                "";
                                                                            setRecipient1FirstName(
                                                                                ""
                                                                            );
                                                                            setRecipient1LastName(
                                                                                ""
                                                                            );
                                                                        }
                                                                    }
                                                                ),
                                                            include_guest_as_recipient:
                                                                event.target
                                                                    .checked,
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Guest 1 is same as contact"
                                        />
                                    </Stack>

                                    <Stack
                                        direction="row"
                                        spacing="26px"
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "-5px",
                                        }}
                                    >
                                        <TextField
                                            sx={textFieldStyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.last_name === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                            }
                                            label="First Name"
                                            disabled={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guest.first_name
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1FirstName
                                                      )
                                            }
                                            onChange={(event) => {
                                                setRecipient1FirstName(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipient1FirstNameBlurred(
                                                    true
                                                );
                                                setRecipientsAndUpdate({
                                                    ...recipientsInfo,
                                                    recipients:
                                                        applyChangeToRecipients(
                                                            (recipients) => {
                                                                recipients[0].first_name =
                                                                    capitalizedValue;
                                                            }
                                                        ),
                                                });
                                                event.target.value = capitalizedValue;
                                            }}
                                            inputRef={(el) => setRef(0, el)}
                                            error={!!recipient1FirstNameError}
                                            helperText={
                                                recipient1FirstNameError
                                            }
                                            fullWidth
                                            margin="dense"
                                        />
                                        <TextField
                                            sx={textFieldStyle}
                                            id="standard-size-normal"
                                            variant="standard"
                                            InputProps={{
                                                disableUnderline: true,
                                                startAdornment: (
                                                    <InputAdornment position="start"></InputAdornment>
                                                ),
                                            }}
                                            defaultValue={
                                                guest === null ||
                                                guest.last_name === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          guest.last_name
                                                      )
                                            }
                                            label="Last Name"
                                            disabled={
                                                recipientsInfo.include_guest_as_recipient
                                            }
                                            value={
                                                recipientsInfo.include_guest_as_recipient
                                                    ? capitalizeFirstLetter(
                                                          guest.last_name
                                                      )
                                                    : capitalizeFirstLetter(
                                                          recipient1LastName
                                                      )
                                            }
                                            onChange={(event) => {
                                                setRecipient1LastName(
                                                    event.target.value
                                                );
                                            }}
                                            onBlur={(event) => {
                                                const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                setRecipient1LastNameBlurred(
                                                    true
                                                );
                                                setRecipientsAndUpdate({
                                                    ...recipientsInfo,
                                                    recipients:
                                                        applyChangeToRecipients(
                                                            (recipients) => {
                                                                recipients[0].last_name =
                                                                    capitalizedValue;
                                                            }
                                                        ),
                                                });
                                                event.target.value = capitalizedValue;
                                            }}
                                            inputRef={(el) => setRef(1, el)}
                                            error={!!recipient1LastNameError}
                                            helperText={recipient1LastNameError}
                                            fullWidth
                                            margin="dense"
                                        />
                                    </Stack>
                                    {order.massage_recipients === 2 && (
                                        <Stack direction="column" spacing="0px">
                                            <Typography
                                                sx={{
                                                    marginTop: "15px",
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                    lineHeight: "16.94px",
                                                    textAlign: "left",
                                                    color: "black",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                Guest 2
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing="26px"
                                                sx={{
                                                    marginTop: "0px",
                                                }}
                                            >
                                                <TextField
                                                    sx={textFieldStyle}
                                                    id="standard-size-normal"
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start"></InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    label="First Name"
                                                    defaultValue={
                                                        recipient2Index <
                                                        recipientsInfo
                                                            .recipients.length
                                                            ? capitalizeFirstLetter(
                                                                  recipientsInfo
                                                                      .recipients[
                                                                      recipient2Index
                                                                  ].first_name
                                                              )
                                                            : ""
                                                    }
                                                    onBlur={(event) => {
                                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                        setRecipient2FirstNameBlurred(
                                                            true
                                                        );
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        recipients[
                                                                            recipient2Index
                                                                        ].first_name =
                                                                            capitalizedValue;
                                                                    }
                                                                ),
                                                        });
                                                        event.target.value = capitalizedValue;
                                                    }}
                                                    inputRef={(el) =>
                                                        setRef(2, el)
                                                    }
                                                    error={
                                                        !!recipient2FirstNameError
                                                    }
                                                    helperText={
                                                        recipient2FirstNameError
                                                    }
                                                />
                                                <TextField
                                                    sx={textFieldStyle}
                                                    id="standard-size-normal"
                                                    variant="standard"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        startAdornment: (
                                                            <InputAdornment position="start"></InputAdornment>
                                                        ),
                                                    }}
                                                    fullWidth
                                                    label="Last Name"
                                                    defaultValue={
                                                        recipient2Index <
                                                        recipientsInfo
                                                            .recipients.length
                                                            ? capitalizeFirstLetter(
                                                                  recipientsInfo
                                                                      .recipients[
                                                                      recipient2Index
                                                                  ].last_name
                                                              )
                                                            : ""
                                                    }
                                                    onBlur={(event) => {
                                                        const capitalizedValue = capitalizeFirstLetter(event.target.value);
                                                        setRecipient2LastNameBlurred(
                                                            true
                                                        );
                                                        setRecipientsAndUpdate({
                                                            ...recipientsInfo,
                                                            recipients:
                                                                applyChangeToRecipients(
                                                                    (
                                                                        recipients
                                                                    ) => {
                                                                        recipients[
                                                                            recipient2Index
                                                                        ].last_name =
                                                                            capitalizedValue;
                                                                    }
                                                                ),
                                                        });
                                                        event.target.value = capitalizedValue;
                                                    }}
                                                    inputRef={(el) =>
                                                        setRef(3, el)
                                                    }
                                                    error={
                                                        !!recipient2LastNameError
                                                    }
                                                    helperText={
                                                        recipient2LastNameError
                                                    }
                                                />
                                            </Stack>
                                        </Stack>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <Box
                                    sx={{
                                        width: "472px",
                                        //height: "663px",
                                        padding: "31px 34px 31px 34px",
                                        gap: "15px",
                                        borderRadius: "12px",
                                        opacity: "0px",
                                        background: "#EDEDED",
                                        marginRight: "100px",
                                    }}
                                >
                                    <Stack direction="column" spacing="15px">
                                        <img
                                            src={massageImage()}
                                            alt="Image of Massage"
                                            style={{
                                                width: "100%",
                                                height: "265px",
                                                borderRadius: "12px",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "19.36px",
                                                textAlign: "left",
                                            }}
                                        >
                                            Additional instructions
                                        </Typography>
                                        <Typography
                                            sx={{
                                                color: "#717171",
                                                fontFamily: "Inter",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16.94px",
                                                textAlign: "left",
                                            }}
                                        >
                                            If your second guest would like to
                                            receive a different massage type or
                                            gender, please specify here
                                        </Typography>
                                        <TextField
                                            onBlur={(event) => {
                                                djangoEndpointPromise(
                                                    "service-order/current/special_requests",
                                                    {
                                                        order_id:
                                                            order.order_id,
                                                        special_requests:
                                                            event.target.value,
                                                    }
                                                );
                                                setOrder({
                                                    ...order,
                                                    special_requests:
                                                        event.target.value,
                                                });
                                            }}
                                            defaultValue={
                                                order.special_requests === null
                                                    ? ""
                                                    : capitalizeFirstLetter(
                                                          order.special_requests
                                                      )
                                            }
                                            label=""
                                            multiline
                                            rows={4}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                style: {
                                                    borderRadius: "12px",
                                                    border: "1px solid #79747E",
                                                    height: "140px",
                                                    background: "white",
                                                },
                                            }}
                                            sx={{
                                                boxSizing: "border-box",
                                                width: "100%",
                                            }}
                                        />
                                        <FormControlLabel
                                            checked={disclaimer1Checked}
                                            onChange={(event) => {
                                                setDisclaimer1Checked(
                                                    event.target.checked
                                                );
                                            }}
                                            control={<Radio />}
                                            label="I agree that all recipients are 14 years or older"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                "& .MuiFormControlLabel-label":
                                                    {
                                                        fontFamily: "Inter",
                                                        color: "black",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                    },
                                            }}
                                        />
                                        <Button
                                            disabled={!isValid}
                                            onClick={() =>
                                                navigate(MASSAGE_PAGINATION[4])
                                            }
                                            sx={{
                                                height: "44px",
                                                width: "100%",
                                                borderRadius: "100px",
                                                gap: "8px",
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                letterSpacing:
                                                    "0.10000000149011612px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Next
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </HeaderFooter>
        </Box>
    );
};

const Loaded = ({ orderHook, guestHook, recipientsHook, hostInfo }) => {
    const [order, _orderTryAgain, setOrder] = orderHook;
    const [guest, _guestTryAgain, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryAgain, setRecipients] = recipientsHook;
    const navigate = useNavigate();
    const [guestFirstNameBlurred, setGuestFirstNameBlurred] = useState(false);
    const [guestLastNameBlurred, setGuestLastNameBlurred] = useState(false);
    const [guestPhoneNumberBlurred, setGuestPhoneNumberBlurred] =
        useState(false);
    const [guestEmailBlurred, setGuestEmailBlurred] = useState(false);
    // TODO: persist the disclaimer checkbox in global state
    const [disclaimer1Checked, setDisclaimer1Checked] = useState(false);
    // Recipients 1 and 2 mean the recipients corresponding to the 1st and 2nd recipient slots in the form.
    const [recipient1FirstNameBlurred, setRecipient1FirstNameBlurred] =
        useState(false);
    const [recipient2FirstNameBlurred, setRecipient2FirstNameBlurred] =
        useState(false);
    const [recipient1LastNameBlurred, setRecipient1LastNameBlurred] =
        useState(false);
    const [recipient2LastNameBlurred, setRecipient2LastNameBlurred] =
        useState(false);
    // Recipient 1 text fields need UI state because of the include_guest_as_recipient checkbox.
    const [recipient1FirstName, setRecipient1FirstName] = useState(
        recipientsInfo.include_guest_as_recipient
            ? ""
            : recipientsInfo.recipients.length < 1
            ? ""
            : recipientsInfo.recipients[0].first_name
    );
    const [recipient1LastName, setRecipient1LastName] = useState(
        recipientsInfo.include_guest_as_recipient
            ? ""
            : recipientsInfo.recipients.length < 1
            ? ""
            : recipientsInfo.recipients[0].last_name
    );

    const recipientTextFieldsRefs = useRef([]);

    // Function to handle dynamic ref assignment
    const setRef = (index, ref) => {
        recipientTextFieldsRefs.current[index] = ref;
    };

    // If include_guest_as_recipient, recipient 2 corresponds to index 0, otherwise index 1.
    const recipient2Index = recipientsInfo.include_guest_as_recipient ? 0 : 1;
    let guestFirstNameError = null;
    let guestLastNameError = null;
    let guestPhoneNumberError = null;
    let guestEmailError = null;
    let recipient1FirstNameError = null;
    let recipient2FirstNameError = null;
    let recipient1LastNameError = null;
    let recipient2LastNameError = null;

    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    let isValid = true;

    if (!disclaimer1Checked) {
        isValid = false;
    }
    // The Guest is only created when the first blur occurs.
    if (guest === null) {
        isValid = false;
    } else {
        if (guest.first_name === "") {
            isValid = false;
            if (guestFirstNameBlurred) {
                guestFirstNameError = "Required";
            }
        }
        if (guest.last_name === "") {
            isValid = false;
            if (guestLastNameBlurred) {
                guestLastNameError = "Required";
            }
        }
        if (guest.phone_number === "") {
            isValid = false;
            if (guestPhoneNumberBlurred) {
                guestPhoneNumberError = "Required";
            }
        }
        if (guest.email === "") {
            isValid = false;
            if (guestEmailBlurred) {
                guestEmailError = "Required";
            }
        } else if (isValidEmail(guest.email) === false) {
            isValid = false;
            guestEmailError = "Invalid Email Address";
        }
    }
    const validateRecipients = () => {
        if (recipientsInfo.include_guest_as_recipient) {
            if (0 < recipientsInfo.recipients.length) {
                const { first_name, last_name } = recipientsInfo.recipients[0];
                if (first_name !== "" || last_name !== "") {
                    if (first_name === "") {
                        isValid = false;
                        if (recipient2FirstNameBlurred) {
                            recipient2FirstNameError = "Required";
                        }
                    }
                    if (last_name === "") {
                        isValid = false;
                        if (recipient2LastNameBlurred) {
                            recipient2LastNameError = "Required";
                        }
                    }
                }
            }
        } else {
            if (0 < recipientsInfo.recipients.length) {
                const { first_name: first_name_1, last_name: last_name_1 } =
                    recipientsInfo.recipients[0];
                if (first_name_1 !== "" || last_name_1 !== "") {
                    if (first_name_1 === "") {
                        isValid = false;
                        if (recipient1FirstNameBlurred) {
                            recipient1FirstNameError = "Required";
                        }
                    }
                    if (last_name_1 === "") {
                        isValid = false;
                        if (recipient1LastNameBlurred) {
                            recipient1LastNameError = "Required";
                        }
                    }
                }
            }
            if (1 < recipientsInfo.recipients.length) {
                const { first_name: first_name_2, last_name: last_name_2 } =
                    recipientsInfo.recipients[1];
                if (first_name_2 !== "" || last_name_2 !== "") {
                    if (first_name_2 === "") {
                        isValid = false;
                        if (recipient2FirstNameBlurred) {
                            recipient2FirstNameError = "Required";
                        }
                    }
                    if (last_name_2 === "") {
                        isValid = false;
                        if (recipient2LastNameBlurred) {
                            recipient2LastNameError = "Required";
                        }
                    }
                }
            }
        }
    };
    validateRecipients();

    const num_recipients = recipientsInfo.include_guest_as_recipient
        ? 1 + recipientsInfo.recipients.length
        : recipientsInfo.recipients.length;
    if (num_recipients !== order.massage_recipients) {
        isValid = false;
    }

    if (
        recipientsInfo.recipients.length > 0 &&
        recipientsInfo.recipients[0].first_name === ""
    ) {
        isValid = false;
    }

    function setRecipientsAndUpdate(recipientsInfo) {
        setRecipients(recipientsInfo);
        djangoEndpointPromise("input_recipient_information", {
            order_id: order.order_id,
            recipients: recipientsInfo.recipients,
            include_guest_as_recipient:
                recipientsInfo.include_guest_as_recipient,
        });
    }
    function setGuestAndUpdate(guest) {
        setGuest(guest);
        console.log("Updated Guest:", guest);
        djangoEndpointPromise("input_guest_information", {
            order_id: order.order_id,
            first_name: guest.first_name,
            last_name: guest.last_name,
            phone_number: guest.phone_number,
            email: guest.email,
        });
    }
    function createGuestIfNull(guest) {
        if (guest === null) {
            return {
                first_name: "",
                last_name: "",
                phone_number: "",
                email: "",
            };
        }
        return guest;
    }
    function applyChangeToRecipients() {
        const recipientsCopy = [
            {
                first_name: "",
                last_name: "",
            },
            {
                first_name: "",
                last_name: "",
            },
        ];
        for (let i = 0; i < recipientTextFieldsRefs.current.length; i += 2) {
            const firstName = recipientTextFieldsRefs.current[i].value;
            const lastName = recipientTextFieldsRefs.current[i + 1].value;

            recipientsCopy[i] = {
                first_name: firstName,
                last_name: lastName,
            };
        }

        if (recipientsInfo.include_guest_as_recipient) {
            recipientsCopy[0] = {
                first_name: "",
                last_name: "",
            };
        }

        const x = recipientsCopy.filter(
            (recipient) =>
                recipient.first_name !== "" || recipient.last_name !== ""
        );

        return x;
    }

    const orderProps = {
        orderHook,
    };

    const hostProps = {
        hostInfo,
    };

    const guestProps = {
        guestHook,
    };

    const blurProps = {
        setGuestFirstNameBlurred,
        setGuestLastNameBlurred,
        setGuestPhoneNumberBlurred,
        setGuestEmailBlurred,
        setRecipient1FirstNameBlurred,
        setRecipient2FirstNameBlurred,
        setRecipient1LastNameBlurred,
        setRecipient2LastNameBlurred,
    };

    const recipientsProps = {
        recipient1FirstName,
        setRecipient1FirstName,
        recipient1LastName,
        setRecipient1LastName,
        recipient2Index,
        recipientsHook,
        recipientTextFieldsRefs,
        setRef,
    };

    const navigationProps = {
        navigate,
    };

    const disclaimerProps = {
        disclaimer1Checked,
        setDisclaimer1Checked,
    };

    const errorProps = {
        guestFirstNameError,
        guestLastNameError,
        guestPhoneNumberError,
        guestEmailError,
        recipient1FirstNameError,
        recipient2FirstNameError,
        recipient1LastNameError,
        recipient2LastNameError,
    };

    const validationProps = {
        isValid,
    };

    const updateProps = {
        setRecipientsAndUpdate,
        setGuestAndUpdate,
        createGuestIfNull,
        applyChangeToRecipients,
    };

    const combinedProps = {
        orderProps,
        hostProps,
        guestProps,
        blurProps,
        recipientsProps,
        navigationProps,
        disclaimerProps,
        errorProps,
        validationProps,
        updateProps,
    };

    const Mobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {Mobile ? (
                <MassageGuestRecipientInfoMobileUI {...combinedProps} />
            ) : (
                <MassageGuestRecipientInfoDesktopUI {...combinedProps} />
            )}
        </>
    );
};

const MassageGuestRecipientInfoPage = () => {
    const orderHook = useCurrentServiceOrder();
    const guestHook = useCurrentGuest();
    const recipientsHook = useCurrentRecipients();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [guest, guestTryLoad, _setGuest] = guestHook;
    const [recipients, recipientsTryLoad, _setRecipients] = recipientsHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);
    // Since guestTryLoad is useCallback() with a dependency on what is essentially order?.order_id,
    // this function will reload when the ID changes.
    useEffect(() => {
        guestTryLoad();
    }, [guestTryLoad]);
    // The same applies for recipientsTryLoad.
    useEffect(() => {
        recipientsTryLoad();
    }, [recipientsTryLoad]);
    return (
        <Loading
            fetchResult={mergeMultipleResults(order, guest, recipients, host)}
            onTryAgain={orderTryLoad}
            renderChildren={() => (
                <Loaded
                    orderHook={orderHook}
                    guestHook={guestHook}
                    recipientsHook={recipientsHook}
                    hostInfo={host}
                />
            )}
        />
    );
};

export default MassageGuestRecipientInfoPage;
