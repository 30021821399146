import React, { useState } from "react";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Divider,
    Button,
    Grid,
    useMediaQuery,
    useTheme,
    Pagination,
    PaginationItem,
    Stack,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    IconButton,
    Container,
    CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { doesSectionFormatHaveLeadingZeros } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import {
    fetchResultType,
    toTimeIntervalString,
    djangoEndpointPromise,
} from "../util";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const COLUMN_FLEX_SPACING = 1.5;
export const COLUMN_FLEX_SPACING_SMALL = 0.75;
export const ROW_FLEX_SPACING = 40;

/**
 * The appbar includes an empty toolbar so the appbar does not cover content.
 *
 * @returns An appbar.
 */
export const AppAppBar = () => {
    // https://stackoverflow.com/questions/48508449/content-beneath-fixed-appbar
    return (
        <>
            <AppBar>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ fiexGrow: 1 }}
                    >
                        Nexus
                    </Typography>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    );
};

export const Header = ({ hostInfo }) => {
    return (
        <>
            <AppBar
                position="static"
                sx={{
                    borderRadius: 0,
                    bgcolor: "#1E1E1E",
                    height: "104px",
                    justifyContent: "center",
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            // width: "14.2%",
                            height: "50px",
                            alignItems: "center",
                            minWidth: "214px",
                        }}
                        display="flex"
                    >
                        <Box
                            sx={{
                                //width: "23%",
                                height: "auto",
                                minWidth: "50px",
                                maxWidth: "50px",
                            }}
                        >
                            <img
                                src={hostInfo.logo_url}
                                alt="Logo"
                                style={{ width: "100%" }}
                            />
                        </Box>

                        <Box
                            sx={{
                                // width: "70%",
                                height: "24px",
                                paddingLeft: "7%",
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                }}
                                noWrap
                            >
                                {hostInfo.company_name === null
                                    ? hostInfo.host_name
                                    : hostInfo.company_name}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "17%",
                            height: "36px",
                            minWidth: "270px",
                            alignItems: "center",
                        }}
                        display="flex"
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: "800",
                                fontSize: "30px",
                                lineHeight: "36px",
                            }}
                        >
                            {hostInfo.listing_name}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            // width: "17%",
                            height: "19px",
                            minWidth: "262px",
                            alignItems: "center",
                        }}
                        display="flex"
                    >
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: "500",
                                fontSize: "16px",
                                lineHeight: "19px",
                            }}
                        >
                            {hostInfo.listing_address}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider
                sx={{
                    width: "100%",
                    bgcolor: "#43505E",
                    height: "1px",
                }}
            />
        </>
    );
};
export const HeaderEmpty = () => {
    return (
        <>
            <AppBar
                position="static"
                sx={{
                    borderRadius: 0,
                    bgcolor: "#1E1E1E",
                    height: "104px",
                    justifyContent: "center",
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            // width: "70%",
                            height: "24px",
                            paddingLeft: "44%",
                        }}
                    >
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: "700",
                                fontSize: "20px",
                                lineHeight: "24px",
                            }}
                            noWrap
                        >
                            Expired Order
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider
                sx={{
                    width: "100%",
                    bgcolor: "#43505E",
                    height: "1px",
                }}
            />
        </>
    );
};

export const MobileHeader = ({ hostInfo }) => {
    return (
        <>
            <AppBar
                position="static"
                sx={{
                    borderRadius: 0,
                    bgcolor: "#1E1E1E",
                    height: "77px",
                    justifyContent: "center",
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            // width: "14.2%",
                            height: "50px",
                            alignItems: "center",
                            minWidth: "214px",
                        }}
                        display="flex"
                    >
                        <Box
                            sx={{
                                //width: "23%",
                                height: "auto",
                                minWidth: "50px",
                                maxWidth: "50px",
                            }}
                        >
                            <img
                                src={hostInfo.logo_url}
                                alt="Logo"
                                style={{ width: "100%" }}
                            />
                        </Box>

                        <Box
                            sx={{
                                // width: "70%",
                                height: "24px",
                                paddingLeft: "7%",
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                }}
                                noWrap
                            >
                                {hostInfo.company_name === null
                                    ? hostInfo.host_name
                                    : hostInfo.company_name}
                            </Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider
                sx={{
                    width: "100%",
                    bgcolor: "#43505E",
                    height: "1px",
                }}
            />
        </>
    );
};

export const MobileHeaderEmpty = () => {
    return (
        <>
            <AppBar
                position="static"
                sx={{
                    borderRadius: 0,
                    bgcolor: "#1E1E1E",
                    height: "77px",
                    justifyContent: "center",
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            // width: "70%",
                            height: "24px",
                            paddingLeft: "32%",
                        }}
                    >
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: "700",
                                fontSize: "20px",
                                lineHeight: "24px",
                            }}
                            noWrap
                        >
                            Expired Order
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider
                sx={{
                    width: "100%",
                    bgcolor: "#43505E",
                    height: "1px",
                }}
            />
        </>
    );
};

export const Footer = ({ hostInfo }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#1E1E1E",
                    display: "flex",
                    alignItems: "center",
                    height: "73px",
                }}
            >
                <Box
                    sx={{
                        marginLeft: "46px",
                        display: "flex",
                        alignItems: "stretch",
                        width: "100%",
                    }}
                >
                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    >
                        Questions?
                    </Typography>
                    <PhoneIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        385-200-0937
                    </Typography>

                    <MailIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />

                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        support@nexusupsells.com
                    </Typography>
                    <Typography
                        sx={{
                            marginLeft: "auto",
                            marginRight: "35px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            color: "#CACACA",
                        }}
                    >
                        {hostInfo.company_name === null
                            ? hostInfo.host_name
                            : hostInfo.company_name}{" "}
                        partners with Nexus Upsells to provide these services.
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export const FooterEmpty = () => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#1E1E1E",
                    display: "flex",
                    alignItems: "center",
                    height: "73px",
                }}
            >
                <Box
                    sx={{
                        marginLeft: "46px",
                        display: "flex",
                        alignItems: "stretch",
                        width: "100%",
                    }}
                >
                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    >
                        Questions?
                    </Typography>
                    <PhoneIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        385-200-0937
                    </Typography>

                    <MailIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />

                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        support@nexusupsells.com
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export const MobileFooter = ({ hostInfo }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#1E1E1E",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "17px",
                    paddingTop: "10px",
                    paddingBottom: "21px",
                }}
            >
                <Typography
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "#CACACA",
                        marginRight: "5px",
                    }}
                >
                    Questions?
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <PhoneIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />
                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        385-200-0937
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <MailIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />

                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        support@nexusupsells.com
                    </Typography>
                </Box>

                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        color: "#CACACA",
                    }}
                >
                    {hostInfo.company_name === null
                        ? hostInfo.host_name
                        : hostInfo.company_name}{" "}
                    partners with Nexus Upsells to provide these services.
                </Typography>
            </Box>
        </>
    );
};

export const MobileFooterEmpty = () => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#1E1E1E",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "17px",
                    paddingTop: "10px",
                    paddingBottom: "21px",
                }}
            >
                <Typography
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        color: "#CACACA",
                        marginRight: "5px",
                    }}
                >
                    Questions?
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <PhoneIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />
                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        385-200-0937
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "stretch",
                    }}
                >
                    <MailIcon
                        sx={{
                            color: "#CACACA",
                            marginRight: "5px",
                        }}
                    />

                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            marginRight: "5px",
                            color: "#CACACA",
                        }}
                    >
                        support@nexusupsells.com
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export const HeaderFooter = ({ hostInfo, isDesktop, children }) => (
    <>
        {isDesktop ? (
            <>
                <Header hostInfo={hostInfo} />
                {children}
                <Footer hostInfo={hostInfo} />
            </>
        ) : (
            <>
                <MobileHeader hostInfo={hostInfo} />
                {children}
                <MobileFooter hostInfo={hostInfo} />
            </>
        )}
    </>
);

export const HeaderFooterEmpty = ({ isDesktop, children }) => (
    <>
        {isDesktop ? (
            <>
                <HeaderEmpty />
                {children}
                <FooterEmpty />
            </>
        ) : (
            <>
                <MobileHeaderEmpty />
                {children}
                <MobileFooterEmpty />
            </>
        )}
    </>
);

export const Ellipse = ({ text, width }) => {
    return (
        <Box
            sx={{
                width: width, // Width of the ellipse
                aspectRatio: "1 / 1",
                flexShrink: 0, // Prevent shrinking
                flexGrow: 0, // Prevent growing
                backgroundColor: "#FFFFFF", // Background color
                borderRadius: "50%", // Ellipse shape
                border: `1px solid #CACACA`,
                display: "flex", // For centering content inside
                alignItems: "center", // Center content vertically
                justifyContent: "center", // Center content horizontally
                color: "black", // Text color
                textAlign: "center", // Center text alignment
            }}
        >
            {text}
        </Box>
    );
};

/**
 * Produces a "dot" good for the Pagination widget. These dots are not clickable.
 */
export const AppDotPaginationItem = ({ selected }) => {
    const theme = useTheme();
    const primaryColor = "#000000";
    const secondaryColor = "#C0C0C0";
    return (
        <Box
            sx={{
                width: 12,
                height: 12,
                margin: 0.8 /* 1 * 8px */,
                borderRadius: "50%",
                backgroundColor: selected ? primaryColor : secondaryColor,
            }}
        />
    );
};

/**
 * Note: Pages are 0-indexed.
 */
export const AppDotPagination = ({ pagination, page, onBack }) => {
    const navigate = useNavigate();
    const paginationItems = [];
    for (let i = 0; i < pagination.length; ++i) {
        const selected = page === i;
        paginationItems.push(
            <AppDotPaginationItem selected={selected} key={i} />
        );
    }

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
        >
            <IconButton
                aria-label="back"
                onClick={onBack ?? (() => navigate(pagination[page - 1]))}
            >
                <ChevronLeftIcon
                    sx={{
                        width: "35px",
                        height: "35px",
                    }}
                />
            </IconButton>
            <Box display="flex">{paginationItems}</Box>
            <IconButton
                aria-label="back"
                sx={{
                    visibility: "hidden",
                }}
            >
                <ChevronLeftIcon
                    sx={{
                        width: "35px",
                        height: "35px",
                    }}
                />
            </IconButton>
        </Box>
    );
};

/**
 * Produces a "dot" good for the Pagination widget. These dots are not clickable.
 */
export const PrivateChefMobileDotPaginationItem = ({ selected }) => {
    const primaryColor = "#000000";
    const secondaryColor = "#C0C0C0";
    return (
        <Box
            sx={{
                width: 11.56,
                height: 11.56,
                margin: 0.8 /* 1 * 8px */,
                borderRadius: "50%",
                backgroundColor: selected ? primaryColor : secondaryColor,
            }}
        />
    );
};

/**
 * Note: Pages are 0-indexed.
 */
export const PrivateChefMobileDotPagination = ({
    pagination,
    page,
    onBack,
}) => {
    const navigate = useNavigate();
    const paginationItems = [];
    for (let i = 0; i < pagination.length; ++i) {
        const selected = page == i;
        paginationItems.push(
            <PrivateChefMobileDotPaginationItem selected={selected} key={i} />
        );
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing="50px"
            sx={{ marginLeft: "-15px" }}
        >
            <IconButton
                aria-label="back"
                onClick={onBack ?? (() => navigate(pagination[page - 1]))}
            >
                <ChevronLeftIcon
                    sx={{
                        width: "50px",
                        height: "50px",
                    }}
                />
            </IconButton>
            <Box display="flex">{paginationItems}</Box>
            <IconButton
                aria-label="back"
                sx={{
                    visibility: "hidden",
                }}
            >
                <ChevronLeftIcon
                    sx={{
                        width: "35px",
                        height: "35px",
                    }}
                />
            </IconButton>
        </Stack>
    );
};

/**
 * Note: Pages are 0-indexed.
 */
export const AppDotPaginationwBack = ({ pagination, page, onBack }) => {
    const navigate = useNavigate();
    const paginationItems = [];
    for (let i = 0; i < pagination.length; ++i) {
        const selected = page == i;
        paginationItems.push(
            <AppDotPaginationItem selected={selected} key={i} />
        );
    }

    return (
        <Stack direction="row" alignItems="center">
            <IconButton
                aria-label="back"
                size="large"
                onClick={onBack ?? (() => navigate(pagination[page - 1]))}
            >
                <ChevronLeftIcon sx={{ color: "black" }} />
            </IconButton>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#1D1B20",
                    width: "43x",
                    height: "22px",
                    paddingLeft: "0px",
                    paddingRight: "10px",
                    marginLeft: "-8px",
                    marginRight: "5px",
                }}
            >
                Back
            </Typography>
            {paginationItems}
        </Stack>
    );
};

const CustomCalendarHeader = (props) => {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () =>
        onMonthChange(
            currentMonth.add(1, "month").startOf("month").add(1, "day"),
            "left"
        );
    const selectPreviousMonth = () =>
        onMonthChange(
            currentMonth.subtract(1, "month").startOf("month").add(1, "day"),
            "right"
        );

    // const selectPreviousMonth = () =>
    //     onMonthChange(currentMonth.subtract(1, "month"), "right");
    // const selectNextMonth = () =>
    //     onMonthChange(currentMonth.add(1, "month"), "left");

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
                alignItems: "center",
            }}
        >
            <Stack spacing={1} direction="row">
                <IconButton
                    onClick={selectPreviousMonth}
                    title="Previous month"
                >
                    <ChevronLeft />
                </IconButton>
            </Stack>
            <Typography
                variant="body2"
                sx={{
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#020202",
                }}
            >
                {currentMonth.format("MMMM YYYY")}
            </Typography>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectNextMonth} title="Next month">
                    <ChevronRight />
                </IconButton>
            </Stack>
        </Box>
    );
};

export const CustomCalendar = ({
    timezone,
    date,
    setDate,
    width,
    height,
    dateHeight,
    borderRadius,
}) => {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    slots={{ calendarHeader: CustomCalendarHeader }}
                    dayOfWeekFormatter={(day) =>
                        dayjs(day).format("ddd").toUpperCase()
                    }
                    timezone={timezone}
                    value={date === null ? null : date}
                    onChange={(value) => {
                        const dateOnly = value.format("YYYY-MM-DD");

                        const timeZone = timezone;

                        const dateAtMidnightInTimeZone = dayjs.tz(
                            `${dateOnly} 00:00:00`,
                            timeZone
                        );

                        setDate(dateAtMidnightInTimeZone);
                    }}
                    disablePast
                    sx={{
                        background: "#FFFFFF",
                        //width: '798px',
                        width: `${width}px`,
                        maxHeight: "none",
                        // flexGrow: 1,
                        // flexShrink: 1,
                        // have to adjust height manually
                        // currently it is not growing to size of its children
                        // height: "575px",
                        height: `${height}px`,
                        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "2px solid #EDEDED", // Customize border style, color, and width
                        borderRadius: `${borderRadius}px`, // Optional: rounded corners
                        "& .MuiDayCalendar-root": {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        },
                        "& .MuiDayCalendar-header": {
                            width: "85%",
                            justifyContent: "space-between",
                        },
                        "& .MuiPickersSlideTransition-root": {
                            width: "85%",
                            justifyContent: "space-between",
                            height: "482px",
                        },
                        "& .MuiDayCalendar-weekContainer": {
                            justifyContent: "space-between",
                            // height: "78px",
                            height: `${dateHeight}px`,
                            // flexBasis: `${dateHeight}px`,
                        },
                    }}
                />
            </LocalizationProvider>
        </>
    );
};

const TimeButton = ({ label, isSelected, onClick, isMobile }) => {
    return (
        <Button
            onClick={onClick}
            variant={isSelected ? "contained" : "outlined"}
            sx={{
                boxSizing: "border-box",
                padding: "0px",
                // gap: "8px",
                width: "100%",
                height: "40px",
                border: "1px solid #1E1E1E",
                borderRadius: "100px",
                background: isSelected
                    ? "#0F3562"
                    : isMobile
                    ? "#FFFFFF"
                    : "#EDEDED",
                color: isSelected ? "white" : "black",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
            }}
        >
            {label}
        </Button>
    );
};

export const TempTimeDisplay = ({
    order,
    setOrder,
    timeslotsInfo,
    date,
    setDate,
    isMobile,
}) => {
    const containerStyle = {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "15px",
        width: "100%",
        // width: "362px",
        height: "auto",
        // marginTop: "20px",
        // marginBottom: "5px",
        // marginLeft: "35px",
        // marginRight: "75px",
    };

    return (
        <Box sx={containerStyle}>
            {timeslotsInfo.timeslots.map((slot, index) => {
                const datestring = date.toISOString().slice(0, 11);

                const beginServerDateFmt = slot[0];
                const endServerDateFmt = slot[1];

                const begin = new dayjs.tz(
                    datestring + beginServerDateFmt,
                    order.timezone
                );

                const end = new dayjs.tz(
                    datestring + endServerDateFmt,
                    order.timezone
                );

                const beginISOString = begin.toISOString().split(".")[0] + "Z";

                const duration = end.diff(begin, "minute");

                // this comparison can be made as-is because both are server format

                const formattedTime = toTimeIntervalString(begin, end);
                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <TimeButton
                            label={formattedTime}
                            key={index}
                            isSelected={order.time_requested === beginISOString}
                            isMobile={isMobile}
                            onClick={() => {
                                const selected =
                                    order.time_requested === beginISOString;

                                setOrder({
                                    ...order,
                                    time_requested: selected
                                        ? null
                                        : beginISOString,
                                    duration_requested: selected
                                        ? null
                                        : duration,
                                });

                                djangoEndpointPromise(
                                    "service-order/current/time_requested",
                                    {
                                        order_id: order.order_id,
                                        time_requested: selected
                                            ? null
                                            : beginISOString,
                                        duration: selected ? null : duration,
                                    }
                                );
                                // setDate(
                                //     order.time_requested === null
                                //         ? null
                                //         : dayjs(order.time_requested)
                                // );
                                // setIsValid(!selected);
                            }}
                        />
                    </div>
                );
            })}
        </Box>
    );
};

export const AppCard = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    if (imageVariant === "fullWidth") {
        return (
            <Card sx={fixWidth ? { minWidth: "60vw" } : null}>
                <CardActionArea component="div" onClick={onClick}>
                    <CardMedia component="img" image={image} />
                    <CardContent>
                        <Stack direction="column" alignItems="flex-start">
                            <Typography variant="h6">{title}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {subtitle}
                            </Typography>
                            <Button
                                sx={{ flexGrow: 0, alignSelf: "flex-end" }}
                                startIcon={<AddIcon />}
                                variant={selected ? "contained" : "outlined"}
                            >
                                Select
                            </Button>
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

/**
 * Created for the MassageGuestCountPage.
 */

const SelectButton = () => {
    const [selected, setSelected] = useState(false);

    const handleButtonClick = () => {
        setSelected(!selected);
    };

    return (
        <Button
            sx={{ flexGrow: 0, alignSelf: "flex-end" }}
            startIcon={selected ? <CheckIcon /> : <AddIcon />}
            variant={selected ? "contained" : "outlined"}
            onClick={handleButtonClick}
        >
            {selected ? "Selected" : "Select"}
        </Button>
    );
};

/**
 * Created for the PrivateChef
 */

export const SelectButtonDynamic = ({ selected }) => {
    // const [selected, setSelected] = useState(false);

    // const handleButtonClick = () => {
    //     setSelected(!selected);
    // };

    return (
        <Button
            sx={{ flexGrow: 0, alignSelf: "flex-end" }}
            startIcon={selected ? <CheckIcon /> : <AddIcon />}
            variant={selected ? "contained" : "outlined"}
            // onClick={handleButtonClick}
        >
            {selected ? "Selected" : "Select"}
        </Button>
    );
};

/**
 * For the MassageTypeLengthTherapist Page.
 */
export const MassageTypeCard = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    const cardStyles = {
        maxWidth: "794px",
        top: "340px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
        height: "259px",
        // flexGrow: 1,
        // left: "106px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card sx={fixWidth ? { ...cardStyles } : cardStyles}>
                <CardActionArea
                    component="div"
                    onClick={onClick}
                    sx={{ display: "flex", flexDirection: "row" }}
                >
                    <CardContent>
                        <Stack
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                    <CardMedia
                        component="img"
                        image={image}
                        sx={{
                            width: "396px",
                            height: "259px",
                        }}
                    />
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

/**
 * For the PrivateChefBld Page.
 */
export const BLDCard = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    const cardStyles = {
        width: "794px",
        top: "340px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
        height: "259px",
        left: "106px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card
                sx={fixWidth ? { minWidth: "60vw", ...cardStyles } : cardStyles}
            >
                <CardActionArea
                    component="div"
                    onClick={onClick}
                    sx={{ display: "flex", flexDirection: "row" }}
                >
                    <CardContent>
                        <Stack
                            direction="column"
                            spacing="15px"
                            sx={{
                                marginTop: "10px",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                    marginBottom: "20px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                    <CardMedia
                        component="img"
                        image={image}
                        sx={{
                            width: "396px",
                            height: "259px",
                        }}
                    />
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

/**
 * For the PrivateChefBld Page.
 */
export const BLDCardMobile = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
}) => {
    const cardStyles = {
        width: "100%",
        height: "407px",
        borderRadius: "12px",
        opacity: "0px",
        background: "#FFFFFF",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card sx={{ cardStyles }}>
                <CardActionArea
                    component="div"
                    onClick={onClick}
                    sx={{ display: "flex", flexDirection: "column" }}
                >
                    <CardMedia
                        component="img"
                        image={image}
                        sx={{
                            width: "100%",
                            height: "239px",
                        }}
                    />
                    <CardContent>
                        <Stack
                            direction="column"
                            spacing="15px"
                            sx={{
                                marginTop: "10px",
                                alignItems: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                    marginBottom: "20px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export const MassageCountCard = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    const cardStyles = {
        // minWidth: "377px",
        // maxWidth: "377px",
        width: "377px",
        top: "340px",
        gap: "0px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card
                sx={fixWidth ? { minWidth: "60vw", ...cardStyles } : cardStyles}
            >
                <CardActionArea component="div" onClick={onClick}>
                    <CardMedia component="img" image={image} />
                    <CardContent>
                        <Stack direction="column" alignItems="flex-start">
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export const MassageCountCardMobile = ({
    imageVariant,
    image,
    title,
    subtitle,
    onClick,
    selected,
    fixWidth,
}) => {
    const cardStyles = {
        // minWidth: "377px",
        maxWidth: "377px",
        width: "100%",
        //top: "340px",
        gap: "0px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card
                sx={fixWidth ? { minWidth: "60vw", ...cardStyles } : cardStyles}
            >
                <CardActionArea component="div" onClick={onClick}>
                    <CardMedia component="img" image={image} />
                    <CardContent>
                        <Stack direction="column" alignItems="flex-start">
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    lineHeight: "24px",
                                    letterSpacing: "0.5px",
                                    textAlign: "left",
                                }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                    marginBottom: "10px",
                                }}
                                color="text.secondary"
                            >
                                {subtitle}
                            </Typography>
                            <SelectButtonDynamic selected={selected} />
                        </Stack>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
    return (
        <Card>
            <CardActionArea
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
            >
                <CardMedia component="img" image={image} sx={{ flex: 0 }} />
                <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{title}</Typography>
                    <Typography variant="subtitle1">{subtitle}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export const PrivateChefP3Card = ({
    imageVariant,
    image,
    price,
    title,
    subtitle,
    fixWidth,
}) => {
    const cardStyles = {
        width: "377px",
        top: "340px",
        gap: "0px",
        borderRadius: "12px 12px 12px 12px",
        opacity: "0px",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card
                sx={fixWidth ? { minWidth: "60vw", ...cardStyles } : cardStyles}
            >
                <CardMedia component="img" image={image} />
                <CardContent>
                    <Stack direction="column" alignItems="flex-start">
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                                textAlign: "left",
                            }}
                        >
                            {title}
                        </Typography>
                        <Stack>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                }}
                                color="text.secondary"
                            >
                                From
                            </Typography>
                            <Typography>${price}/guest</Typography>
                        </Stack>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                lineHeight: "20px",
                                letterSpacing: "0.25px",
                                textAlign: "left",
                                marginTop: "16px",
                            }}
                            color="text.secondary"
                        >
                            {subtitle}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        );
    }
    return (
        <Card>
            <CardMedia component="img" image={image} sx={{ flex: 0 }} />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
            </CardContent>
        </Card>
    );
};

export const PrivateChefP3CardMobile = ({
    imageVariant,
    image,
    price,
    title,
    subtitle,
    fixWidth,
}) => {
    const cardStyles = {
        width: "92%",
        gap: "0px",
        borderRadius: "12px",
        opacity: "0px",
    };

    if (imageVariant === "fullWidth") {
        return (
            <Card
                sx={fixWidth ? { minWidth: "60vw", ...cardStyles } : cardStyles}
            >
                <CardMedia component="img" image={image} />
                <CardContent>
                    <Stack direction="column" alignItems="flex-start">
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "20px",
                                fontWeight: "600",
                                lineHeight: "24px",
                                letterSpacing: "0.5px",
                                textAlign: "left",
                            }}
                        >
                            {title}
                        </Typography>
                        <Stack>
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    letterSpacing: "0.25px",
                                    textAlign: "left",
                                    marginTop: "16px",
                                }}
                                color="text.secondary"
                            >
                                From
                            </Typography>
                            <Typography>${price}/guest</Typography>
                        </Stack>
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                lineHeight: "20px",
                                letterSpacing: "0.25px",
                                textAlign: "left",
                                marginTop: "16px",
                            }}
                            color="text.secondary"
                        >
                            {subtitle}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        );
    }
    return (
        <Card>
            <CardMedia component="img" image={image} sx={{ flex: 0 }} />
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{title}</Typography>
                <Typography variant="subtitle1">{subtitle}</Typography>
            </CardContent>
        </Card>
    );
};

export const Title = ({ title }) => {
    return (
        <Typography
            sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "40px",
                lineHeight: "58px",
                height: "45.04px",
                textAlign: "left",
            }}
        >
            {title}
        </Typography>
    );
};

export const MobileTitle = ({ title }) => {
    return (
        <Typography
            sx={{
                fontFamily: "Inter",

                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "24px",
            }}
        >
            {title}
        </Typography>
    );
};

/**
 * To understand fetchResult, see util.fetchResultType.
 */
export const Loading = ({ renderChildren, fetchResult, onTryAgain }) => {
    const branch = fetchResultType(fetchResult);
    if (branch === "loading") {
        return (
            <Container>
                <LoadingIndicator />
            </Container>
        );
    }
    if (branch === "data") {
        return renderChildren();
    }
    if (branch === "error") {
        const error = fetchResult.error;
        const message =
            Array.isArray(error) && error.length > 0
                ? String(error[0])
                : String(error);
        console.error("Nexus error", error);
        return (
            <Container>
                <Typography variant="h1">Error</Typography>
                <Typography variant="body1">Message: {message}</Typography>
                <Button onClick={onTryAgain}>Try again</Button>
            </Container>
        );
    }
    throw new Error("should not happen");
};

/**
 * Encapsulates the loading indicator UI element.
 */
export const LoadingIndicator = () => {
    return <CircularProgress />;
};
