import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    Container,
    useMediaQuery,
    IconButton,
    Grid,
    Stack,
} from "@mui/material";
import LocalPizzaOutlinedIcon from "@mui/icons-material/LocalPizzaOutlined";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import {
    Loading,
    LoadingIndicator,
    AppDotPagination,
    AppDotPaginationwBack,
    Ellipse,
    HeaderFooter,
    Title,
    MobileTitle,
} from "../components/UI";
import {
    djangoEndpointPromise,
    fetchResultType,
    useCurrentServiceOrder,
    PRIVATE_CHEF_PAGINATION,
    useCurrentHost,
    useCurrentMenu,
    mergeMultipleResults,
    capitalizeFirstLetter,
    addSToEnd,
} from "../util";
import logo from "../assets/ellipse.png";
import menuPicture from "../assets/macncheese.png";

const onNext = async (orderId, coursesInfo) => {
    const extractSelectedDishes = (courses) => {
        const result = [];

        Object.values(courses).forEach((course) => {
            if (course.beenSelected) {
                course.dishes.forEach((dish) => {
                    if (dish.numServingsSelected > 0) {
                        result.push({
                            courseDishId: dish.courseDishId,
                            numServingsSelected: dish.numServingsSelected,
                            addOn: course.addOn,
                        });
                    }
                    if (dish.included) {
                        result.push({
                            courseDishId: dish.courseDishId,
                            numServingsSelected: coursesInfo.numServings,
                            addOn: course.addOn,
                        });
                    }
                });
            }
        });

        return result;
    };

    const selectedDishes = extractSelectedDishes(coursesInfo.courses);

    try {
        const url = "private-chef-menu/input-dishes-and-courses";

        const result = await djangoEndpointPromise(url, {
            order_id: orderId,
            course_dishes: selectedDishes,
        });

        if (fetchResultType(result) === "error") {
            throw new Error(
                "Server Error: Something went wrong on the server."
            );
        }
    } catch (error) {
        console.error("Error with backend:", error);
    }
};

const Counter = ({ count, updateCounter }) => {
    return (
        <Box display="flex" sx={{ gap: "8px" }}>
            <IconButton
                onClick={() => updateCounter(-1)}
                sx={{
                    padding: 0,
                }}
            >
                <Ellipse
                    text={
                        <Typography
                            sx={{
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "14px",
                                lineHeight: "20px",
                                color: "#000000",
                            }}
                        >
                            -
                        </Typography>
                    }
                    width={22}
                />
            </IconButton>
            <Typography
                sx={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#000000",
                }}
            >
                {count}
            </Typography>
            <IconButton
                onClick={() => updateCounter(1)}
                sx={{
                    padding: 0,
                }}
            >
                <Ellipse
                    text={
                        <AddIcon
                            sx={{
                                width: "10px",
                                height: "20px",
                            }}
                        />
                        // <Typography
                        //     sx={{
                        //         fontFamily: "Inter",
                        //         fontWeight: 400,
                        //         fontSize: "14px",
                        //         lineHeight: "20px",
                        //         color: "#000000",
                        //     }}
                        // >
                        //     +
                        // </Typography>
                    }
                    width={22}
                />
            </IconButton>
        </Box>
    );
};

const CourseSelections = ({
    coursesInfo,
    includedCourses,
    courseSelected,
    setCourseSelected,
    notIncludedCourses,
    isNonspecificChoosing,
    setIsNonspecificChoosing,
    currentAddonChoosing,
    setcurrentAddonChoosing,
}) => {
    return (
        <>
            <Typography
                component="div"
                sx={{
                    marginTop: "15px",
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#1D1B20",
                }}
            >
                Included
            </Typography>
            {includedCourses.length > 0 && (
                <>
                    <Typography
                        component="div"
                        sx={{
                            marginTop: "5px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#000000",
                        }}
                    >
                        Choose dishes for these included courses
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{
                            marginTop: "12px",
                            gap: "11px",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            {includedCourses.map((courseName, index) =>
                                courseName === courseSelected ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#0C2E55",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : coursesInfo["courses"][courseName][
                                      "beenSelected"
                                  ] ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#0C2E55",
                                                gap: "3px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#FFFFFF",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#FFFFFF",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #CACACA",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#FFFFFF",
                                                gap: "5px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#000000",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#000000",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                </>
            )}

            {notIncludedCourses.length > 0 && (
                <>
                    <Typography
                        component="div"
                        sx={{
                            marginTop: "5px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "19px",
                            color: "#000000",
                        }}
                    >
                        Choose dishes for{" "}
                        {coursesInfo["menu"]["num_nonspecific_courses"]} of
                        these courses
                    </Typography>

                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{
                            marginTop: "12px",
                            gap: "11px",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            {notIncludedCourses.map((courseName, index) =>
                                courseName === courseSelected &&
                                isNonspecificChoosing ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(true);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#0C2E55",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : coursesInfo["courses"][courseName][
                                      "beenSelected"
                                  ] &&
                                  coursesInfo["courses"][courseName][
                                      "nonSpecificChosen"
                                  ] ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(true);
                                            }}
                                            variant="outlined"
                                            key={index}
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#0C2E55",
                                                gap: "3px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#FFFFFF",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#FFFFFF",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : coursesInfo[
                                      "numNonspecificCoursesSelected"
                                  ] <
                                  coursesInfo["menu"][
                                      "num_nonspecific_courses"
                                  ] ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(true);
                                            }}
                                            variant="outlined"
                                            key={index}
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #CACACA",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#FFFFFF",
                                                gap: "5px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#000000",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#000000",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            variant="outlined"
                                            key={index}
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #CACACA",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#D3D3D3",
                                                gap: "5px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#656565",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#656565",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                </>
            )}

            {coursesInfo["numNonspecificCoursesSelected"] >=
                coursesInfo["menu"]["num_nonspecific_courses"] && (
                <>
                    <Typography
                        component="div"
                        sx={{
                            marginTop: "15px",
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "24px",
                            color: "#1D1B20",
                        }}
                    >
                        Add-Ons
                    </Typography>
                    <Typography
                        component="div"
                        sx={{
                            textAlign: "left",
                            marginTop: "5px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "19px",
                            color: "#000000",
                        }}
                    >
                        Choose a dish from an additional course below to add it
                        to your meal
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        sx={{
                            marginTop: "12px",
                            gap: "11px",
                            width: "100%",
                        }}
                    >
                        <Grid container spacing={2}>
                            {notIncludedCourses.map((courseName, index) =>
                                courseName === courseSelected &&
                                !coursesInfo["courses"][courseName][
                                    "nonSpecificChosen"
                                ] ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#0C2E55",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : coursesInfo["courses"][courseName][
                                      "beenSelected"
                                  ] &&
                                  !coursesInfo["courses"][courseName][
                                      "nonSpecificChosen"
                                  ] ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            variant="outlined"
                                            key={index}
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #0F3562",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#0C2E55",
                                                gap: "3px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#FFFFFF",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 600,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#FFFFFF",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : !coursesInfo["courses"][courseName][
                                      "nonSpecificChosen"
                                  ] &&
                                  coursesInfo["courses"][courseName]["addOn"] <
                                      3 ? (
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Button
                                            onClick={() => {
                                                setCourseSelected(courseName);
                                                setIsNonspecificChoosing(false);
                                            }}
                                            key={index}
                                            variant="outlined"
                                            sx={{
                                                width: "118px",
                                                height: "34px",
                                                borderRadius: "10px",
                                                border: "3px solid #CACACA",
                                                padding: "5px 12px 5px 12px",
                                                backgroundColor: "#FFFFFF",
                                                gap: "5px",
                                            }}
                                        >
                                            <LocalPizzaOutlinedIcon
                                                sx={{
                                                    color: "#000000",
                                                    width: "20px",
                                                    height: "19px",
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                    lineHeight: "17px",
                                                    color: "#000000",
                                                }}
                                            >
                                                {courseName
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    courseName.slice(1)}
                                            </Typography>
                                        </Button>
                                    </Grid>
                                ) : (
                                    !coursesInfo["courses"][courseName][
                                        "nonSpecificChosen"
                                    ] && (
                                        <Grid item xs={4} sm={4} md={4} lg={4}>
                                            <Button
                                                variant="outlined"
                                                key={index}
                                                sx={{
                                                    width: "118px",
                                                    height: "34px",
                                                    borderRadius: "10px",
                                                    border: "3px solid #CACACA",
                                                    padding:
                                                        "5px 12px 5px 12px",
                                                    backgroundColor: "#D3D3D3",
                                                    gap: "5px",
                                                }}
                                            >
                                                <LocalPizzaOutlinedIcon
                                                    sx={{
                                                        color: "#656565",
                                                        width: "20px",
                                                        height: "19px",
                                                    }}
                                                />
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "14px",
                                                        lineHeight: "17px",
                                                        color: "#656565",
                                                    }}
                                                >
                                                    {courseName
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        courseName.slice(1)}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    )
                                )
                            )}
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
};

const DishCard = ({
    dishInfo,
    index,
    coursesInfo,
    setCoursesInfo,
    courseSelected,
    isNonspecificChoosing,
    setIsFulfilled,
    currentAddonChoosing,
    setcurrentAddonChoosing,
}) => {
    const resetCourse = (course) => {
        // Set course properties
        course.addOn = 0;
        course.beenSelected = false;
        course.nonSpecificChosen = false;
        course.numDishesSelected = 0;
        course.numServingsSelected = 0;

        // Reset each dish in the course
        course.dishes.forEach((dish) => {
            dish.numServingsSelected = 0;
        });
    };

    const updateCounter = (delta) => {
        setCoursesInfo((prevState) => {
            // Destructure the necessary parts of the state
            const { courses } = prevState;
            const selectedCourse = courses[courseSelected];
            const selectedDish = selectedCourse.dishes[index];

            // Update numServingsSelected for the specific dish
            const updatedDish = {
                ...selectedDish,
                numServingsSelected: Math.max(
                    (selectedDish.numServingsSelected || 0) + delta,
                    0
                ), // Prevent negative values
            };

            // Create a new array of dishes with the updated dish
            const updatedDishes = [
                ...selectedCourse.dishes.slice(0, index),
                updatedDish,
                ...selectedCourse.dishes.slice(index + 1),
            ];

            const totalNumDishesSelected = updatedDishes.reduce(
                (total, dish) => {
                    return total + (dish.numServingsSelected > 0 || 0);
                },
                0
            );

            if (
                totalNumDishesSelected >
                coursesInfo["courses"][courseSelected]["nonspecificDishesMax"]
            ) {
                return prevState;
            }

            const totalNumServingsSelected = updatedDishes.reduce(
                (total, dish) => {
                    return total + (dish.numServingsSelected || 0);
                },
                0
            );

            if (totalNumServingsSelected > coursesInfo.numServings) {
                return prevState;
            }

            // Create a new object for the course with the updated dishes and total servings
            const updatedCourse = {
                ...selectedCourse,
                dishes: updatedDishes,
                numServingsSelected: totalNumServingsSelected,
                numDishesSelected: totalNumDishesSelected,
                beenSelected: totalNumServingsSelected > 0,
                nonSpecificChosen:
                    isNonspecificChoosing && totalNumServingsSelected > 0,
            };

            // Create a new courses object with the updated course
            const updatedCourses = {
                ...courses,
                [courseSelected]: updatedCourse,
            };

            const totalNumNonspecificCoursesSelected = Object.values(
                updatedCourses
            ).filter(
                (course) =>
                    course.beenSelected &&
                    !course.included &&
                    course.nonSpecificChosen
            ).length;

            if (
                totalNumNonspecificCoursesSelected >=
                coursesInfo["menu"]["num_nonspecific_courses"] &&
                currentAddonChoosing === 0
            ) {
                Object.entries(updatedCourses).forEach(
                    ([courseName, courseData]) => {
                        if (
                            !courseData.included &&
                            !courseData.nonSpecificChosen
                        ) {
                            courseData.addOn = 1;
                        }
                    }
                );
                setcurrentAddonChoosing(1);
            } else if (
                totalNumNonspecificCoursesSelected <
                coursesInfo["menu"]["num_nonspecific_courses"]
            ) {
                Object.entries(updatedCourses).forEach(
                    ([courseName, courseData]) => {
                        courseData.addOn = 0;
                        if (
                            !courseData.included &&
                            !courseData.nonSpecificChosen
                        ) {
                            resetCourse(courseData);
                        }
                    }
                );
                setcurrentAddonChoosing(0);
            }

            if (
                !updatedCourse.nonSpecificChosen &&
                !updatedCourse.included &&
                updatedCourse.beenSelected
            ) {
                Object.entries(updatedCourses).forEach(
                    ([courseName, courseData]) => {
                        if (
                            courseName !== courseSelected &&
                            courseData.addOn === updatedCourse.addOn &&
                            !courseData.included &&
                            !courseData.nonSpecificChosen
                        ) {
                            courseData.addOn += 1;
                        }
                    }
                );
            } else if (
                !updatedCourse.nonSpecificChosen &&
                !updatedCourse.included &&
                !updatedCourse.beenSelected
            ) {
                Object.entries(updatedCourses).forEach(
                    ([courseName, courseData]) => {
                        if (
                            courseName !== selectedCourse &&
                            courseData.addOn > updatedCourse.addOn
                        ) {
                            courseData.addOn -= 1;
                        }
                    }
                );
                const addOnValues = Object.values(updatedCourses).map(
                    (course) => course.addOn
                );

                // Find the maximum value, or return null if no values are found

                updatedCourse.addOn = Math.max(...addOnValues);
            }

            const addOnValues = Object.entries(updatedCourses).map(
                ([courseName, courseData]) => ({
                    courseName,
                    addOn: courseData.addOn,
                })
            );

            const totalNumServingsAllSelectedCourses =
                (Object.values(updatedCourses).filter(
                    (course) => course.included
                ).length +
                    coursesInfo["menu"]["num_nonspecific_courses"] +
                    Object.values(updatedCourses).filter(
                        (course) =>
                            !course.included &&
                            course.beenSelected &&
                            !course.nonSpecificChosen
                    ).length) *
                coursesInfo.numServings;

            const allCoursesSelected = Object.entries(updatedCourses).filter(
                ([courseName, courseData]) =>
                    courseData.beenSelected || courseData.included
            );

            const totalNumServingsAllSelectedCoursesSelected = Object.values(
                updatedCourses
            )
                .filter((course) => course.beenSelected || course.included)
                .reduce(
                    (total, course) =>
                        total + (course.numServingsSelected || 0),
                    0
                );

            const isNumDishSelectedSatisfiable = allCoursesSelected.every(
                ([courseName, courseData]) => {
                    const selectedDishesCount = courseData.dishes.filter(
                        (dish) => dish.numServingsSelected > 0
                    ).length;
                    return (
                        selectedDishesCount >=
                        courseData.nonspecificDishesMin &&
                        selectedDishesCount <= courseData.nonspecificDishesMax
                    );
                }
            );

            setIsFulfilled(
                totalNumServingsAllSelectedCoursesSelected ===
                totalNumServingsAllSelectedCourses &&
                isNumDishSelectedSatisfiable
            );

            const extractSelectedDishes = (courses) => {
                const result = [];

                Object.values(courses).forEach((course) => {
                    if (course.beenSelected) {
                        course.dishes.forEach((dish) => {
                            if (dish.numServingsSelected > 0) {
                                result.push({
                                    dishName: dish.name,
                                    courseDishId: dish.courseDishId,
                                    numServingsSelected:
                                        dish.numServingsSelected,
                                });
                            }
                            if (dish.included) {
                                result.push({
                                    dishName: dish.name,
                                    courseDishId: dish.courseDishId,
                                    numServingsSelected:
                                        coursesInfo.numServings,
                                });
                            }
                        });
                    }
                });

                return result;
            };

            const selectedDishes = extractSelectedDishes(updatedCourses);

            const updatedCoursesInfo = {
                ...prevState,
                courses: updatedCourses,
                numNonspecificCoursesSelected:
                    totalNumNonspecificCoursesSelected,
            };

            // Return the new state object
            return updatedCoursesInfo;
        });
    };

    return (
        <>
            <Box
                sx={{
                    backgroundColor: "#FFFFFF",
                    width: "100%",
                    flexShrink: 0,
                    flexGrow: 0,
                    height: "248px",
                    borderRadius: "12px",
                    padding: "20px 16px 20px 16px",
                    border: "1px solid #CACACA",
                    opacity: "0px",
                }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
            >
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#1D1B20",
                    }}
                >
                    {capitalizeFirstLetter(dishInfo.name)}
                </Typography>

                {dishInfo.addlPrice > 0 ? (
                    <Typography
                        sx={{
                            marginTop: "16px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: "20px",
                            lineHeight: "20px",
                            color: "#49454F",
                        }}
                    >
                        {"$" + dishInfo.addlPrice}
                    </Typography>
                ) : (
                    <Typography
                        component="div"
                        sx={{
                            marginTop: "16px",
                            visibility: "hidden",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: "20px",
                            lineHeight: "20px",
                            color: "#49454F",
                        }}
                    >
                        0
                    </Typography>
                )}

                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        marginTop: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#49454F",
                    }}
                >
                    {capitalizeFirstLetter(dishInfo.description)}
                </Typography>

                <Typography
                    component="div"
                    sx={{
                        textAlign: "left",
                        marginTop: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#49454F",
                    }}
                >
                    {"Includes: " + capitalizeFirstLetter(dishInfo.allergens)}
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        height: "40px",
                        width: "328px",
                        marginTop: "16px",
                    }}
                >
                    {dishInfo.included ? (
                        <>
                            <Button
                                sx={{
                                    visibility: "hidden",
                                    radius: "100px",
                                    width: "112px",
                                    height: "40px",
                                }}
                            >
                                Included
                            </Button>
                            <Button
                                sx={{
                                    radius: "100px",
                                    width: "112px",
                                    height: "40px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Included
                                </Typography>
                            </Button>
                        </>
                    ) : coursesInfo["courses"][courseSelected]["dishes"][index][
                        "numServingsSelected"
                    ] > 0 ? (
                        <>
                            <Counter
                                count={
                                    coursesInfo["courses"][courseSelected][
                                    "dishes"
                                    ][index]["numServingsSelected"]
                                }
                                updateCounter={updateCounter}
                            />{" "}
                            <Button
                                onClick={() =>
                                    updateCounter(
                                        -1 *
                                        coursesInfo["courses"][
                                        courseSelected
                                        ]["dishes"][index][
                                        "numServingsSelected"
                                        ]
                                    )
                                }
                                sx={{
                                    gap: "4px",
                                    radius: "100px",
                                    width: "112px",
                                    height: "40px",
                                }}
                            >
                                <CheckIcon
                                    sx={{
                                        width: "24px",
                                        height: "auto",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#FFFFFF",
                                    }}
                                >
                                    Selected
                                </Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Counter
                                count={
                                    coursesInfo["courses"][courseSelected][
                                    "dishes"
                                    ][index]["numServingsSelected"]
                                }
                                updateCounter={updateCounter}
                            />{" "}
                            <Button
                                variant="outline"
                                onClick={() => updateCounter(1)}
                                sx={{
                                    border: "1px solid #1E1E1E",
                                    radius: "100px",
                                    width: "112px",
                                    height: "40px",
                                    gap: "8px",
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        width: "20px",
                                        height: "auto",
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#1E1E1E",
                                    }}
                                >
                                    Select
                                </Typography>
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

const NextButton = ({ isFulfilled, navigate, orderId, coursesInfo, width }) => {
    return (
        <>
            {isFulfilled ? (
                <Button
                    onClick={async () => {
                        onNext(orderId, coursesInfo);
                        navigate(PRIVATE_CHEF_PAGINATION[4]);
                    }}
                    sx={{
                        marginTop: "10px",
                        width: width,
                        height: "44px",
                        radius: "100px",
                        backgroundColor: "#0F3562",
                        boxShadow: "none",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#FFFFFF",
                        }}
                    >
                        Next
                    </Typography>
                </Button>
            ) : (
                <Button
                    sx={{
                        marginTop: "10px",
                        width: width,
                        height: "44px",
                        radius: "100px",
                        backgroundColor: "#D8D8D9",
                        boxShadow: "none",
                    }}
                    disabled
                >
                    <Typography
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "20px",
                            color: "#78767B",
                        }}
                    >
                        Next
                    </Typography>
                </Button>
            )}
        </>
    );
};

const useCoursesInfo = (order) => {
    const [coursesInfo, setCoursesInfo] = useState({});
    const tryLoad = useCallback(async () => {
        try {
            const url = `private-chef-menu/retrieve-courses-and-dishes?order_id=${encodeURIComponent(
                order.order_id
            )}`;

            const result = await djangoEndpointPromise(url);

            if (fetchResultType(result) === "error") {
                throw new Error(
                    "Server Error: Something went wrong on the server."
                );
            }

            setCoursesInfo(result.data);
        } catch (error) {
            // Handle errors in communicating with your backend server
            console.error("Error with backend:", error);
        }
    }, [order]);
    // useEffect(() => {
    //     if (order) {
    //         tryLoad();
    //     }
    // }, [tryLoad]);
    return [coursesInfo, tryLoad, setCoursesInfo];
};

const classifyCourses = (courses) => {
    return Object.entries(courses).reduce(
        (acc, [courseName, courseData]) => {
            if (courseData.included) {
                acc.included.push(courseName);
            } else {
                acc.notIncluded.push(courseName);
            }
            return acc;
        },
        { included: [], notIncluded: [] }
    );
};

const PrivateChefSelectionsMobileUI = ({
    orderId,
    hostInfo,
    coursesInfo,
    setCoursesInfo,
    includedCourses,
    notIncludedCourses,
    courseSelected,
    setCourseSelected,
    isNonspecificChoosing,
    setIsNonspecificChoosing,
    isFulfilled,
    setIsFulfilled,
    navigate,
    currentAddonChoosing,
    setcurrentAddonChoosing,
}) => {
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "0px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "20px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "20px",
                                alignItems: "center",
                                padding: "0px 16px 0px 16px",
                                width: "85%",
                                paddingLeft: "80px"
                            }}
                        >
                            <AppDotPagination
                                page={3}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />
                            <MobileTitle title={"Choose Selections"} />

                            <Box
                                sx={{
                                    margin: "0px 17px 0px 17px",
                                    gap: "15px",
                                    paddingLeft: "30px",
                                    paddingRight: "30px"
                                }}
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                            >
                                <CourseSelections
                                    coursesInfo={coursesInfo}
                                    includedCourses={includedCourses}
                                    courseSelected={courseSelected}
                                    setCourseSelected={setCourseSelected}
                                    notIncludedCourses={notIncludedCourses}
                                    isNonspecificChoosing={
                                        isNonspecificChoosing
                                    }
                                    setIsNonspecificChoosing={
                                        setIsNonspecificChoosing
                                    }
                                    currentAddonChoosing={currentAddonChoosing}
                                    setcurrentAddonChoosing={
                                        setcurrentAddonChoosing
                                    }
                                />
                            </Box>
                            <Typography
                                component="div"
                                sx={{
                                    marginTop: "18px",
                                    fontFamily: "Inter",
                                    fontWeight: 500,
                                    fontSize: "20px",
                                    lineHeight: "24px",
                                    color: "#000000",
                                }}
                            >
                                {coursesInfo.menu.name}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    marginTop: "12px",
                                    marginLeft: "49px",
                                    marginRight: "49px",
                                }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    sx={{
                                        gap: "4px",
                                        width: "85px",
                                    }}
                                >
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#000000",
                                        }}
                                    >
                                        Select
                                    </Typography>

                                    <Ellipse
                                        text={coursesInfo.numServings}
                                        width={38}
                                    />

                                    <Typography
                                        component="div"
                                        sx={{
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#000000",
                                        }}
                                    >
                                        Servings
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    sx={{
                                        gap: "4px",
                                    }}
                                >
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#000000",
                                        }}
                                    >
                                        Select
                                    </Typography>
                                    {coursesInfo["courses"][courseSelected][
                                        "nonspecificDishesMin"
                                    ] ===
                                        coursesInfo["courses"][courseSelected][
                                        "nonspecificDishesMax"
                                        ] ? (
                                        <>
                                            <Ellipse
                                                text={
                                                    coursesInfo["courses"][
                                                    courseSelected
                                                    ]["nonspecificDishesMax"]
                                                }
                                                width={38}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                sx={{
                                                    gap: "5px",
                                                }}
                                            >
                                                <Ellipse
                                                    text={
                                                        coursesInfo["courses"][
                                                        courseSelected
                                                        ][
                                                        "nonspecificDishesMin"
                                                        ]
                                                    }
                                                    width={38}
                                                />

                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "19px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    {" "}
                                                    -{" "}
                                                </Typography>

                                                <Ellipse
                                                    text={
                                                        coursesInfo["courses"][
                                                        courseSelected
                                                        ][
                                                        "nonspecificDishesMax"
                                                        ]
                                                    }
                                                    width={38}
                                                />
                                            </Box>
                                        </>
                                    )}

                                    <Typography
                                        component="div"
                                        sx={{
                                            fontFamily: "Inter",
                                            fontWeight: 400,
                                            fontSize: "16px",
                                            lineHeight: "19px",
                                            color: "#000000",
                                        }}
                                    >
                                        Dishes
                                    </Typography>
                                </Box>
                                {coursesInfo["courses"][courseSelected][
                                    "addOn"
                                ] > 0 ? (
                                    <>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                            sx={{
                                                gap: "4px",
                                                width: "85px",
                                            }}
                                        >
                                            <Typography
                                                component="div"
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "16px",
                                                    lineHeight: "19px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Added
                                            </Typography>

                                            <Ellipse
                                                text={
                                                    "$" +
                                                    (coursesInfo["courses"][
                                                        courseSelected
                                                    ]["addOn"] === 1
                                                        ? coursesInfo
                                                            .basePriceInfo
                                                            .first_addl_course
                                                        : coursesInfo
                                                            .basePriceInfo
                                                            .second_addl_course)
                                                }
                                                width={38}
                                            />

                                            <Typography
                                                component="div"
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: 400,
                                                    fontSize: "16px",
                                                    lineHeight: "19px",
                                                    color: "#000000",
                                                }}
                                            >
                                                Per Guests
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        <Box sx={{ width: "85px" }}></Box>
                                    </>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                sx={{
                                    marginTop: "20px",
                                    marginBottom: "15px",
                                    gap: "12px",
                                }}
                            >
                                {coursesInfo["courses"][courseSelected][
                                    "dishes"
                                ].map((dishInfo, index) => (
                                    <DishCard
                                        dishInfo={dishInfo}
                                        index={index}
                                        coursesInfo={coursesInfo}
                                        setCoursesInfo={setCoursesInfo}
                                        courseSelected={courseSelected}
                                        isNonspecificChoosing={
                                            isNonspecificChoosing
                                        }
                                        setIsFulfilled={setIsFulfilled}
                                        currentAddonChoosing={
                                            currentAddonChoosing
                                        }
                                        setcurrentAddonChoosing={
                                            setcurrentAddonChoosing
                                        }
                                    />
                                ))}
                            </Box>

                            <NextButton
                                isFulfilled={isFulfilled}
                                navigate={navigate}
                                orderId={orderId}
                                coursesInfo={coursesInfo}
                                width={360}
                            />
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const PrivateChefSelectionsDesktopUI = ({
    orderId,
    hostInfo,
    menuInfo,
    coursesInfo,
    setCoursesInfo,
    includedCourses,
    notIncludedCourses,
    courseSelected,
    setCourseSelected,
    isNonspecificChoosing,
    setIsNonspecificChoosing,
    isFulfilled,
    setIsFulfilled,
    navigate,
    currentAddonChoosing,
    setcurrentAddonChoosing,
}) => {
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "1512px",
                    backgroundColor: "#FFFFFF",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            paddingBottom: "125px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "97px",
                                marginLeft: "100px",
                                alignItems: "flex-start",
                            }}
                        >
                            <Title title="Choose Selections" />
                            <AppDotPaginationwBack
                                page={3}
                                pagination={PRIVATE_CHEF_PAGINATION}
                            />

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    width: "1428px",
                                }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={7}>
                                    <Box
                                        sx={{
                                            backgroundColor: "#EDEDED",
                                            width: "798px",
                                            // width: "53%",
                                            // height: "758px",
                                            borderRadius: "20px",
                                            padding: "43px 0px 43px 0px",
                                            opacity: "0px",
                                        }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        {coursesInfo["courses"][courseSelected][
                                            "addOn"
                                        ] > 0 ? (
                                            <>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        width: "100%",
                                                        padding:
                                                            "0px 27px 0px 27px",
                                                    }}
                                                >
                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        sx={{
                                                            visibility:
                                                                "hidden",
                                                            gap: "6px",
                                                            // width: "39%",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            Select
                                                        </Typography>

                                                        <Ellipse
                                                            text={
                                                                coursesInfo.numServings
                                                            }
                                                            width={38}
                                                        />

                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            Servings
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                // marginTop: "43px",
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 500,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            {addSToEnd(
                                                                capitalizeFirstLetter(
                                                                    courseSelected
                                                                )
                                                            )}
                                                        </Typography>
                                                    </Box>

                                                    <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        sx={{
                                                            gap: "6px",
                                                            // width: "39%",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            Added
                                                        </Typography>

                                                        <Ellipse
                                                            text={
                                                                "$" +
                                                                (coursesInfo[
                                                                    "courses"
                                                                ][
                                                                    courseSelected
                                                                ]["addOn"] === 1
                                                                    ? coursesInfo
                                                                        .basePriceInfo
                                                                        .first_addl_course
                                                                    : coursesInfo
                                                                        .basePriceInfo
                                                                        .second_addl_course)
                                                            }
                                                            width={38}
                                                        />

                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            Per Guest
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : (
                                            <Typography
                                                component="div"
                                                sx={{
                                                    // marginTop: "43px",
                                                    fontFamily: "Inter",
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    lineHeight: "19px",
                                                    color: "#000000",
                                                }}
                                            >
                                                {addSToEnd(
                                                    capitalizeFirstLetter(
                                                        courseSelected
                                                    )
                                                )}
                                            </Typography>
                                        )}

                                        <Box
                                            sx={{
                                                marginTop: "23px",
                                                gap: "40px",
                                            }}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    gap: "6px",
                                                    // width: "39%",
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "19px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    Select
                                                </Typography>

                                                {coursesInfo["courses"][
                                                    courseSelected
                                                ]["nonspecificDishesMin"] ===
                                                    coursesInfo["courses"][
                                                    courseSelected
                                                    ]["nonspecificDishesMax"] ? (
                                                    <>
                                                        <Ellipse
                                                            text={
                                                                coursesInfo[
                                                                "courses"
                                                                ][
                                                                courseSelected
                                                                ][
                                                                "nonspecificDishesMin"
                                                                ]
                                                            }
                                                            width={38}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <Ellipse
                                                            text={
                                                                coursesInfo[
                                                                "courses"
                                                                ][
                                                                courseSelected
                                                                ][
                                                                "nonspecificDishesMin"
                                                                ]
                                                            }
                                                            width={38}
                                                        />

                                                        <Typography
                                                            component="div"
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "19px",
                                                                color: "#000000",
                                                            }}
                                                        >
                                                            {" "}
                                                            to{" "}
                                                        </Typography>

                                                        <Ellipse
                                                            text={
                                                                coursesInfo[
                                                                "courses"
                                                                ][
                                                                courseSelected
                                                                ][
                                                                "nonspecificDishesMax"
                                                                ]
                                                            }
                                                            width={38}
                                                        />
                                                    </>
                                                )}

                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "19px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    Dishes
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{
                                                    gap: "6px",
                                                    // width: "39%",
                                                }}
                                            >
                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "19px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    Select
                                                </Typography>

                                                <Ellipse
                                                    text={
                                                        coursesInfo.numServings
                                                    }
                                                    width={38}
                                                />

                                                <Typography
                                                    component="div"
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "19px",
                                                        color: "#000000",
                                                    }}
                                                >
                                                    Servings
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: "23px",
                                                marginLeft: "5px",
                                                width: "100%",
                                                padding: "25px",
                                            }}
                                        >
                                            <Grid container spacing={2}>
                                                {coursesInfo["courses"][
                                                    courseSelected
                                                ]["dishes"].map(
                                                    (dishInfo, index) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                            key={index}
                                                        >
                                                            <DishCard
                                                                dishInfo={
                                                                    dishInfo
                                                                }
                                                                index={index}
                                                                coursesInfo={
                                                                    coursesInfo
                                                                }
                                                                setCoursesInfo={
                                                                    setCoursesInfo
                                                                }
                                                                courseSelected={
                                                                    courseSelected
                                                                }
                                                                isNonspecificChoosing={
                                                                    isNonspecificChoosing
                                                                }
                                                                setIsFulfilled={
                                                                    setIsFulfilled
                                                                }
                                                                currentAddonChoosing={
                                                                    currentAddonChoosing
                                                                }
                                                                setcurrentAddonChoosing={
                                                                    setcurrentAddonChoosing
                                                                }
                                                            />
                                                        </Grid>
                                                    )
                                                )}
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={5}>
                                    <Box
                                        sx={{
                                            backgroundColor: "#EDEDED",
                                            width: "472px",
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            height: "auto",
                                            borderRadius: "12px",
                                            padding: "31px 34px 31px 34px",
                                            opacity: "0px",
                                            gap: "15px",
                                        }}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                    >
                                        <Box
                                            component="img"
                                            src={menuInfo.menu.menu_image}
                                            alt="menuPicture"
                                            sx={{
                                                width: "404px",
                                                height: "265px",
                                                borderRadius: "12px",
                                            }}
                                        />

                                        <CourseSelections
                                            coursesInfo={coursesInfo}
                                            includedCourses={includedCourses}
                                            courseSelected={courseSelected}
                                            setCourseSelected={
                                                setCourseSelected
                                            }
                                            notIncludedCourses={
                                                notIncludedCourses
                                            }
                                            isNonspecificChoosing={
                                                isNonspecificChoosing
                                            }
                                            setIsNonspecificChoosing={
                                                setIsNonspecificChoosing
                                            }
                                            currentAddonChoosing={
                                                currentAddonChoosing
                                            }
                                            setcurrentAddonChoosing={
                                                setcurrentAddonChoosing
                                            }
                                        />

                                        {isFulfilled ? (
                                            <Button
                                                onClick={async () => {
                                                    onNext(
                                                        orderId,
                                                        coursesInfo
                                                    );
                                                    navigate(
                                                        PRIVATE_CHEF_PAGINATION[4]
                                                    );
                                                }}
                                                sx={{
                                                    marginTop: "10px",
                                                    width: "414px",
                                                    height: "44px",
                                                    radius: "100px",
                                                    backgroundColor: "#0F3562",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        lineHeight: "20px",
                                                        color: "#FFFFFF",
                                                    }}
                                                >
                                                    Next
                                                </Typography>
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{
                                                    marginTop: "10px",
                                                    width: "414px",
                                                    height: "44px",
                                                    radius: "100px",
                                                    backgroundColor: "#D8D8D9",
                                                    boxShadow: "none",
                                                }}
                                                disabled
                                            >
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontWeight: 500,
                                                        fontSize: "16px",
                                                        lineHeight: "20px",
                                                        color: "#78767B",
                                                    }}
                                                >
                                                    Next
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook, coursesInfo, setCoursesInfo, menu }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery("(max-width:600px)");

    const [host, tryAgain, setHostProvider] = hostHook;

    const [order, tryOrderAgain, setOrder] = orderHook;

    const [isLoading, setIsLoading] = useState(true);

    const [includedCourses, setIncludedCourses] = useState([]);
    const [notIncludedCourses, setNotIncludedCourses] = useState([]);
    // const [courseDishesSelected, setCourseDishesSelected] = useState({})

    const [courseSelected, setCourseSelected] = useState("");
    const [isNonspecificChoosing, setIsNonspecificChoosing] = useState(false);
    const [currentAddonChoosing, setcurrentAddonChoosing] = useState(0);
    const [isFulfilled, setIsFulfilled] = useState(false);

    useEffect(() => {
        if (coursesInfo && coursesInfo.courses) {
            const { included, notIncluded } = classifyCourses(
                coursesInfo.courses
            );
            setIncludedCourses(included);
            setNotIncludedCourses(notIncluded);
            if (included.length > 0) {
                if (courseSelected === "") {
                    setCourseSelected(included[0]);
                }
            } else {
                if (courseSelected === "") {
                    setCourseSelected(notIncluded[0]);
                    setIsNonspecificChoosing(true);
                }
            }

            const totalNumServingsAllSelectedCourses =
                (Object.values(coursesInfo.courses).filter(
                    (course) => course.included
                ).length +
                    coursesInfo["menu"]["num_nonspecific_courses"] +
                    Object.values(coursesInfo.courses).filter(
                        (course) =>
                            !course.included &&
                            course.beenSelected &&
                            !course.nonSpecificChosen
                    ).length) *
                coursesInfo.numServings;

            const allCoursesSelected = Object.entries(
                coursesInfo.courses
            ).filter(
                ([courseName, courseData]) =>
                    courseData.beenSelected || courseData.included
            );

            const totalNumServingsAllSelectedCoursesSelected = Object.values(
                coursesInfo.courses
            )
                .filter((course) => course.beenSelected || course.included)
                .reduce(
                    (total, course) =>
                        total + (course.numServingsSelected || 0),
                    0
                );

            const isNumDishSelectedSatisfiable = allCoursesSelected.every(
                ([courseName, courseData]) => {
                    const selectedDishesCount = courseData.dishes.filter(
                        (dish) => dish.numServingsSelected > 0
                    ).length;
                    return (
                        selectedDishesCount >=
                            courseData.nonspecificDishesMin &&
                        selectedDishesCount <= courseData.nonspecificDishesMax
                    );
                }
            );

            setIsFulfilled(
                totalNumServingsAllSelectedCoursesSelected ===
                    totalNumServingsAllSelectedCourses &&
                    isNumDishSelectedSatisfiable
            );

            const totalNumNonspecificCoursesSelected = Object.values(
                coursesInfo.courses
            ).filter(
                (course) =>
                    course.beenSelected &&
                    !course.included &&
                    course.nonSpecificChosen
            ).length;

            if (
                totalNumNonspecificCoursesSelected >=
                    coursesInfo["menu"]["num_nonspecific_courses"] &&
                currentAddonChoosing === 0
            ) {
                setcurrentAddonChoosing(1);
            } else if (
                totalNumNonspecificCoursesSelected <
                coursesInfo["menu"]["num_nonspecific_courses"]
            ) {
                setcurrentAddonChoosing(0);
            }
            // setCourseDishesSelected(coursesInfo.courses)
            setIsLoading(false);
        }
    }, [coursesInfo]);

    return (
        <>
            {isLoading ? (
                <LoadingIndicator />
            ) : isMobile ? (
                <PrivateChefSelectionsMobileUI
                    orderId={order.order_id}
                    hostInfo={host}
                    coursesInfo={coursesInfo}
                    setCoursesInfo={setCoursesInfo}
                    includedCourses={includedCourses}
                    notIncludedCourses={notIncludedCourses}
                    courseSelected={courseSelected}
                    setCourseSelected={setCourseSelected}
                    isNonspecificChoosing={isNonspecificChoosing}
                    setIsNonspecificChoosing={setIsNonspecificChoosing}
                    isFulfilled={isFulfilled}
                    setIsFulfilled={setIsFulfilled}
                    navigate={navigate}
                    currentAddonChoosing={currentAddonChoosing}
                    setcurrentAddonChoosing={setcurrentAddonChoosing}
                />
            ) : (
                <PrivateChefSelectionsDesktopUI
                    orderId={order.order_id}
                    hostInfo={host}
                    menuInfo={menu}
                    coursesInfo={coursesInfo}
                    setCoursesInfo={setCoursesInfo}
                    includedCourses={includedCourses}
                    notIncludedCourses={notIncludedCourses}
                    courseSelected={courseSelected}
                    setCourseSelected={setCourseSelected}
                    isNonspecificChoosing={isNonspecificChoosing}
                    setIsNonspecificChoosing={setIsNonspecificChoosing}
                    isFulfilled={isFulfilled}
                    setIsFulfilled={setIsFulfilled}
                    navigate={navigate}
                    currentAddonChoosing={currentAddonChoosing}
                    setcurrentAddonChoosing={setcurrentAddonChoosing}
                />
            )}
        </>
    );
};

const PrivateChefSelections = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    const [coursesInfo, courseTryLoad, setCoursesInfo] = useCoursesInfo(order);

    const [menu, setMenu] = useCurrentMenu();

    useEffect(() => {
        if (order) {
            courseTryLoad();
        }
    }, [order?.order_id]);

    // // Since guestTryLoad is useCallback() with a dependency on what is essentially order?.order_id,
    // // this function will reload when the ID changes.
    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);
    return (
        <Loading
            fetchResult={mergeMultipleResults(order, host, coursesInfo)}
            onTryAgain={orderTryLoad}
            renderChildren={() => (
                <Loaded
                    orderHook={orderHook}
                    hostHook={hostHook}
                    coursesInfo={coursesInfo}
                    setCoursesInfo={setCoursesInfo}
                    menu={menu}
                />
            )}
        />
    );
};

export default PrivateChefSelections;
