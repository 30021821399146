import { AppAppBar, AppCard } from "../components/UI"
import { useNavigate } from "react-router-dom";
import { MASSAGE_PAGINATION, PRIVATE_CHEF_PAGINATION, debugImage } from "../util";

const ServiceSelectionPage = () => {
    const navigate = useNavigate();
    return <>
        <AppAppBar />
        <AppCard
            imageVariant="fullWidth"
            onClick={() => navigate(MASSAGE_PAGINATION[0])}
            image={debugImage(400, 200)}
            title="Massage"
            subtitle="Lorem ipsum"
        />
        <AppCard
            imageVariant="fullWidth"
            onClick={() => navigate(PRIVATE_CHEF_PAGINATION[0])}
            image={debugImage(400, 200)}
            title="Private Chef"
            subtitle="Lorem ipsum"
        />
    </>;
}
export default ServiceSelectionPage;
