import React, { useState, useEffect, createContext, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useNavigate,
    Outlet,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import "./App.css";
import { djangoUrl } from "./urls";
import {
    CurrentGuestProvider,
    CurrentRecipientsProvider,
    CurrentServiceOrderProvider,
    CurrentMenuProvider,
    MASSAGE_PAGINATION,
    PRIVATE_CHEF_PAGINATION,
    SnackbarProvider,
    CurrentHostProvider,
    secret,
    useCurrentServiceOrder,
    CSRFProvider,
} from "./util";

import GoogleLogin from "./pages/GoogleLogin";
import ProviderRequest from "./pages/ProviderRequest";
import DebugPage from "./pages/DebugPage";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import ServiceSelectionPage from "./pages/ServiceSelectionPage";
import MassageGuestCountPage from "./pages/MassageGuestCountPage";
import MassageTypeLengthTherapistPage from "./pages/MassageTypeLengthTherapistPage";
import MassageCalendar from "./pages/MassageCalendar";
import MassageGuestRecipientInfoPage from "./pages/MassageGuestRecipientInfoPage";
import MassageRequestDetailsPage from "./pages/MassageRequestDetailsPage";
import GuestFacingWebsite from "./pages/Listing";
import PrivateChefBld from "./pages/PrivateChefBld";
import PrivateChefSelections from "./pages/PrivateChefSelections";
import PrivateChefCalendar from "./pages/PrivateChefCalendar";
import PrivateChefTimes from "./pages/PrivateChefTimes";
import CheckoutForm from "./pages/CheckoutForm";
import ReturnPage from "./pages/ReturnPage";
import Policies from "./pages/Policies";
import { Loading } from "./components/UI";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PrivateChefGuestRecipientInfo from "./pages/PrivateChefGuestRecipientInfo";
import PrivateChefRequestDetails from "./pages/PrivateChefRequestDetails";
import InvalidOrder from "./pages/InvalidOrder";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

/**
 * The Stripe publishable key. SECURITY WARNING: This is not the secret key.
 */

const PrivateChefProviderWrapper = () => (
    <CurrentMenuProvider>
        <Outlet />
    </CurrentMenuProvider>
);

const Home = () => {
    const navigate = useNavigate();
    const [currentServiceOrder, tryLoadServiceOrder] = useCurrentServiceOrder();
    const renderChildren = () => {
        return (
            <>
                {/* <GuestFacingWebsite /> */}
                Welcome to Nexus! Your order ID is{" "}
                {currentServiceOrder.order_id}.
                <button onClick={() => navigate("/stripe/checkout")}>
                    Navigate to checkout
                </button>
                <button onClick={() => navigate("/provider/login")}>
                    Navigate to providerlogin
                </button>
                <button onClick={() => navigate("/service-selection")}>
                    Navigate to service selection
                </button>
            </>
        );
    };

    return (
        <Loading
            renderChildren={renderChildren}
            fetchResult={currentServiceOrder}
            onTryAgain={tryLoadServiceOrder}
        />
    );
};

// The theme is composed with multiple steps because some theme options depend on other theme options.
let theme = createTheme({
    palette: {
        mode: "light",
        type: "light",
        primary: {
            main: "#0C2E55",
        },
        background: {
            default: "#F2F2F2",
        },
        mutedButton: {
            main: "#FFFFFF",
        },
        mutedButtonSelected: {
            main: "#DDDDDD",
        },
    },
});
theme = createTheme(theme, {
    components: {
        MuiButton: {
            defaultProps: {
                size: "medium",
                variant: "contained",
            },
            styleOverrides: {
                root: {
                    borderRadius: "999px",
                    textTransform: "none" /* remove uppercase */,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "outlined",
                margin: "normal",
                InputProps: {
                    style: {
                        borderRadius: 32,
                        backgroundColor: "#FFFFFF",
                        boxShadow: theme.shadows[2],
                    },
                    className:
                        "MuiPaper-root MuiPaper-elevation MuiPaper-elevation24",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12, // TODO: use theme.shape.borderRadius
                },
            },
        },
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 12, // TODO: use theme.shape.borderRadius
                },
            },
        },
    },
    typography: {
        h1: {
            fontSize: "1.5rem",
            marginBottom: 16,
        },
        h2: {
            fontSize: "1.2rem",
            marginBottom: 16,
            fontWeight: "bold", // TODO: make semibold
        },
        h3: {
            fontSize: "1.1rem",
            marginBottom: 16,
        },
    },
});

const App = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="App">
                    <SnackbarProvider>
                        <CSRFProvider>
                            <Router>
                                <CurrentServiceOrderProvider>
                                    <CurrentHostProvider>
                                        <CurrentGuestProvider>
                                            <CurrentRecipientsProvider>
                                                <CurrentMenuProvider>
                                                    <Routes>
                                                        <Route
                                                            path="/"
                                                            element={<Home />}
                                                        />

                                                        <Route
                                                            path="/invalid-order"
                                                            element={
                                                                <InvalidOrder />
                                                            }
                                                        />

                                                        <Route
                                                            path="/provider/login"
                                                            element={
                                                                <GoogleLogin />
                                                            }
                                                        />
                                                        <Route
                                                            path="/requestprovider/:uniqueId"
                                                            element={
                                                                <ProviderRequest />
                                                            }
                                                        />
                                                        <Route
                                                            path="/listing/:uniqueId"
                                                            element={
                                                                <GuestFacingWebsite />
                                                            }
                                                        />
                                                        <Route
                                                            path="/debug"
                                                            element={
                                                                <DebugPage />
                                                            }
                                                        />
                                                        <Route
                                                            path="/policies"
                                                            element={
                                                                <Policies />
                                                            }
                                                        />
                                                        <Route
                                                            path="/stripe/return"
                                                            element={
                                                                <ReturnPage />
                                                            }
                                                        />

                                                        <Route
                                                            element={
                                                                <ProtectedRoutes />
                                                            }
                                                        >
                                                            <Route
                                                                path="/stripe/checkout"
                                                                element={
                                                                    <CheckoutForm />
                                                                }
                                                            />
                                                            <Route
                                                                path="/service-selection"
                                                                element={
                                                                    <ServiceSelectionPage />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    MASSAGE_PAGINATION[0]
                                                                }
                                                                element={
                                                                    <MassageGuestCountPage />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    MASSAGE_PAGINATION[1]
                                                                }
                                                                element={
                                                                    <MassageTypeLengthTherapistPage />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    MASSAGE_PAGINATION[2]
                                                                }
                                                                element={
                                                                    <MassageCalendar />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    MASSAGE_PAGINATION[3]
                                                                }
                                                                element={
                                                                    <MassageGuestRecipientInfoPage />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    MASSAGE_PAGINATION[4]
                                                                }
                                                                element={
                                                                    <MassageRequestDetailsPage />
                                                                }
                                                            />
                                                            <Route
                                                                element={
                                                                    <PrivateChefProviderWrapper />
                                                                }
                                                            >
                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[0]
                                                                    }
                                                                    element={
                                                                        <PrivateChefBld />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[1]
                                                                    }
                                                                    element={
                                                                        <PrivateChefCalendar />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[2]
                                                                    }
                                                                    element={
                                                                        <PrivateChefTimes />
                                                                    }
                                                                />

                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[3]
                                                                    }
                                                                    element={
                                                                        <PrivateChefSelections />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[4]
                                                                    }
                                                                    element={
                                                                        <PrivateChefGuestRecipientInfo />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        PRIVATE_CHEF_PAGINATION[5]
                                                                    }
                                                                    element={
                                                                        <PrivateChefRequestDetails />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                    </Routes>
                                                </CurrentMenuProvider>
                                            </CurrentRecipientsProvider>
                                        </CurrentGuestProvider>
                                    </CurrentHostProvider>
                                </CurrentServiceOrderProvider>
                            </Router>
                        </CSRFProvider>
                    </SnackbarProvider>
                </div>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
