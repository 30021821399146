import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    Divider,
    IconButton,
    Paper,
    Stack,
    Typography,
    useMediaQuery,
    Grid,
} from "@mui/material";
import {
    AppDotPagination,
    Title,
    MobileTitle,
    AppDotPaginationwBack,
    Loading,
    HeaderFooter,
    PrivateChefMobileDotPagination,
} from "../components/UI";
import {
    MASSAGE_GENDER_REQUESTED,
    MASSAGE_PAGINATION,
    MASSAGE_TYPE_REQUESTED,
    mergeMultipleResults,
    toDateString,
    toTimeIntervalString,
    useCurrentGuest,
    useCurrentRecipients,
    useCurrentServiceOrder,
    useCurrentHost,
    djangoEndpointPromise,
    capitalizeFirstLetter,
} from "../util";
import logo from "../assets/ellipse.png";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/CreateOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect } from "react";
import {
    LINK_TO_CANCELLATION_POLICY,
    LINK_TO_SATISFACTION_GUARANTEE,
} from "../urls";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import massagePhoto from "../assets/massage.png";

dayjs.extend(utc);
dayjs.extend(timezone);

const StackVariant = ({ children }) => {
    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {children}
        </Stack>
    );
};

const Item = ({ left, right, rightFontWeight }) => {
    return (
        <StackVariant>
            {left && <Typography variant="body1">{left}</Typography>}
            <Typography variant="body1" fontWeight={rightFontWeight}>
                {right}
            </Typography>
        </StackVariant>
    );
};

const ItemCard = ({ heading, children, onEdit }) => {
    return (
        <Card
            sx={{
                width: "100%",
            }}
        >
            <CardContent>
                <StackVariant>
                    <Typography variant="h2" marginBottom={0}>
                        {heading}
                    </Typography>
                    <IconButton aria-label="Edit" onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </StackVariant>
                {children}
            </CardContent>
        </Card>
    );
};

const RecipientList = ({ recipients }) => {
    return (
        <>
            {recipients.map((recipient, index) => (
                <>
                    <Item
                        key={recipient.person_id}
                        left={<Subtitle subtitle={`Guest ${index + 1}`} />}
                        right={
                            <Value
                                value={`${capitalizeFirstLetter(
                                    recipient.first_name
                                )} ${capitalizeFirstLetter(
                                    recipient.last_name
                                )}`}
                            />
                        }
                    />

                    {index + 1 < recipients.length && <Divider />}
                </>
            ))}
        </>
    );
};

const CardTitle = ({ title }) => {
    return (
        <span
            style={{
                color: "#000000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "19.36px",
            }}
        >
            {title}
        </span>
    );
};

const Subtitle = ({ subtitle }) => {
    return (
        <span
            style={{
                color: "#717171",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "19.36px",
            }}
        >
            {subtitle}
        </span>
    );
};

const Value = ({ value }) => {
    return (
        <span
            style={{
                color: "#000000",
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "19.36px",
            }}
        >
            {value}
        </span>
    );
};

const MassageRequestDetailsMobileUI = ({
    orderProps,
    hostProps,
    guestProps,
    navigationProps,
    dateProps,
    recipientProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { guest } = guestProps;

    const { navigate } = navigationProps;

    const { startDate, endDate } = dateProps;

    const { recipientsInfo, recipientsElements } = recipientProps;

    const queryString = new URLSearchParams({ order_id: order.order_id }).toString();
    const policiesUrl = `${window.location.origin}/policies?${queryString}`;

    console.log(policiesUrl);

    const Mobile = useMediaQuery("(max-width:600px)");
    const Large = useMediaQuery("(min-width:1400px)");
    const minWidthValue = Mobile ? "0px" : "1050px";
    const backgroundColor = Mobile ? "#F2F2F2" : "white";
    const padding = Large ? "163.63px" : "5px";

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: minWidthValue,
                width: "100%",
                overflowX: "auto",
                scrollBehavior: "smooth",
                background: backgroundColor,
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                <Box
                    sx={{
                        bgcolor: "#F2F2F2",
                        flexGrow: 1,
                        width: "100%",
                        height: "100%",
                        overflowY: "auto",
                        paddingBottom: "23px",
                        marginBottom: "0px",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing="15.96px"
                        sx={{
                            marginTop: "5px",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <PrivateChefMobileDotPagination
                            page={4}
                            pagination={MASSAGE_PAGINATION}
                        />
                        <MobileTitle title="Request Details" />
                        <Stack
                            direction="column"
                            spacing="12px"
                            sx={{ marginTop: "12px", width: "92%" }}
                        >
                            <img
                                src={massagePhoto}
                                alt="Face Massage"
                                style={{
                                    width: "100%",
                                    borderRadius: "12px",
                                }}
                            />
                            <ItemCard
                                heading={
                                    <CardTitle title="Booking Information" />
                                }
                                onEdit={() =>
                                    navigate(`/listing/${hostInfo.unique_url}`)
                                }
                            >
                                <Item
                                    left={<Subtitle subtitle="Massage" />}
                                    right={
                                        <Value
                                            value={
                                                order.massage_recipients === 2
                                                    ? "Couple's"
                                                    : "Individual"
                                            }
                                        />
                                    }
                                />
                            </ItemCard>
                            <ItemCard
                                heading={<CardTitle title="Type of Massage" />}
                                onEdit={() => navigate(MASSAGE_PAGINATION[1])}
                            >
                                <Item
                                    left={<Subtitle subtitle="Massage Type" />}
                                    right={
                                        <Value
                                            value={
                                                MASSAGE_TYPE_REQUESTED.titles[
                                                    MASSAGE_TYPE_REQUESTED.options.indexOf(
                                                        order.massage_type_requested
                                                    )
                                                ]
                                            }
                                        />
                                    }
                                />
                            </ItemCard>
                            <ItemCard
                                heading={<CardTitle title="Preferences" />}
                                onEdit={() => navigate(MASSAGE_PAGINATION[1])}
                            >
                                <Stack direction="column" spacing="15px">
                                    <Item
                                        left={
                                            <Subtitle subtitle="Massage Therapist Gender" />
                                        }
                                        right={
                                            <Value
                                                value={
                                                    MASSAGE_GENDER_REQUESTED
                                                        .titles[
                                                        MASSAGE_GENDER_REQUESTED.options.indexOf(
                                                            order.massage_gender_requested
                                                        )
                                                    ]
                                                }
                                            />
                                        }
                                    />
                                    <Item
                                        left={
                                            <Subtitle subtitle="Length of Massage" />
                                        }
                                        right={
                                            <Value
                                                value={`${order.massage_duration_requested} minutes`}
                                            />
                                        }
                                    />
                                </Stack>
                            </ItemCard>
                            <ItemCard
                                heading={<CardTitle title="Date & Time" />}
                                onEdit={() => navigate(MASSAGE_PAGINATION[2])}
                            >
                                <Stack direction="column" spacing="15px">
                                    <Item
                                        left={<Subtitle subtitle="Date" />}
                                        right={
                                            <Value
                                                value={toDateString(startDate)}
                                            />
                                        }
                                    />
                                    <Item
                                        left={<Subtitle subtitle="Time" />}
                                        right={
                                            <Value
                                                value={toTimeIntervalString(
                                                    startDate,
                                                    endDate
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </ItemCard>

                            <ItemCard
                                heading={
                                    <CardTitle title="Contact Information" />
                                }
                                onEdit={() => navigate(MASSAGE_PAGINATION[3])}
                            >
                                <Stack direction="column" spacing="15px">
                                    <Item
                                        left={<Subtitle subtitle="Guest" />}
                                        right={
                                            <Value
                                                value={`${capitalizeFirstLetter(
                                                    guest.first_name
                                                )} ${capitalizeFirstLetter(
                                                    guest.last_name
                                                )}`}
                                            />
                                        }
                                    />
                                    <Item
                                        left={<Subtitle subtitle="Email" />}
                                        right={<Value value={guest.email} />}
                                    />
                                    <Item
                                        left={
                                            <Subtitle subtitle="Phone Number" />
                                        }
                                        right={
                                            <Value value={guest.phone_number} />
                                        }
                                    />
                                </Stack>
                            </ItemCard>
                            <ItemCard
                                heading={<CardTitle title="Guests" />}
                                onEdit={() => navigate(MASSAGE_PAGINATION[3])}
                            >
                                <Stack direction="column" spacing="15px">
                                    <RecipientList
                                        recipients={recipientsElements}
                                    />
                                </Stack>
                            </ItemCard>
                            <ItemCard
                                heading={
                                    <CardTitle title="Additional Instructions" />
                                }
                                onEdit={() => navigate(MASSAGE_PAGINATION[3])}
                            >
                                <Item
                                    left={
                                        <Subtitle
                                            subtitle={
                                                order.special_requests === ""
                                                    ? "None"
                                                    : capitalizeFirstLetter(
                                                          order.special_requests
                                                      )
                                            }
                                        />
                                    }
                                />
                            </ItemCard>

                            <Stack direction="column" spacing="16px">
                                <Card
                                    sx={{
                                        width: "100%",
                                        height: "164px",
                                    }}
                                >
                                    <CardContent>
                                        <Stack
                                            direction="column"
                                            spacing="11px"
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19.36px",
                                                    textAlign: "left",
                                                    color: "black",
                                                    //marginBottom: "17px",
                                                }}
                                            >
                                                Order summary
                                            </Typography>
                                            <Item
                                                left={
                                                    <span
                                                        style={{
                                                            color: "#717171",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        Subtotal
                                                    </span>
                                                }
                                                right={
                                                    <span
                                                        style={{
                                                            color: "black",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        {"$" +
                                                            order.price_total}
                                                    </span>
                                                }
                                            />
                                            <Item
                                                left={
                                                    <span
                                                        style={{
                                                            color: "#717171",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        Tax
                                                    </span>
                                                }
                                                right={
                                                    <span
                                                        style={{
                                                            color: "black",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "500",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        {"$" +
                                                            (
                                                                order.price_total *
                                                                0.0725
                                                            ).toFixed(2)}
                                                    </span>
                                                }
                                            />
                                            <Divider />
                                            <Item
                                                left={
                                                    <span
                                                        style={{
                                                            color: "#1D1B20",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        Total
                                                    </span>
                                                }
                                                right={
                                                    <span
                                                        style={{
                                                            color: "black",
                                                            fontFamily: "Inter",
                                                            fontSize: "14px",
                                                            fontWeight: "700",
                                                            lineHeight:
                                                                "16.94px",
                                                        }}
                                                    >
                                                        {"$" +
                                                            (
                                                                order.price_total *
                                                                1.0725
                                                            ).toFixed(2)}
                                                    </span>
                                                }
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "19.36px",
                                        textAlign: "left",
                                    }}
                                >
                                    Policies
                                </Typography>
                                <Stack
                                    direction="column"
                                    spacing="12px"
                                    sx={{ width: "100%" }}
                                >
                                    <ButtonBase
                                        component="button"
                                        onClick={() =>
                                            window.open(
                                                policiesUrl,
                                                "_blank"
                                            )
                                        }
                                    >
                                        <Paper
                                            sx={{
                                                padding: 1,
                                                paddingLeft: 2,
                                                width: "100%",
                                            }}
                                        >
                                            <StackVariant>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Satisfaction guarantee
                                                </Typography>
                                                <ChevronRightIcon />
                                            </StackVariant>
                                        </Paper>
                                    </ButtonBase>
                                    <ButtonBase
                                        component="button"
                                        onClick={() =>
                                            window.open(
                                                policiesUrl,
                                                "_blank"
                                            )
                                        }
                                    >
                                        <Paper
                                            sx={{
                                                padding: 1,
                                                paddingLeft: 2,
                                                width: "100%",
                                            }}
                                        >
                                            <StackVariant>
                                                <Typography
                                                    sx={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        lineHeight: "16.94px",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Cancellation policy
                                                </Typography>
                                                <ChevronRightIcon />
                                            </StackVariant>
                                        </Paper>
                                    </ButtonBase>
                                </Stack>
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        lineHeight: "19.36px",
                                        textAlign: "left",
                                    }}
                                >
                                    Your payment method won't be charged until
                                    your service is confirmed.
                                </Typography>
                                <Button
                                    onClick={() => {
                                        const service_type = 1;

                                        setOrder((prevOrder) => {
                                            const updatedOrder = {
                                                ...prevOrder,
                                                service: service_type,
                                            };

                                            djangoEndpointPromise(
                                                "set_service_type",
                                                {
                                                    order_id:
                                                        updatedOrder.order_id,
                                                    service_id: service_type,
                                                }
                                            )
                                                .then((response) => {
                                                    navigate(
                                                        "/stripe/checkout"
                                                    );
                                                })
                                                .catch((error) => {
                                                    console.error(
                                                        "Error calling endpoint:",
                                                        error
                                                    );
                                                });

                                            return updatedOrder;
                                        });
                                    }}
                                    sx={{
                                        width: "100%",
                                        height: "44px",
                                        gap: "8px",
                                        borderRadius: "100px",
                                        opacity: "0px",
                                        fontFamily: "Inter",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        letterSpacing: "0.10000000149011612px",
                                        textAlign: "center",
                                    }}
                                >
                                    Request booking
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </HeaderFooter>
        </Box>
    );
};

const MassageRequestDetailsDesktopUI = ({
    orderProps,
    hostProps,
    guestProps,
    navigationProps,
    dateProps,
    recipientProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { guest } = guestProps;

    const { navigate } = navigationProps;

    const { startDate, endDate } = dateProps;

    const { recipientsInfo, recipientsElements } = recipientProps;

    const queryString = new URLSearchParams({ order_id: order.order_id }).toString();
    const policiesUrl = `${window.location.origin}/policies?${queryString}`;

    console.log(policiesUrl);

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: "1512px",
                width: "100%",
                scrollBehavior: "smooth",
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                <Box
                    sx={{
                        bgcolor: "#FFFFFF",
                        paddingBottom: "125px",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: "1512px",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "97px",
                                marginLeft: "100px",
                                alignItems: "flex-start",
                            }}
                        >
                            <Title title="Request Details" />
                            <AppDotPaginationwBack
                                page={4}
                                pagination={MASSAGE_PAGINATION}
                            />

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    width: "1428px",
                                }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={7}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            padding: "30px 60px",
                                            gap: "15px",
                                            width: "798px",
                                            background: "#EDEDED",
                                            borderRadius: "20px",
                                        }}
                                    >
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Booking Information" />
                                            }
                                            onEdit={() =>
                                                navigate(
                                                    `/listing/${hostInfo.unique_url}`
                                                )
                                            }
                                        >
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Massage" />
                                                }
                                                right={
                                                    <Value
                                                        value={
                                                            order.massage_recipients ===
                                                            2
                                                                ? "Couple's"
                                                                : "Individual"
                                                        }
                                                    />
                                                }
                                            />
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Type of Massage" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[1])
                                            }
                                        >
                                            <Item
                                                left={
                                                    <Subtitle subtitle="Massage Type" />
                                                }
                                                right={
                                                    <Value
                                                        value={
                                                            MASSAGE_TYPE_REQUESTED
                                                                .titles[
                                                                MASSAGE_TYPE_REQUESTED.options.indexOf(
                                                                    order.massage_type_requested
                                                                )
                                                            ]
                                                        }
                                                    />
                                                }
                                            />
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Preferences" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[1])
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                spacing="15px"
                                            >
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Massage Therapist Gender" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={
                                                                MASSAGE_GENDER_REQUESTED
                                                                    .titles[
                                                                    MASSAGE_GENDER_REQUESTED.options.indexOf(
                                                                        order.massage_gender_requested
                                                                    )
                                                                ]
                                                            }
                                                        />
                                                    }
                                                />
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Length of Massage" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={`${order.massage_duration_requested} minutes`}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Date & Time" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[2])
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                spacing="15px"
                                            >
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Date" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={toDateString(
                                                                startDate
                                                            )}
                                                        />
                                                    }
                                                />
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Time" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={toTimeIntervalString(
                                                                startDate,
                                                                endDate
                                                            )}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Contact Information" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[3])
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                spacing="15px"
                                            >
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Guest" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={`${capitalizeFirstLetter(
                                                                guest.first_name
                                                            )} ${capitalizeFirstLetter(
                                                                guest.last_name
                                                            )}`}
                                                        />
                                                    }
                                                />
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Email" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={guest.email}
                                                        />
                                                    }
                                                />
                                                <Item
                                                    left={
                                                        <Subtitle subtitle="Phone Number" />
                                                    }
                                                    right={
                                                        <Value
                                                            value={
                                                                guest.phone_number
                                                            }
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Guests" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[3])
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                spacing="15px"
                                            >
                                                <RecipientList
                                                    recipients={
                                                        recipientsElements
                                                    }
                                                />
                                            </Stack>
                                        </ItemCard>
                                        <ItemCard
                                            heading={
                                                <CardTitle title="Additional Instructions" />
                                            }
                                            onEdit={() =>
                                                navigate(MASSAGE_PAGINATION[3])
                                            }
                                        >
                                            <Item
                                                left={
                                                    <Subtitle
                                                        subtitle={
                                                            order.special_requests ===
                                                            ""
                                                                ? "None"
                                                                : capitalizeFirstLetter(
                                                                      order.special_requests
                                                                  )
                                                        }
                                                    />
                                                }
                                            />
                                        </ItemCard>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={5}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            padding: "31px 34px",
                                            gap: "16px",
                                            isolation: "isolate",
                                            width: "472px",

                                            background: "#EDEDED",
                                            borderRadius: "12px",
                                        }}
                                    >
                                        <Stack
                                            direction="column"
                                            spacing="16px"
                                        >
                                            <img
                                                src={massagePhoto}
                                                alt="Face Massage"
                                                style={{
                                                    width: "100%",
                                                    height: "317px",
                                                    borderRadius: "12px",
                                                }}
                                            />
                                            <Card
                                                sx={{
                                                    width: "100%",
                                                    height: "164px",
                                                }}
                                            >
                                                <CardContent>
                                                    <Stack
                                                        direction="column"
                                                        spacing="11px"
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    "Inter",
                                                                fontSize:
                                                                    "16px",
                                                                fontWeight:
                                                                    "500",
                                                                lineHeight:
                                                                    "19.36px",
                                                                textAlign:
                                                                    "left",
                                                                color: "black",
                                                                //marginBottom: "17px",
                                                            }}
                                                        >
                                                            Order summary
                                                        </Typography>
                                                        <Item
                                                            left={
                                                                <span
                                                                    style={{
                                                                        color: "#717171",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "400",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    Subtotal
                                                                </span>
                                                            }
                                                            right={
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "500",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    {"$" +
                                                                        order.price_total}
                                                                </span>
                                                            }
                                                        />
                                                        <Item
                                                            left={
                                                                <span
                                                                    style={{
                                                                        color: "#717171",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "400",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    Tax
                                                                </span>
                                                            }
                                                            right={
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "500",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    {"$" +
                                                                        (
                                                                            order.price_total *
                                                                            0.0725
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                </span>
                                                            }
                                                        />
                                                        <Divider />
                                                        <Item
                                                            left={
                                                                <span
                                                                    style={{
                                                                        color: "#1D1B20",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "400",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    Total
                                                                </span>
                                                            }
                                                            right={
                                                                <span
                                                                    style={{
                                                                        color: "black",
                                                                        fontFamily:
                                                                            "Inter",
                                                                        fontSize:
                                                                            "14px",
                                                                        fontWeight:
                                                                            "700",
                                                                        lineHeight:
                                                                            "16.94px",
                                                                    }}
                                                                >
                                                                    {"$" +
                                                                        (
                                                                            order.price_total *
                                                                            1.0725
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                </span>
                                                            }
                                                        />
                                                    </Stack>
                                                </CardContent>
                                            </Card>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "19.36px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Policies
                                            </Typography>
                                            <Stack
                                                direction="column"
                                                spacing="12px"
                                                sx={{ width: "100%" }}
                                            >
                                                <ButtonBase
                                                    component="button"
                                                    onClick={() =>
                                                        window.open(
                                                            policiesUrl,
                                                            "_blank"
                                                        )
                                                    }
                                                >
                                                    <Paper
                                                        sx={{
                                                            padding: 1,
                                                            paddingLeft: 2,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <StackVariant>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "400",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                Satisfaction
                                                                Guarantee
                                                            </Typography>
                                                            <ChevronRightIcon />
                                                        </StackVariant>
                                                    </Paper>
                                                </ButtonBase>
                                                <ButtonBase
                                                    component="button"
                                                    onClick={() =>
                                                        window.open(
                                                            policiesUrl,
                                                            "_blank"
                                                        )
                                                    }
                                                >
                                                    <Paper
                                                        sx={{
                                                            padding: 1,
                                                            paddingLeft: 2,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <StackVariant>
                                                            <Typography
                                                                sx={{
                                                                    fontFamily:
                                                                        "Inter",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "400",
                                                                    lineHeight:
                                                                        "16.94px",
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                Cancellation
                                                                Policy
                                                            </Typography>
                                                            <ChevronRightIcon />
                                                        </StackVariant>
                                                    </Paper>
                                                </ButtonBase>
                                            </Stack>
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "700",
                                                    lineHeight: "19.36px",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Your payment method won't be
                                                charged until your service is
                                                confirmed.
                                            </Typography>
                                            <Button
                                                onClick={() => {
                                                    const service_type = 1;

                                                    setOrder((prevOrder) => {
                                                        const updatedOrder = {
                                                            ...prevOrder,
                                                            service:
                                                                service_type,
                                                        };

                                                        djangoEndpointPromise(
                                                            "set_service_type",
                                                            {
                                                                order_id:
                                                                    updatedOrder.order_id,
                                                                service_id:
                                                                    service_type,
                                                            }
                                                        )
                                                            .then(
                                                                (response) => {
                                                                    navigate(
                                                                        "/stripe/checkout"
                                                                    );
                                                                }
                                                            )
                                                            .catch((error) => {
                                                                console.error(
                                                                    "Error calling endpoint:",
                                                                    error
                                                                );
                                                            });

                                                        return updatedOrder;
                                                    });
                                                }}
                                                sx={{
                                                    width: "100%",
                                                    height: "44px",
                                                    gap: "8px",
                                                    borderRadius: "100px",
                                                    opacity: "0px",
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    fontWeight: "500",
                                                    lineHeight: "20px",
                                                    letterSpacing:
                                                        "0.10000000149011612px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Request booking
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </Box>
            </HeaderFooter>
        </Box>
    );
};

const Loaded = ({ orderHook, guestHook, recipientsHook, hostInfo }) => {
    const [order, _orderTryLoad, setOrder] = orderHook;
    const [guest, _guestTryLoad, setGuest] = guestHook;
    const [recipientsInfo, _recipientsTryLoad, setRecipients] = recipientsHook;
    const navigate = useNavigate();
    const startDate = dayjs.utc(order.time_requested).tz(order.timezone);

    const endDate = startDate.add(order.duration_requested, "minute");

    const recipientsElements = recipientsInfo.include_guest_as_recipient
        ? [guest, ...recipientsInfo.recipients]
        : recipientsInfo.recipients;

    const orderProps = {
        orderHook,
    };

    const hostProps = {
        hostInfo,
    };

    const guestProps = {
        guest,
    };

    const navigationProps = {
        navigate,
    };

    const dateProps = {
        startDate,
        endDate,
    };

    const recipientProps = {
        recipientsInfo,
        recipientsElements,
    };

    const combinedProps = {
        orderProps,
        hostProps,
        guestProps,
        navigationProps,
        dateProps,
        recipientProps,
    };

    const Mobile = useMediaQuery("(max-width:600px)");

    return (
        <>
            {Mobile ? (
                <MassageRequestDetailsMobileUI {...combinedProps} />
            ) : (
                <MassageRequestDetailsDesktopUI {...combinedProps} />
            )}
        </>
    );
};

const MassageRequestDetailsPage = () => {
    const orderHook = useCurrentServiceOrder();
    const guestHook = useCurrentGuest();
    const recipientsHook = useCurrentRecipients();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [guest, guestTryLoad, _setGuest] = guestHook;
    const [recipients, recipientsTryLoad, _setRecipients] = recipientsHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        orderTryLoad();
    }, []);

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);
    // Since guestTryLoad is useCallback() with a dependency on what is essentially order?.order_id,
    // this function will reload when the ID changes.
    useEffect(() => {
        guestTryLoad();
    }, [guestTryLoad]);
    // The same applies for recipientsTryLoad.
    useEffect(() => {
        recipientsTryLoad();
    }, [recipientsTryLoad]);
    return (
        <Loading
            fetchResult={mergeMultipleResults(order, guest, recipients, host)}
            onTryAgain={orderTryLoad}
            renderChildren={() => (
                <Loaded
                    orderHook={orderHook}
                    guestHook={guestHook}
                    recipientsHook={recipientsHook}
                    hostInfo={host}
                />
            )}
        />
    );
};

export default MassageRequestDetailsPage;
