import { useNavigate } from "react-router-dom";
import {
    Title,
    MobileTitle,
    AppDotPaginationwBack,
    AppDotPagination,
    HeaderFooter,
    Loading,
    MassageCountCard,
    MassageCountCardMobile,
} from "../components/UI";
import {
    useMediaQuery,
    Typography,
    TextField,
    Button,
    IconButton,
    Stack,
    Box,
    Alert,
    InputAdornment,
    Tooltip,
    Grid,
} from "@mui/material";
import {
    MASSAGE_PAGINATION,
    djangoEndpointPromise,
    useCurrentServiceOrder,
    mergeMultipleResults,
    useCurrentHost,
} from "../util";
import { useEffect, useState } from "react";
import logo from "../assets/ellipse.png";
import HelpIcon from "@mui/icons-material/HelpOutline";
import CouplesMassagePhoto from "../assets/couplesmassage.png";
import IndivMassagePhoto from "../assets/indivmassage.png";

const MobileToolTip = () => {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <Tooltip
            title="Your reservation number is given by your host. You can often find this number on your confirmation email. If your host did not provide you with a reservation number, enter five zeroes."
            open={open}
            onClose={handleTooltipClose}
            disableHoverListener
        >
            <IconButton onClick={handleTooltipOpen}>
                <HelpIcon
                    sx={{
                        color: "#1D1B20",
                        width: "16px",
                        height: "16px",
                    }}
                />
            </IconButton>
        </Tooltip>
    );
};

const MassageCards = ({ orderHook, MASSAGE_RECIPIENTS }) => {
    const [order, tryAgain, setOrder] = orderHook;
    return (
        <>
            {MASSAGE_RECIPIENTS.options.map((option, i) => {
                return (
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <MassageCountCardMobile
                            key={i}
                            title={MASSAGE_RECIPIENTS.titles[i]}
                            subtitle={MASSAGE_RECIPIENTS.subtitles[i]}
                            selected={option === order.massage_recipients}
                            onClick={() => {
                                setOrder({
                                    ...order,
                                    massage_recipients:
                                        option === order.massage_recipients
                                            ? null
                                            : option,
                                });
                                djangoEndpointPromise(
                                    "massage/input_recipients",
                                    {
                                        order_id: order.order_id,
                                        massage_recipient_type:
                                            option === order.massage_recipients
                                                ? null
                                                : MASSAGE_RECIPIENTS.apiOptions[
                                                      i
                                                  ],
                                    }
                                );
                            }}
                            image={MASSAGE_RECIPIENTS.images[i]}
                            imageVariant="fullWidth"
                        />
                    </Grid>
                );
            })}
        </>
    );
};

const MASSAGE_RECIPIENTS = {
    options: [1, 2],
    titles: ["Individual", "Couple's"],
    subtitles: [
        "Treat yourself to some extra R&R without leaving your listing.",
        "Treat yourself to some extra R&R without leaving your listing.",
    ],
    apiOptions: ["individual", "couple"],
    images: [IndivMassagePhoto, CouplesMassagePhoto],
};

const MassageGuestCountMobileUI = ({
    orderProps,
    hostProps,
    navigationProps,
    validationProps,
    reservationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;
    const {
        validate,
        setValidate,
        errorMessages,
        isValid,
        reservationNumberError,
    } = validationProps;
    const { reservationNumberBlurred, setReservationNumberBlurred } =
        reservationProps;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "20px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "20px",
                                alignItems: "center",
                                padding: "0px 11px 0px 11px",
                            }}
                        >
                            <AppDotPagination
                                page={0}
                                pagination={MASSAGE_PAGINATION}
                                onBack={() =>
                                    navigate(`/listing/${hostInfo.unique_url}`)
                                }
                            />
                            <MobileTitle title="Who are you booking for?" />

                            <Grid container spacing={2} sx={{ width: "100%" }}>
                                <MassageCards
                                    orderHook={orderHook}
                                    MASSAGE_RECIPIENTS={MASSAGE_RECIPIENTS}
                                />
                                <Grid item xs={12} sm={12} md={8} lg={4}>
                                    <Box
                                        sx={{
                                            height: "199px",
                                            borderRadius: "12px",
                                            paddingTop: "20px",
                                        }}
                                    >
                                        <Stack direction="column">
                                            <Stack
                                                direction="row"
                                                sx={{
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "#1D1B20",
                                                    }}
                                                >
                                                    Reservation Number
                                                </Typography>
                                                <MobileToolTip />
                                            </Stack>
                                            <TextField
                                                sx={{
                                                    marginTop: "2px",
                                                    width: "100%",
                                                    borderRadius: "12px",
                                                    background: "white",
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            borderRadius:
                                                                "12px",
                                                            "& fieldset": {
                                                                borderColor:
                                                                    "#79747E",
                                                                borderWidth:
                                                                    "1px",
                                                            },
                                                        },
                                                }}
                                                error={
                                                    reservationNumberError !==
                                                    null
                                                }
                                                helperText={
                                                    reservationNumberError
                                                }
                                                FormHelperTextProps={{
                                                    sx: {
                                                        marginTop: "0px",
                                                        background: "#EDEDED",
                                                        width: "100%",
                                                        marginLeft: "0px",
                                                        paddingLeft: "19px",
                                                    },
                                                }}
                                                defaultValue={order.res_number}
                                                onBlur={(event) => {
                                                    const value =
                                                        event.target.value;
                                                    setReservationNumberBlurred(
                                                        true
                                                    );
                                                    setOrder({
                                                        ...order,
                                                        res_number: value,
                                                    });
                                                    djangoEndpointPromise(
                                                        "input_res",
                                                        {
                                                            order_id:
                                                                order.order_id,
                                                            res_number: value,
                                                        }
                                                    );
                                                }}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            #
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {validate && !isValid && (
                                                <Alert
                                                    variant="filled"
                                                    severity="error"
                                                >
                                                    {errorMessages.map(
                                                        (message) => (
                                                            <Typography
                                                                key={message}
                                                                variant="body1"
                                                                sx={{
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                {message}
                                                            </Typography>
                                                        )
                                                    )}
                                                </Alert>
                                            )}
                                            <Button
                                                disabled={!isValid}
                                                onClick={async () => {
                                                    if (isValid) {
                                                        tryAgain();
                                                        navigate(
                                                            MASSAGE_PAGINATION[1]
                                                        );
                                                    }
                                                }}
                                                sx={{
                                                    width: "100%",
                                                    height: "44px",
                                                    marginBottom: "30px",
                                                    marginTop:
                                                        reservationNumberError
                                                            ? "0px"
                                                            : "13px",
                                                }}
                                                fullWidth
                                            >
                                                Next
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const MassageGuestCountDesktopUI = ({
    orderProps,
    hostProps,
    navigationProps,
    validationProps,
    reservationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, tryAgain, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;
    const {
        validate,
        setValidate,
        errorMessages,
        isValid,
        reservationNumberError,
    } = validationProps;
    const { reservationNumberBlurred, setReservationNumberBlurred } =
        reservationProps;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    minWidth: "1512px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                    <Box
                        sx={{
                            bgcolor: "#FFFFFF",
                            paddingBottom: "125px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: "1512px",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="15.96px"
                                sx={{
                                    marginTop: "97px",
                                    marginLeft: "100px",
                                    marginRight: "100px",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Title title="Who are you booking for?" />
                                <AppDotPaginationwBack
                                    page={0}
                                    pagination={MASSAGE_PAGINATION}
                                    onBack={() =>
                                        navigate(
                                            `/listing/${hostInfo.unique_url}`
                                        )
                                    }
                                />

                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        width: "1250px",
                                    }}
                                >
                                    <MassageCards
                                        orderHook={orderHook}
                                        MASSAGE_RECIPIENTS={MASSAGE_RECIPIENTS}
                                    />
                                    <Grid item xs={12} sm={12} md={8} lg={4}>
                                        <Box
                                            sx={{
                                                width: "472px",
                                                height: "199px",
                                                background: "#EDEDED",
                                                borderRadius: "12px",

                                                paddingLeft: "30px",
                                                paddingRight: "30px",
                                                paddingTop: "20px",
                                            }}
                                        >
                                            <Stack direction="column">
                                                <Stack
                                                    direction="row"
                                                    sx={{
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: "#1D1B20",
                                                        }}
                                                    >
                                                        Reservation Number
                                                    </Typography>
                                                    <Tooltip title="Your reservation number is given by your host. You can often find this number on your confirmation email. If your host did not provide you with a reservation number, enter five zeroes.">
                                                        <IconButton>
                                                            <HelpIcon
                                                                sx={{
                                                                    color: "#1D1B20",
                                                                    width: "16px",
                                                                    height: "16px",
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                                <TextField
                                                    sx={{
                                                        marginTop: "2px",
                                                        width: "100%",
                                                        borderRadius: "12px",
                                                        background: "white",
                                                        "& .MuiOutlinedInput-root":
                                                            {
                                                                borderRadius:
                                                                    "12px",
                                                                "& fieldset": {
                                                                    borderColor:
                                                                        "#79747E",
                                                                    borderWidth:
                                                                        "1px",
                                                                },
                                                            },
                                                    }}
                                                    error={
                                                        reservationNumberError !==
                                                        null
                                                    }
                                                    helperText={
                                                        reservationNumberError
                                                    }
                                                    FormHelperTextProps={{
                                                        sx: {
                                                            marginTop: "0px",
                                                            background:
                                                                "#EDEDED",
                                                            width: "100%",
                                                            marginLeft: "0px",
                                                            paddingLeft: "19px",
                                                        },
                                                    }}
                                                    defaultValue={
                                                        order.res_number
                                                    }
                                                    onBlur={(event) => {
                                                        const value =
                                                            event.target.value;
                                                        setReservationNumberBlurred(
                                                            true
                                                        );
                                                        setOrder({
                                                            ...order,
                                                            res_number: value,
                                                        });
                                                        djangoEndpointPromise(
                                                            "input_res",
                                                            {
                                                                order_id:
                                                                    order.order_id,
                                                                res_number:
                                                                    value,
                                                            }
                                                        );
                                                    }}
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                #
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {validate && !isValid && (
                                                    <Alert
                                                        variant="filled"
                                                        severity="error"
                                                    >
                                                        {errorMessages.map(
                                                            (message) => (
                                                                <Typography
                                                                    key={
                                                                        message
                                                                    }
                                                                    variant="body1"
                                                                    sx={{
                                                                        textAlign:
                                                                            "left",
                                                                    }}
                                                                >
                                                                    {message}
                                                                </Typography>
                                                            )
                                                        )}
                                                    </Alert>
                                                )}
                                                <Button
                                                    disabled={!isValid}
                                                    onClick={async () => {
                                                        if (isValid) {
                                                            tryAgain();
                                                            navigate(
                                                                MASSAGE_PAGINATION[1]
                                                            );
                                                        }
                                                    }}
                                                    sx={{
                                                        width: "100%",
                                                        height: "44px",
                                                        marginBottom: "30px",
                                                        marginTop:
                                                            reservationNumberError
                                                                ? "0px"
                                                                : "13px",
                                                    }}
                                                    fullWidth
                                                >
                                                    Next
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [hostInfo, hostTryLoad, _setHost] = hostHook;

    const [order, tryAgain, setOrder] = orderHook;

    const navigate = useNavigate();
    const [validate, setValidate] = useState(false);

    const errorMessages = [];
    const [reservationNumberBlurred, setReservationNumberBlurred] =
        useState(false);
    let isValid = true;
    let reservationNumberError = null;
    if (!MASSAGE_RECIPIENTS.options.includes(order.massage_recipients)) {
        isValid = false;
        if (validate) {
            errorMessages.push("Select the individual or couple option.");
        }
    }
    if (order.res_number === "") {
        isValid = false;
        if (validate) {
            errorMessages.push("Enter your reservation number.");
        }
        if (reservationNumberBlurred) {
            reservationNumberError = "Please enter your reservation number";
        }
    }

    const Mobile = useMediaQuery("(max-width:600px)");

    const orderProps = {
        orderHook,
    };

    const hostProps = {
        hostInfo,
    };

    const navigationProps = {
        navigate,
    };

    const validationProps = {
        validate,
        setValidate,
        errorMessages,
        isValid,
        reservationNumberError,
    };

    const reservationProps = {
        reservationNumberBlurred,
        setReservationNumberBlurred,
    };

    const combinedProps = {
        orderProps,
        hostProps,
        navigationProps,
        validationProps,
        reservationProps,
    };

    return (
        <>
            {Mobile ? (
                <MassageGuestCountMobileUI {...combinedProps} />
            ) : (
                <MassageGuestCountDesktopUI {...combinedProps} />
            )}
        </>
    );
};

const MassageGuestCountPage = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default MassageGuestCountPage;
