import {
    Box,
    Button,
    Stack,
    Typography,
    useMediaQuery,
    Grid,
} from "@mui/material";
import {
    AppDotPagination,
    AppDotPaginationwBack,
    Title,
    Loading,
    LoadingIndicator,
    HeaderFooter,
    CustomCalendar,
    TempTimeDisplay,
    MobileTitle,
} from "../components/UI";
import { useNavigate } from "react-router-dom";
import {
    MASSAGE_PAGINATION,
    djangoEndpointPromise,
    fetchResultType,
    useCurrentServiceOrder,
    useCurrentHost,
    mergeMultipleResults,
} from "../util";
import logo from "../assets/ellipse.png";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

function useCurrentTimeslots(order, date) {
    // A null state represents the timeslots never being needed yet.
    const [timeslotsInfo, setTimeslotsInfo] = useState(null);
    const dateString = date === null ? "" : date.toISOString();
    const orderId = order.order_id;
    const tryLoad = useCallback(async () => {
        if (dateString === "") {
            return;
        }
        if (orderId === undefined) {
            setTimeslotsInfo({
                error: ["Order ID or datestring is undefined", orderId],
            });
            return;
        } else {
            setTimeslotsInfo({});
        }

        const url = `massage/retrieve_selected_massage_timeslots?order_id=${encodeURIComponent(
            orderId
        )}&date=${encodeURIComponent(dateString)}`;
        const result = await djangoEndpointPromise(url);

        if (fetchResultType(result) === "error") {
            setTimeslotsInfo(result);
            return;
        }
        try {
            if (!Array.isArray(result.data.timeslots)) {
                throw new Error("result.data.timeslots must be array");
            }
            setTimeslotsInfo(result.data);
        } catch (e) {
            setTimeslotsInfo({
                error: ["Cannot read data format", e, result],
            });
        }
    }, [dateString, orderId]);
    useEffect(() => {
        tryLoad();
    }, [tryLoad]);
    return [timeslotsInfo, tryLoad, setTimeslotsInfo];
}

const MassageCalendarMobileUI = ({
    orderProps,
    hostProps,
    navigationProps,
    timeProps,
    validationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, _tryLoadOrder, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;

    const { date, setDate, availableTimesElement } = timeProps;

    const { isValid } = validationProps;

    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    //minWidth: "400px",
                    width: "100%",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                }}
            >
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Box
                        sx={{
                            bgcolor: "#F2F2F2",
                            paddingBottom: "20px",
                            flexGrow: 1,
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="15.96px"
                            sx={{
                                marginTop: "20px",
                                alignItems: "center",
                                padding: "0px 11px 0px 11px",
                            }}
                        >
                            <AppDotPagination
                                page={2}
                                pagination={MASSAGE_PAGINATION}
                            />
                            <MobileTitle title="Select a Date" />

                            <CustomCalendar
                                timezone={order.timezone}
                                date={date}
                                setDate={setDate}
                                width={360}
                                height={366}
                                dateHeight={40}
                                borderRadius={24}
                            />
                            <Box
                                sx={{
                                    gap: "20px",
                                    opacity: "0px",
                                    width: "100%",
                                    // width: "472px",
                                    // background: "#EDED",
                                    borderRadius: "12px",
                                    // marginRight: "100px",
                                    padding: "5px",
                                }}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <Typography
                                    component="div"
                                    sx={{
                                        marginRight: "auto",
                                        fontFamily: "Inter",
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        lineHeight: "24.2px",
                                        textAlign: "left",
                                        color: "#1D1B20",
                                    }}
                                >
                                    Available Times
                                </Typography>

                                {availableTimesElement}

                                <Button
                                    disabled={!isValid}
                                    onClick={() =>
                                        navigate(MASSAGE_PAGINATION[3])
                                    }
                                    sx={{
                                        width: "100%",
                                    }}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </HeaderFooter>
            </Box>
        </>
    );
};

const MassageCalendarDesktopUI = ({
    orderProps,
    hostProps,
    navigationProps,
    timeProps,
    validationProps,
}) => {
    const { orderHook } = orderProps;

    const [order, _tryLoadOrder, setOrder] = orderHook;

    const { hostInfo } = hostProps;

    const { navigate } = navigationProps;

    const { date, setDate, availableTimesElement } = timeProps;

    const { isValid } = validationProps;

    return (
        <Box
            sx={{
                flexGrow: 1,
                minWidth: "1512px",
                width: "100%",
                scrollBehavior: "smooth",
            }}
        >
            <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                <Box
                    sx={{
                        bgcolor: "#FDFDFD",
                        paddingBottom: "125px",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                    }}
                >
                    <Stack
                        direction="column"
                        spacing="15.96px"
                        sx={{
                            marginTop: "97px",
                            marginLeft: "100px",
                            alignItems: "flex-start",
                        }}
                    >
                        <Title title="Customize your experience" />
                        <AppDotPaginationwBack
                            page={2}
                            pagination={MASSAGE_PAGINATION}
                        />
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                width: "1428px",
                            }}
                        >
                            <Grid item xs={12} sm={12} md={12} lg={7}>
                                <Box
                                    sx={{
                                        width: "798px",
                                    }}
                                >
                                    <CustomCalendar
                                        timezone={order.timezone}
                                        date={date}
                                        setDate={setDate}
                                        width={798}
                                        height={575}
                                        dateHeight={78}
                                        borderRadius={8}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <Box
                                    sx={{
                                        gap: "20px",
                                        opacity: "0px",

                                        // width: "380px",
                                        width: "472px",
                                        background: "#EDEDED",
                                        borderRadius: "12px",
                                        // marginRight: "100px",
                                        padding: "37px 35px 37px 35px",
                                    }}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <Typography
                                        component="div"
                                        sx={{
                                            marginRight: "auto",
                                            fontFamily: "Inter",
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            lineHeight: "24.2px",
                                            textAlign: "left",
                                            color: "#1D1B20",
                                        }}
                                    >
                                        Available Times
                                    </Typography>

                                    {availableTimesElement}

                                    <Button
                                        disabled={!isValid}
                                        onClick={() =>
                                            navigate(MASSAGE_PAGINATION[3])
                                        }
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>
            </HeaderFooter>
        </Box>
    );
};

const Loaded = ({ orderHook, hostHook }) => {
    const [order, _tryLoadOrder, setOrder] = orderHook;
    const [hostInfo, hostTryLoad, _setHost] = hostHook;
    const navigate = useNavigate();
    // This is the native JS date, but the DateCalendar uses dayjs dates.
    // Conversion is done in the date calendar.
    const [date, setDate] = useState(
        order.time_requested === null
            ? null
            : dayjs.utc(order.time_requested).tz(order.timezone)
    );

    const [timeslotsInfo, tryLoadTimeslots, _setTimeslotsInfo] =
        useCurrentTimeslots(order, date);

    // This is not included in order.time_requested because we only want to set that when we select a timeslot.
    const timeslotsResultType =
        timeslotsInfo === null ? null : fetchResultType(timeslotsInfo);

    let isValid = true;
    if (order.time_requested === null) {
        isValid = false;
    }

    const Mobile = useMediaQuery("(max-width:600px)");

    const availableTimesElement =
        timeslotsResultType === null ? (
            <Typography variant="body1">
                Select a date to view available times.
            </Typography>
        ) : timeslotsResultType === "loading" ? (
            <LoadingIndicator />
        ) : timeslotsResultType === "error" ? (
            <>
                <Typography variant="body1">
                    An error occurred while loading the times.
                </Typography>
                <Button onClick={() => tryLoadTimeslots()}>Try again</Button>
            </>
        ) : timeslotsInfo.timeslots.length === 0 ? (
            <>
                <Typography variant="body1">No times available.</Typography>
                <Button onClick={() => tryLoadTimeslots()}>Reload times</Button>
            </>
        ) : (
            <TempTimeDisplay
                order={order}
                setOrder={setOrder}
                timeslotsInfo={timeslotsInfo}
                date={date}
                setDate={setDate}
                isMobile={Mobile}
            />
        );

    const orderProps = {
        orderHook,
    };

    const hostProps = {
        hostInfo,
    };

    const navigationProps = {
        navigate,
    };

    const timeProps = {
        date,
        setDate,
        availableTimesElement,
    };

    const validationProps = {
        isValid,
    };

    const combinedProps = {
        orderProps,
        hostProps,
        navigationProps,
        timeProps,
        validationProps,
    };

    return (
        <>
            {Mobile ? (
                <MassageCalendarMobileUI {...combinedProps} />
            ) : (
                <MassageCalendarDesktopUI {...combinedProps} />
            )}
        </>
    );
};

const MassageCalendar = () => {
    const orderHook = useCurrentServiceOrder();
    const hostHook = useCurrentHost();
    const [order, orderTryLoad, _setOrder] = orderHook;
    const [host, hostTryLoad, _setHost] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            <Loading
                fetchResult={mergeMultipleResults(order, host)}
                onTryAgain={() => {
                    orderTryLoad();
                    hostTryLoad();
                }}
                renderChildren={() => (
                    <Loaded orderHook={orderHook} hostHook={hostHook} />
                )}
            />
        </>
    );
};

export default MassageCalendar;
