import { secret } from './util';

/**
 * I am not sure what to do for production; whether to serve on example.com and sub.example.com or just example.com/ and example.com/django.
 * 
 * Therefore, I am offering a way to change the server domain.
 */
export function djangoUrl(urlString) {
    return new URL(urlString, secret.DJANGO_URL);
}

export const LINK_TO_SATISFACTION_GUARANTEE = 'https://example.com';

export const LINK_TO_CANCELLATION_POLICY = 'https://example.com';