import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Divider,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Collapse,
    Container,
    useMediaQuery,
    Modal,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Loading, LoadingIndicator, HeaderFooter } from "../components/UI";
import {
    djangoEndpointPromise,
    fetchResultType,
    useCurrentServiceOrder,
    useCurrentHost,
    mergeMultipleResults,
    PRIVATE_CHEF_PAGINATION,
    MASSAGE_PAGINATION,
} from "../util";
import logo from "../assets/ellipse.png";
import "@fontsource/inter";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import { styled } from "@mui/material/styles";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import massagePhoto from "../assets/massage.png";
import privateChefPhoto from "../assets/privatechef.png";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Carousel from "react-material-ui-carousel";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    marginLeft: "auto",
    "& .MuiSvgIcon-root": {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
        transform: `rotate(${expand ? "180deg" : "0deg"})`,
    },
}));

const QuoteCard = ({ quoteInfo }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#F2F2F2",
                }}
                display="flex"
                flexDirection="column"
            >
                <Typography
                    component="div"
                    sx={{
                        marginTop: "53px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "32px",
                        letterSpacing: "0.1em",
                        color: "#848384",
                    }}
                >
                    WHAT GUESTS ARE SAYING
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: "90px",
                            height: "64.33px",
                        }}
                    ></Box>
                    <Typography
                        component="div"
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "25px",
                            lineHeight: "32px",
                            color: "#1D1B20",
                        }}
                    >
                        {quoteInfo.quote}
                    </Typography>
                    <FormatQuoteIcon
                        sx={{
                            width: "90px",
                            height: "64.33px",
                        }}
                    />
                </Box>
                <Typography
                    component="div"
                    sx={{
                        marginTop: "30px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "23px",
                        marginBottom: "15px",
                    }}
                >
                    -{" "}
                    <Typography
                        display={"inline"}
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "20px",
                        }}
                    >
                        {quoteInfo.name}
                    </Typography>{" "}
                </Typography>
            </Box>
        </>
    );
};

const QuoteCardMobile = ({ quoteInfo }) => {
    return (
        <>
            <Box
                sx={{
                    bgcolor: "#F2F2F2",
                }}
                display="flex"
                flexDirection="column"
            >
                <Typography
                    component="div"
                    sx={{
                        marginTop: "33px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "32px",
                        letterSpacing: "0.1em",
                        color: "#848384",
                    }}
                >
                    WHAT GUESTS ARE SAYING
                </Typography>

                <Typography
                    component="div"
                    sx={{
                        width: "90%",
                        alignSelf: "center",
                        marginTop: "25px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "18px",
                        lineHeight: "28px",
                        color: "#1D1B20",
                    }}
                >
                    {quoteInfo.quote}
                </Typography>
                <Typography
                    component="div"
                    sx={{
                        marginTop: "28px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: "23px",
                    }}
                >
                    -{"  "}
                    <Typography
                        display={"inline"}
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 600,
                            fontSize: "20px",
                        }}
                    >
                        {quoteInfo.name}
                    </Typography>{" "}
                </Typography>
                <FormatQuoteIcon
                    sx={{
                        marginLeft: "auto",
                        marginRight: "12px",
                        width: "90px",
                        height: "64.33px",
                    }}
                />
            </Box>
        </>
    );
};

const ServiceCard = ({
    imagepath,
    service,
    price,
    serviceInfo,
    pagination,
}) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card sx={{ width: "485px", alignSelf: "flex-start" }}>
            <CardMedia sx={{ height: 317 }} image={imagepath} />
            <CardContent
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
                <Typography
                    gutterBottom
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "24px",
                    }}
                >
                    {service}
                </Typography>
                <Typography
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#1D1B20",
                        lineHeight: "24px",
                    }}
                >
                    From{" "}
                    <Typography
                        display={"inline"}
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: "14px",
                        }}
                    >
                        ${price} / guest
                    </Typography>{" "}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <IconButton onClick={handleExpandClick}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {!expanded ? (
                                <AddIcon />
                            ) : (
                                <RemoveIcon
                                    sx={{
                                        color: "#1D1B20",
                                    }}
                                />
                            )}

                            <Typography
                                component="div"
                                sx={{
                                    marginLeft: "4px",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#1D1B20",
                                }}
                            >
                                Details
                            </Typography>
                        </Box>
                    </IconButton>
                </Box>

                <Button
                    sx={{
                        width: 126,
                        height: 40,
                    }}
                    onClick={() => navigate(pagination[0])}
                >
                    Customize
                </Button>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                    >
                        {serviceInfo.map(([key, value], index) => (
                            <React.Fragment key={index}>
                                <Typography
                                    component="div"
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        marginBottom: "7px",
                                        color: "#1D1B20",
                                    }}
                                >
                                    {key}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        textAlign: "left",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        marginBottom:
                                            index === serviceInfo.length - 1
                                                ? "0px"
                                                : "16px",
                                        color: "#49454F",
                                    }}
                                >
                                    {value}
                                </Typography>
                            </React.Fragment>
                        ))}
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    );
};

const ServiceCardMobile = ({
    imagepath,
    service,
    price,
    serviceInfo,
    pagination,
}) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card sx={{ width: "95%", marginBottom: "16px" }}>
            <CardMedia sx={{ height: 255 }} image={imagepath} />
            <CardContent
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
                <Typography
                    gutterBottom
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: "700",
                        fontSize: "22px",
                        lineHeight: "24px",
                    }}
                >
                    {service}
                </Typography>
                <Typography
                    component="div"
                    sx={{
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#1D1B20",
                        lineHeight: "24px",
                    }}
                >
                    From{" "}
                    <Typography
                        display={"inline"}
                        sx={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: "14px",
                        }}
                    >
                        ${price} / guest
                    </Typography>{" "}
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Box>
                    <IconButton onClick={handleExpandClick}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {!expanded ? (
                                <AddIcon />
                            ) : (
                                <RemoveIcon
                                    sx={{
                                        color: "#1D1B20",
                                    }}
                                />
                            )}

                            <Typography
                                component="div"
                                sx={{
                                    marginLeft: "4px",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                    color: "#1D1B20",
                                }}
                            >
                                Details
                            </Typography>
                        </Box>
                    </IconButton>
                </Box>

                <Button
                    sx={{
                        width: 126,
                        height: 40,
                    }}
                    onClick={() => navigate(pagination[0])}
                >
                    Customize
                </Button>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                    >
                        {serviceInfo.map(([key, value], index) => (
                            <React.Fragment key={index}>
                                <Typography
                                    component="div"
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        marginBottom: "7px",
                                        color: "#1D1B20",
                                    }}
                                >
                                    {key}
                                </Typography>
                                <Typography
                                    component="div"
                                    sx={{
                                        textAlign: "left",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        marginBottom:
                                            index === serviceInfo.length - 1
                                                ? "0px"
                                                : "16px",
                                        color: "#49454F",
                                    }}
                                >
                                    {value}
                                </Typography>
                            </React.Fragment>
                        ))}
                    </Box>
                </CardContent>
            </Collapse>
        </Card>
    );
};

const GuestFacingWebsiteMobileUI = ({ hostInfo, open, handleClose }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    var items = [
        {
            quote: "Booking a massage was so easy and I've never felt more confident in the quality",
            name: "NICK",
        },
        {
            quote: "I can't believe I hadn't been booking private chef meals on every vacation",
            name: "JAKE",
        },
        {
            quote: "I loved how responsive and helpful their providers are!",
            name: "PEYTON",
        },
    ];

    const massageInfo = {
        Description:
            "Enjoy a relaxing massage right in your rental from one of our recommended providers. Whether just for you or for your whole party, our providers are equipped to provide a tailored massage at a discounted price for all of our guests.",
        Modalities: "Swedish, Deep Tissue, Hot Stone, Sports",
        Duration: "Up to 2 hours long!",
        "Cancellation Policy": "Free cancellation up to 72 hours in advance",
    };

    const privateChefInfo = {
        Description:
            "Experience an unforgettable meal with one of our favorite local chefs without leaving the listing.  Select from our favorite local menus for your multi-course dinner and discover why dining in can beat out the restaurant experience for you and your party.",
        "Meals Available": "Lunch, Dinner",
        "Sample Menus": "Panhandle Classics, Low Country Boil, Asian Fusion",
        "Cancellation Policy": "Free cancellation up to 1 week in advance",
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        "& .MuiBox-root": {
                            outline: "none",
                        },
                    }}
                >
                    <Box
                        sx={{
                            transform: "translate(5%, 70%)",
                            width: "90%",
                            bgcolor: "#F6F6F6",
                            borderRadius: 2,
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            paddingTop: "5px",
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                marginBottom: "18px",
                            }}
                        >
                            <IconButton
                                sx={{
                                    visibility: "hidden",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    lineHeight: "19.36px",
                                    textAlign: "left",
                                    color: "#1D1B20",
                                }}
                            >
                                Welcome!
                            </Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1D1B20",
                                marginBottom: "25px",
                            }}
                        >
                            We've compiled local services from our favorite
                            providers you can request below! Take a look and see
                            if any of these services would make your vacation
                            that much better. Plus, there are no cancellation
                            fees up to a week in advance of your reservation!
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: "24px",
                                color: "#1D1B20",
                                marginBottom: "15px",
                            }}
                        >
                            Thank you for staying with us!
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "19px",
                                color: "#1D1B20",
                                marginBottom: "11px",
                            }}
                        >
                            {hostInfo.company_name === null
                                ? hostInfo.host_name
                                : hostInfo.company_name +
                                  " | " +
                                  hostInfo.host_name}
                        </Typography>
                    </Box>
                </Modal>
                <HeaderFooter hostInfo={hostInfo} isDesktop={false}>
                    <Card
                        sx={{
                            borderRadius: 0,
                            bgcolor: "#F2F2F2",
                        }}
                    >
                        <CardContent>
                            <Box display="flex" flexDirection="column">
                                <Box
                                    sx={{
                                        paddingLeft: "0px",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <ExpandMore
                                        expand={expanded}
                                        onClick={handleExpandClick}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: "500",
                                                fontSize: "20px",
                                                lineHeight: "24px",
                                                color: "#1D1B20",
                                                marginRight: "3px",
                                            }}
                                        >
                                            Welcome
                                        </Typography>
                                        <ExpandMoreIcon
                                            sx={{
                                                color: "#2D2D2D",
                                            }}
                                        />
                                    </ExpandMore>
                                </Box>
                                <Box
                                    sx={{
                                        width: "75%",
                                        alignSelf: "center",
                                    }}
                                >
                                    <Collapse
                                        in={expanded}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 400,
                                                fontSize: "18px",
                                                lineHeight: "30px",
                                                color: "#1D1B20",
                                                marginBottom: "25px",
                                            }}
                                        >
                                            We've compiled local services from
                                            our favorite providers you can
                                            request below! Take a look and see
                                            if any of these services would make
                                            your vacation that much better.
                                            Plus, there are no cancellation fees
                                            up to a week in advance of your
                                            reservation!
                                        </Typography>
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 700,
                                                fontSize: "22px",
                                                lineHeight: "24px",
                                                color: "#1D1B20",
                                                marginBottom: "23px",
                                            }}
                                        >
                                            Thank you for staying with us!
                                        </Typography>
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 700,
                                                fontSize: "16px",
                                                lineHeight: "19px",
                                                color: "#1D1B20",
                                                marginBottom: "23px",
                                            }}
                                        >
                                            {hostInfo.company_name === null
                                                ? hostInfo.host_name
                                                : hostInfo.company_name +
                                                  " | " +
                                                  hostInfo.host_name}
                                        </Typography>
                                    </Collapse>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    <Divider
                        sx={{
                            width: "100%",
                            bgcolor: "#D3D3D3",
                            height: "1px",
                        }}
                    />
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            paddingBottom: "22px",
                        }}
                    >
                        <Box
                            sx={{
                                paddingTop: "28px",
                                paddingLeft: "15px",
                            }}
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Typography
                                component="div"
                                sx={{
                                    textAlign: "left",
                                    fontFamily: "Inter",
                                    fontWeight: "800",
                                    fontSize: "26px",
                                    lineHeight: "31px",
                                }}
                            >
                                Services for {hostInfo.listing_name}
                            </Typography>
                            <Box
                                sx={{
                                    paddingTop: "13px",
                                }}
                            >
                                <Typography
                                    component="div"
                                    sx={{
                                        textAlign: "left",
                                        fontFamily: "Inter",
                                        fontWeight: 500,
                                        fontSize: "16px",
                                        lineHeight: "19px",
                                    }}
                                >
                                    {hostInfo.listing_address}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: "27px",
                                    width: "100%",
                                }}
                            >
                                {hostInfo.massage_min_price !== null && (
                                    <ServiceCardMobile
                                        imagepath={massagePhoto}
                                        service="Massage"
                                        price={hostInfo.massage_min_price}
                                        serviceInfo={Object.entries(
                                            massageInfo
                                        )}
                                        pagination={MASSAGE_PAGINATION}
                                    />
                                )}

                                {hostInfo.private_chef_min_price !== null && (
                                    <ServiceCardMobile
                                        imagepath={privateChefPhoto}
                                        service="Private Chef"
                                        price={hostInfo.private_chef_min_price}
                                        serviceInfo={Object.entries(
                                            privateChefInfo
                                        )}
                                        pagination={PRIVATE_CHEF_PAGINATION}
                                    />
                                )}
                                {hostInfo.massage_min_price === null &&
                                    hostInfo.private_chef_min_price ===
                                        null && (
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: "700",
                                                fontSize: "24px",
                                                lineHeight: "31px",
                                            }}
                                        >
                                            No Services Available
                                        </Typography>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                    <Carousel
                        indicatorIconButtonProps={{
                            style: {
                                padding: "10px", // 1
                            },
                        }}
                    >
                        {items.map((quoteInfo, i) => (
                            <QuoteCardMobile key={i} quoteInfo={quoteInfo} />
                        ))}
                    </Carousel>
                </HeaderFooter>
            </Box>
        </>
    );
};

const GuestFacingWebsiteDesktopUI = ({ hostInfo, open, handleClose }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    var items = [
        {
            quote: "Booking a massage was so easy and I've never felt more confident in the quality",
            name: "NICK",
        },
        {
            quote: "I can't believe I hadn't been booking private chef meals on every vacation",
            name: "JAKE",
        },
        {
            quote: "I loved how responsive and helpful their providers are!",
            name: "PEYTON",
        },
    ];

    const massageInfo = {
        Description:
            "Enjoy a relaxing massage right in your rental from one of our recommended providers. Whether just for you or for your whole party, our providers are equipped to provide a tailored massage at a discounted price for all of our guests.",
        Modalities: "Swedish, Deep Tissue, Hot Stone, Sports",
        Duration: "Up to 2 hours long!",
        "Cancellation Policy": "Free cancellation up to 72 hours in advance",
    };

    const privateChefInfo = {
        Description:
            "Experience an unforgettable meal with one of our favorite local chefs without leaving the listing.  Select from our favorite local menus for your multi-course dinner and discover why dining in can beat out the restaurant experience for you and your party.",
        "Meals Available": "Lunch, Dinner",
        "Sample Menus": "Panhandle Classics, Low Country Boil, Asian Fusion",
        "Cancellation Policy": "Free cancellation up to 1 week in advance",
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, minWidth: "1512px" }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        "& .MuiBox-root": {
                            outline: "none",
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "50%",
                            bgcolor: "#F6F6F6",
                            p: "17px",
                            borderRadius: 2,
                        }}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{
                                marginBottom: "18px",
                            }}
                        >
                            <IconButton
                                sx={{
                                    visibility: "hidden",
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    lineHeight: "24px",
                                    color: "#1D1B20",
                                }}
                            >
                                Welcome!
                            </Typography>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#1D1B20",
                                marginBottom: "25px",
                            }}
                        >
                            We've compiled local services from our favorite
                            providers you can request below! Take a look and see
                            if any of these services would make your vacation
                            that much better. Plus, there are no cancellation
                            fees up to a week in advance of your reservation!
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: "24px",
                                color: "#1D1B20",
                                marginBottom: "15px",
                            }}
                        >
                            Thank you for staying with us!
                        </Typography>
                        <Typography
                            component="div"
                            sx={{
                                textAlign: "center",
                                fontFamily: "Inter",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "19px",
                                color: "#1D1B20",
                                marginBottom: "11px",
                            }}
                        >
                            {hostInfo.company_name === null
                                ? hostInfo.host_name
                                : hostInfo.company_name +
                                  " | " +
                                  hostInfo.host_name}
                        </Typography>
                    </Box>
                </Modal>
                <HeaderFooter hostInfo={hostInfo} isDesktop={true}>
                    <Card
                        sx={{
                            borderRadius: 0,
                            bgcolor: "#F2F2F2",
                            minWidth: "1050px",
                        }}
                    >
                        <CardContent>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                            >
                                <Box
                                    sx={{
                                        marginRight: "auto",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "130px",
                                            paddingLeft: "20px",
                                            alignItems: "center",
                                        }}
                                        display="flex"
                                    >
                                        <ExpandMore
                                            expand={expanded}
                                            onClick={handleExpandClick}
                                            aria-expanded={expanded}
                                            aria-label="show more"
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: "Inter",
                                                    fontWeight: "500",
                                                    fontSize: "20px",
                                                    lineHeight: "24px",
                                                    color: "#1D1B20",
                                                }}
                                            >
                                                Welcome
                                            </Typography>
                                            <ExpandMoreIcon
                                                sx={{
                                                    color: "#2D2D2D",
                                                }}
                                            />
                                        </ExpandMore>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width: "51.5%",
                                        alignSelf: "center",
                                    }}
                                >
                                    <Collapse
                                        in={expanded}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 400,
                                                fontSize: "18px",
                                                lineHeight: "30px",
                                                color: "#1D1B20",
                                                marginBottom: "25px",
                                            }}
                                        >
                                            We've compiled local services from
                                            our favorite providers you can
                                            request below! Take a look and see
                                            if any of these services would make
                                            your vacation that much better.
                                            Plus, there are no cancellation fees
                                            up to a week in advance of your
                                            reservation!
                                        </Typography>
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 700,
                                                fontSize: "22px",
                                                lineHeight: "24px",
                                                color: "#1D1B20",
                                                marginBottom: "23px",
                                            }}
                                        >
                                            Thank you for staying with us!
                                        </Typography>
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: 700,
                                                fontSize: "16px",
                                                lineHeight: "19px",
                                                color: "#1D1B20",
                                                marginBottom: "23px",
                                            }}
                                        >
                                            {hostInfo.company_name === null
                                                ? hostInfo.host_name
                                                : hostInfo.company_name +
                                                  " | " +
                                                  hostInfo.host_name}
                                        </Typography>
                                    </Collapse>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    <Divider
                        sx={{
                            width: "100%",
                            bgcolor: "#D3D3D3",
                            height: "1px",
                        }}
                    />
                    <Box
                        sx={{
                            bgcolor: "#FDFDFD",
                            paddingBottom: "125px",
                        }}
                    >
                        <Box
                            sx={{
                                paddingTop: "33px",
                                paddingLeft: "46px",
                            }}
                            display="flex"
                            alignItems="flex-start"
                            flexDirection="column"
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: "800",
                                    fontSize: "26px",
                                    lineHeight: "31px",
                                }}
                            >
                                Services for {hostInfo.listing_name}
                            </Typography>
                            <Box
                                sx={{
                                    paddingTop: "5px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: "Inter",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                    }}
                                >
                                    {hostInfo.company_name === null
                                        ? hostInfo.host_name
                                        : hostInfo.company_name}{" "}
                                    partners with Nexus Upsells to provide these
                                    services.
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: "24px",
                                }}
                                display="flex"
                            >
                                {hostInfo.massage_min_price !== null && (
                                    <ServiceCard
                                        imagepath={massagePhoto}
                                        service="Massage"
                                        price={hostInfo.massage_min_price}
                                        serviceInfo={Object.entries(
                                            massageInfo
                                        )}
                                        pagination={MASSAGE_PAGINATION}
                                    />
                                )}
                                {hostInfo.private_chef_min_price !== null && (
                                    <Box
                                        sx={{
                                            marginLeft: "33px",
                                        }}
                                    >
                                        <ServiceCard
                                            imagepath={privateChefPhoto}
                                            service="Private Chef"
                                            price={
                                                hostInfo.private_chef_min_price
                                            }
                                            serviceInfo={Object.entries(
                                                privateChefInfo
                                            )}
                                            pagination={PRIVATE_CHEF_PAGINATION}
                                        />
                                    </Box>
                                )}

                                {hostInfo.massage_min_price === null &&
                                    hostInfo.private_chef_min_price ===
                                        null && (
                                        <Typography
                                            sx={{
                                                fontFamily: "Inter",
                                                fontWeight: "700",
                                                fontSize: "24px",
                                                lineHeight: "31px",
                                            }}
                                        >
                                            No Services Available
                                        </Typography>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                    <Carousel
                        indicatorIconButtonProps={{
                            style: {
                                padding: "10px", // 1
                            },
                        }}
                    >
                        {items.map((quoteInfo, i) => (
                            <QuoteCard key={i} quoteInfo={quoteInfo} />
                        ))}
                    </Carousel>
                </HeaderFooter>
            </Box>
        </>
    );
};

const Loaded = ({ orderHook, hostInfo }) => {
    const isMobile = useMediaQuery("(max-width:600px)");

    const [order, orderTryLoad, setOrder] = orderHook;

    const [open, setOpen] = useState(true);

    const handleClose = () => setOpen(false);

    return (
        <>
            {isMobile ? (
                <GuestFacingWebsiteMobileUI
                    hostInfo={hostInfo}
                    open={open}
                    handleClose={handleClose}
                />
            ) : (
                <GuestFacingWebsiteDesktopUI
                    hostInfo={hostInfo}
                    open={open}
                    handleClose={handleClose}
                />
            )}
        </>
    );
};

const GuestFacingWebsite = () => {
    const { uniqueId } = useParams();

    const orderHook = useCurrentServiceOrder();

    const [order, orderTryLoad, setOrder] = orderHook;

    const [isValidLink, setIsValidLink] = useState(true);

    useEffect(() => {
        if (
            !order ||
            order.service_order === null ||
            Object.keys(order).length === 0
        ) {
            createNewServiceOrder();
        }
    }, [order]);

    async function createNewServiceOrder() {
        if (!sessionStorage.getItem("tabID")) {
            sessionStorage.setItem(
                "tabID",
                "tab_" +
                    Date.now().toString(36) +
                    Math.random().toString(36).substr(2, 9)
            );
        }

        const url = "service-order/create";
        try {
            const response = await djangoEndpointPromise(url, {
                hash_value: uniqueId,
            });

            if (fetchResultType(response) === "error") {
                throw new Error(
                    "Server Error: Something went wrong on the server."
                );
            }

            const data = response.data;
            setOrder(data);
            sessionStorage.setItem("listingURL", uniqueId);
        } catch (error) {
            setOrder({ order: null });
            setIsValidLink(false);
            console.error("Error creating new service order:", error);
        }
    }

    const hostHook = useCurrentHost();
    const [host, hostTryLoad, setHostProvider] = hostHook;

    useEffect(() => {
        hostTryLoad();
    }, [hostTryLoad]);

    return (
        <>
            {isValidLink ? (
                <Loading
                    fetchResult={mergeMultipleResults(order, host)}
                    onTryAgain={orderTryLoad}
                    renderChildren={() => (
                        <Loaded orderHook={orderHook} hostInfo={host} />
                    )}
                />
            ) : (
                <h1>Invalid Link</h1>
            )}
        </>
    );
};

export default GuestFacingWebsite;
