// https://stackoverflow.com/questions/39999282/re-export-default-in-es-6-modules
// export { default as secret } from '../../../run/secrets/nodeSecret';
import { Box, Button, IconButton, Snackbar, styled } from "@mui/material";
import { DJANGO_URL, STRIPE_PUBLISHABLE_KEY } from "./nodeSecret";
import {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import { LoadingIndicator } from "./components/UI";
import { djangoUrl } from "./urls";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import IndivMassagePhoto from "./assets/indivmassage.png";
import SwedishMassagePhoto from "./assets/swedishMassage.png";
import DeepTissueMassagePhoto from "./assets/deepTissueMassage.png";

export const secret = {
    DJANGO_URL,
    STRIPE_PUBLISHABLE_KEY,
};

export function debugImage(width, height) {
    return `https://picsum.photos/seed/nexusmvp/${width}/${height}`;
}

export const MASSAGE_PAGINATION = [
    "/massage/guest-count",
    "/massage/type-length-therapist",
    "/massage/calendar",
    "/massage/guest-recipient-info",
    "/massage/request-details",
];

export const PRIVATE_CHEF_PAGINATION = [
    "/private-chef/bld",
    "/private-chef/calendar",
    "/private-chef/time-slots",
    "/private-chef/selections",
    "/private-chef/guest-recipient-info",
    "/private-chef/request-details",
];

export const LOREM_IPSUM_2_SENTENCES =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

const CSRFContext = createContext();
export const useCSRF = () => useContext(CSRFContext);

export const CSRFProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await djangoEndpointPromise("get-csrftoken");
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <CSRFContext.Provider value={{}}>
            {loading ? <LoadingIndicator /> : children}
        </CSRFContext.Provider>
    );
};

const CurrentServiceOrderContext = createContext();

export const useCurrentServiceOrder = () =>
    useContext(CurrentServiceOrderContext);

export const CurrentServiceOrderProvider = ({ children }) => {
    const [serviceOrder, setServiceOrder] = useState({});
    const location = useLocation();

    useEffect(() => {
        tryLoad();
    }, []);
    async function tryLoad() {
        if (location.pathname.startsWith("/listing/")) {
            // Extract the unique ID from the pathname
            const uniqueId = location.pathname.split("/listing/")[1];

            if (uniqueId && uniqueId === sessionStorage.getItem("listingURL")) {
                setServiceOrder(
                    await djangoEndpointPromise("service-order/current")
                );
            } else {
                setServiceOrder({ order: null });
            }
        } else {
            setServiceOrder(
                await djangoEndpointPromise("service-order/current")
            );
        }
    }
    return (
        <CurrentServiceOrderContext.Provider
            value={[serviceOrder, tryLoad, setServiceOrder]}
        >
            {children}
        </CurrentServiceOrderContext.Provider>
    );
};

const CurrentHostContext = createContext();

export const useCurrentHost = () => useContext(CurrentHostContext);

export const CurrentHostProvider = ({ children }) => {
    const [order, _orderTryAgain, _setOrder] = useCurrentServiceOrder();
    const [host, setHost] = useState({});
    // Normally, we would useEffect(...) to attempt a load.
    // However, we want an element of laziness. The current guest is not needed in every page.
    // The children can call tryLoad() whenever orderType changes.
    const tryLoad = useCallback(async () => {
        // Refuse to do anything if the order id is undefined
        if (order.order_id === undefined) {
            return;
        }
        const result = await djangoEndpointPromise(
            `get-hostsite-info?order_id=${encodeURIComponent(order.order_id)}`
        );
        const resultType = fetchResultType(result);

        if (resultType === "error") {
            setHost(result);
            return;
        }
        try {
            if (result) {
                setHost(result);
            } else {
                setHost(null);
            }
        } catch (e) {
            setHost({
                error: ["Cannot read host data format", [e, result]],
            });
        }
    }, [order.order_id]);
    return (
        <CurrentHostContext.Provider value={[host, tryLoad, setHost]}>
            {children}
        </CurrentHostContext.Provider>
    );
};

const CurrentGuestContext = createContext();

export const useCurrentGuest = () => useContext(CurrentGuestContext);

export const CurrentGuestProvider = ({ children }) => {
    const [order, _orderTryAgain, _setOrder] = useCurrentServiceOrder();
    const [guest, setGuest] = useState({});
    // Normally, we would useEffect(...) to attempt a load.
    // However, we want an element of laziness. The current guest is not needed in every page.
    // The children can call tryLoad() whenever orderType changes.
    const tryLoad = useCallback(async () => {
        // Refuse to do anything if the order id is undefined
        if (order.order_id === undefined) {
            return;
        }
        const result = await djangoEndpointPromise(
            `service-order/current/guests?order_id=${encodeURIComponent(
                order.order_id
            )}`
        );
        const resultType = fetchResultType(result);
        if (resultType === "error") {
            setGuest(result);
            return;
        }
        try {
            if (result.data.length > 0) {
                setGuest(result.data[0]);
            } else {
                setGuest(null);
            }
        } catch (e) {
            setGuest({
                error: ["Cannot read guest data format", [e, result]],
            });
        }
    }, [order.order_id]);
    return (
        <CurrentGuestContext.Provider value={[guest, tryLoad, setGuest]}>
            {children}
        </CurrentGuestContext.Provider>
    );
};

const CurrentRecipientsContext = createContext();

export const useCurrentRecipients = () => useContext(CurrentRecipientsContext);

export const CurrentRecipientsProvider = ({ children }) => {
    const [order, _orderTryAgain, _setOrder] = useCurrentServiceOrder();
    const [recipients, setRecipients] = useState({});
    // Like CurrentGuestProvider, there is an element of laziness.
    const tryLoad = useCallback(async () => {
        if (order.order_id === undefined) {
            return;
        }
        const result = await djangoEndpointPromise(
            `service-order/current/recipients?order_id=${encodeURIComponent(
                order.order_id
            )}`
        );
        const resultType = fetchResultType(result);
        if (resultType === "error") {
            setRecipients(result);
            return;
        }
        try {
            if (!Array.isArray(result.recipients)) {
                throw new Error("recipients must be array");
            }
            setRecipients(result);
        } catch (e) {
            setRecipients({
                error: ["Cannot read guest data format", e, result],
            });
        }
    }, [order.order_id]);
    return (
        <CurrentRecipientsContext.Provider
            value={[recipients, tryLoad, setRecipients]}
        >
            {children}
        </CurrentRecipientsContext.Provider>
    );
};

const CurrentMenuContext = createContext();

export const useCurrentMenu = () => useContext(CurrentMenuContext);

export const CurrentMenuProvider = ({ children }) => {
    const [menu, setMenu] = useState(null);
    return (
        <CurrentMenuContext.Provider value={[menu, setMenu]}>
            {children}
        </CurrentMenuContext.Provider>
    );
};

const SnackbarContext = createContext(null);

/**
 * Provides a function that shows a snackbar. The parameters are (message, onClick).
 */
export const SnackbarProvider = ({ children }) => {
    // This component is almost identical to the MUI docs.

    // Represents a queue (front element is first out) of snackbar data.
    const [snackbarInfo, setSnackbarInfo] = useState([]);

    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState(null);

    useEffect(() => {
        if (snackbarInfo.length && !messageInfo) {
            // Set a new Snackbar to be displayed
            setMessageInfo({ ...snackbarInfo[0] });
            setSnackbarInfo((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackbarInfo.length && messageInfo && open) {
            // Close an existing snackbar if open
            setOpen(false);
        }
    }, [snackbarInfo, messageInfo, open]);

    const showSnackbar = (message, options = {}) => {
        const { onAction, actionLabel, autoHideDuration } = options;
        setSnackbarInfo((prev) => [
            ...prev,
            {
                message,
                key: new Date().getTime(),
                onAction,
                actionLabel,
                autoHideDuration,
            },
        ]);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setMessageInfo(null);
    };

    // It is better to leave the Snackbar always rendered even when it is hidden.
    // This is because there are better animations and the code is simpler.
    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <Snackbar
                key={messageInfo === null ? undefined : messageInfo.key}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={
                    messageInfo === null
                        ? undefined
                        : messageInfo.autoHideDuration === undefined
                        ? 1000
                        : messageInfo.autoHideDuration
                }
                onClose={handleClose}
                onExited={handleExited}
                TransitionProps={{ onExited: handleExited }}
                message={messageInfo === null ? undefined : messageInfo.message}
                action={
                    messageInfo === null ? undefined : (
                        <>
                            {messageInfo.onAction === undefined ? null : (
                                <Button
                                    color="secondary"
                                    size="small"
                                    onClick={messageInfo.onAction}
                                >
                                    {messageInfo.actionLabel}
                                </Button>
                            )}
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </>
                    )
                }
            />
        </SnackbarContext.Provider>
    );
};

// Hook to use the snackbar
export const useSnackbar = () => useContext(SnackbarContext);

export async function djangoEndpointPromise(url, post) {
    let res;
    let json;

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(
                        cookie.substring(name.length + 1)
                    );
                    break;
                }
            }
        }
        return cookieValue;
    }

    try {
        if (post === undefined) {
            res = await fetch(djangoUrl(url), {
                credentials: "include",
                headers: {
                    "Tab-ID": sessionStorage.getItem("tabID"),
                },
            });
        } else {
            const csrftoken = getCookie("csrftoken");
            res = await fetch(djangoUrl(url), {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrftoken,
                    "Tab-ID": sessionStorage.getItem("tabID"),
                },
                body: JSON.stringify(post),
            });
        }
    } catch (e) {
        return {
            error: ["Network error", String(e)],
        };
    }
    try {
        json = await res.json();
    } catch (e) {
        return {
            error: ["Invalid JSON", String(e)],
        };
    }
    console.log("network response", json);
    if (Object.keys(json).length === 0) {
        return {
            data: undefined,
        };
    }
    return json;
}

/**
 * Returns one of 'loading', 'data', or 'error'.
 */
export function fetchResultType(fetchResult) {
    if (isEmptyObject(fetchResult)) {
        return "loading";
    }
    if (
        fetchResult !== null &&
        typeof fetchResult === "object" &&
        "error" in fetchResult
    ) {
        return "error";
    }
    return "data";
}

/**
 * Merge multiple results.
 *
 * If there is a loading object, the "loading" value (empty object) is returned.
 * Otherwise, if there is a failure, the error messages are concatenated with semicolons and the debug infos are put into an array.
 * Otherwise, everything is successful, and the results parameter is passed back as-is.
 */
export function mergeMultipleResults(...results) {
    const errors = [];

    for (const result of results) {
        const resultType = fetchResultType(result);
        if (resultType === "loading") {
            return {};
        } else if (resultType === "error") {
            errors.push(result.error);
        } else {
            // Do nothing
        }
    }
    if (errors.length > 0) {
        // Process the errors
        const errorMessages = [];
        const errorDebug = [];
        for (const error of errors) {
            if (Array.isArray(error)) {
                if (error.length >= 1) {
                    errorMessages.push(String(error[0]));
                } else {
                    errorMessages.push("(blank message)");
                }
                if (error.length >= 2) {
                    errorDebug.push(error[1]);
                } else {
                    errorDebug.push(undefined);
                }
            }
        }
        // Form the object to return
        return {
            error: [errorMessages.join("; "), errorDebug],
        };
    }
    // Return the results as-is, as an array
    return results;
}

export const MUTED_BUTTON_SELECTED_STRINGS = [
    "mutedButton",
    "mutedButtonSelected",
];

/**
 * Credits to https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 */
function isEmpty(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

// Helper function to convert 24-hour time to 12-hour format
function convertTo12HourFormat(time) {
    const [hour, minute] = time.split(":");
    const hourInt = parseInt(hour);
    const minuteInt = parseInt(minute);
    const period = hourInt >= 12 ? "PM" : "AM";
    const adjustedHour = hourInt % 12 || 12; // Convert 0 to 12 for midnight

    return `${adjustedHour}:${minuteInt < 10 ? "0" : ""}${minuteInt} ${period}`;
}

// Function to format a pair of times into a string like "5:30-7:00 PM"
export function formatTimeRange(startTime, endTime) {
    const start12Hour = convertTo12HourFormat(startTime);
    const end12Hour = convertTo12HourFormat(endTime);

    return `${start12Hour.split(" ")[0]}-${end12Hour}`;
}

/**
 * Credits to https://stackoverflow.com/questions/679915/how-do-i-test-for-an-empty-javascript-object
 */
function isEmptyObject(value) {
    if (value == null) {
        // null or undefined
        return false;
    }

    if (typeof value !== "object") {
        // boolean, number, string, function, etc.
        return false;
    }

    const proto = Object.getPrototypeOf(value);

    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto !== null && proto !== Object.prototype) {
        return false;
    }

    return isEmpty(value);
}

export const SWEDISH_DESCRIPTION =
    "Swedish massage is a therapeutic technique that uses long, flowing strokes, kneading, and circular movements to promote relaxation and improve circulation.";

export const DEEP_TISSUE_DESCRIPTION =
    "Deep tissue massage is a technique that targets the deeper layers of muscle and connective tissue through slow, firm pressure to relieve chronic pain and muscle tension.";

export const HOT_STONE_DESCRIPTION =
    "Hot stone massage involves placing heated stones on the body and using them to massage muscles, providing deep relaxation and easing muscle stiffness.";

export const MASSAGE_TYPE_REQUESTED = {
    options: ["swedish", "deep tissue", "hot stone"],
    titles: ["Swedish", "Deep Tissue", "Hot Stone"],
    subtitles: [
        SWEDISH_DESCRIPTION,
        DEEP_TISSUE_DESCRIPTION,
        HOT_STONE_DESCRIPTION,
    ],
    images: [SwedishMassagePhoto, DeepTissueMassagePhoto, IndivMassagePhoto],
};

export const MASSAGE_GENDER_REQUESTED = {
    options: ["male", "female", "no preference"],
    titles: ["Male", "Female", "No preference"],
};

export const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeWords = (string) => {
    if (!string) return "";
    return string
        .split(" ")
        .map((word) => capitalizeFirstLetter(word))
        .join(" ");
};

export const addSToEnd = (str) => {
    // Check if the string already ends with 's'
    if (str.charAt(str.length - 1).toLowerCase() !== "s") {
        return str + "s";
    }
    return str;
};

/**
 * Turn a Date into time components { hours, minutes, ampm }.
 */
export function toTimeComponents(date) {
    let hours = date.hour();
    let minutes = date.minute();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // Hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return { hours, minutes, ampm };
}

/**
 * Format a Date to a time string (no date).
 */
export function toTimeString(date) {
    const { hours, minutes, ampm } = toTimeComponents(date);
    return `${hours}:${minutes}${ampm}`;
}

/**
 * Format two dates into a time interval, e.g., 9:00-11:00am.
 *
 * Examples of format:
 * 10:00-11:30am
 * 12:00-1:30pm
 * 6:30-8:00pm
 *
 * There is no leading zero for the hour, and AM/PM is elided from the first entry when possible.
 */
export function toTimeIntervalString(time1, time2) {
    // Get formatted times
    let {
        hours: startHours,
        minutes: startMinutes,
        ampm: startAmPm,
    } = toTimeComponents(time1);
    let {
        hours: endHours,
        minutes: endMinutes,
        ampm: endAmPm,
    } = toTimeComponents(time2);

    // Create the time string, conditionally format start AM/PM
    let startTime = `${startHours}:${startMinutes}`;
    let endTime = `${endHours}:${endMinutes}${endAmPm}`;
    if (startAmPm === endAmPm) {
        return `${startTime}-${endTime}`;
    } else {
        return `${startTime}${startAmPm}-${endTime}`;
    }
}

/**
 * Format a Date to a date string (no time).
 */
export function toDateString(date) {
    const month = date.month() + 1; // Add 1 because months are 0-indexed
    const day = date.date();
    const year = date.year();
    return `${month}/${day}/${year}`;
}
