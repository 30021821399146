import React from "react";
import { djangoUrl } from "../urls";
import { useGoogleLogin } from "@react-oauth/google";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Divider,
    Button,
    Grid,
    useMediaQuery,
} from "@mui/material";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import logo from "../assets/nexusproviderlogo.png";
import vector from "../assets/vector.png";

// This function will be called upon a successful login
const handleSuccess = (credentialResponse) => {
    // If you are using the authorization code flow, you will receive a code to be exchanged for an access token
    const authorizationCode = credentialResponse.code;
    console.log(authorizationCode);

    // Send the authorization code to your backend server
    fetch(djangoUrl("auth/get_tokens"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ authorization_code: authorizationCode }),
    })
        .then((response) => response.json())
        .then((data) => {
            // Handle the response from your backend server
            console.log("Login successful, backend response:", data);
        })
        .catch((error) => {
            // Handle errors in communicating with your backend server
            console.error("Error exchanging authorization code:", error);
        });
};

const GoogleLoginMobileUI = () => {
    const googleLogin = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            const authorizationCode = credentialResponse.code;
            console.log(authorizationCode);

            // Send the authorization code to your backend server
            fetch(djangoUrl("auth/get_tokens"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ authorization_code: authorizationCode }),
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response from your backend server
                    console.log("Login successful, backend response:", data);
                })
                .catch((error) => {
                    // Handle errors in communicating with your backend server
                    console.error(
                        "Error exchanging authorization code:",
                        error
                    );
                });
            // You can now use the tokenResponse to authenticate the user in your app
        },
        onError: (errorResponse) => {
            console.error("Google login failed", errorResponse);
            // Handle login errors here
        },
        flow: "auth-code", // Use 'auth-code' for the authorization code flow
        scope: "https://www.googleapis.com/auth/calendar",
    });
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        paddingTop: "9px",
                    }}
                >
                    <img
                        src={logo}
                        style={{
                            width: "127px",
                            height: "auto",
                            maxHeight: 56,
                        }}
                        alt="Logo"
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: "43px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 600,
                            fontSize: "22px",
                            color: "#010424",
                            lineHeight: "21px",
                            letterSpacing: "0.1px",
                        }}
                    >
                        Link your Google Calendar
                    </Typography>
                </Box>
                <Box
                    sx={{
                        paddingTop: "22px",
                    }}
                >
                    <Divider
                        sx={{
                            width: "100%",
                            bgcolor: "#E5E5E5",
                            height: "1px",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: "12px",
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#2D2D2D",
                        }}
                    >
                        Share availability with guests and automatically create
                        events for any requests you accept.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        paddingTop: "12px",
                    }}
                >
                    <Divider
                        sx={{
                            width: "100%",
                            bgcolor: "#E5E5E5",
                            height: "1px",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        paddingTop: "43px",
                    }}
                >
                    <Button
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#00E4DA33",
                            color: "white",
                        }}
                        onClick={() => googleLogin()}
                    >
                        <Typography
                            variant="body1"
                            sx={{
                                textTransform: "none",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                color: "#010424",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                            }}
                        >
                            Sign in with Google 🚀
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </>
    );
};

const GoogleLoginDesktopUI = () => {
    const googleLogin = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            const authorizationCode = credentialResponse.code;
            console.log(authorizationCode);

            // Send the authorization code to your backend server
            fetch(djangoUrl("auth/get_tokens"), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ authorization_code: authorizationCode }),
            })
                .then((response) => response.json())
                .then((data) => {
                    // Handle the response from your backend server
                    console.log("Login successful, backend response:", data);
                })
                .catch((error) => {
                    // Handle errors in communicating with your backend server
                    console.error(
                        "Error exchanging authorization code:",
                        error
                    );
                });
            // You can now use the tokenResponse to authenticate the user in your app
        },
        onError: (errorResponse) => {
            console.error("Google login failed", errorResponse);
            // Handle login errors here
        },
        flow: "auth-code", // Use 'auth-code' for the authorization code flow
        scope: "https://www.googleapis.com/auth/calendar",
    });
    return (
        <>
            <AppBar
                elevation={0}
                sx={{
                    boxShadow: "none",
                    borderBottom: "1px solid #E5E5E5",
                    bgcolor: "white",
                    height: "83px",
                    justifyContent: "center",
                }}
            >
                <Toolbar>
                    <Box
                        display="flex"
                        sx={{
                            width: 127,
                            maxWidth: "100%",
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: 56,
                            }}
                            alt="Logo"
                        />
                    </Box>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <Grid container spacing={0} wrap="nowrap">
                <Grid item sx={{}}>
                    <Box sx={{ width: "275px", maxWidth: "275px" }}>
                        <Box sx={{ width: "274px", maxWidth: "275px" }}>
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    fontSize: "20px",
                                    color: "#2D2D2D",
                                    lineHeight: "30px",
                                    letterSpacing: "0.15px",
                                    textAlign: "left",
                                    paddingTop: "80px",
                                    paddingLeft: "16px",
                                }}
                            >
                                Provider Name
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "100%",
                                    maxWidth: "265px",
                                    bgcolor: "#E5E5E5",
                                    height: "1px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "13px",
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        width: "258px",
                                        maxWidth: "258px",
                                        height: "40px",
                                        backgroundColor: "#00E4DA33",
                                        display: "flex", // Use flex display
                                        alignItems: "center", // Align elements vertically
                                        paddingLeft: "8px",
                                    }}
                                >
                                    <img
                                        src={vector}
                                        style={{
                                            height: "auto",
                                            maxHeight: 56,
                                        }}
                                        alt="vector icon"
                                    />
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "#010424",
                                            lineHeight: "21px",
                                            letterSpacing: "0.1px",
                                            paddingLeft: "17px",
                                        }}
                                    >
                                        Calendar Integration
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        height: "100vh",
                    }}
                >
                    <Divider orientation="vertical" flexItem />
                </Box>
                <Grid item xs>
                    <Box
                        sx={{
                            paddingTop: "50px",
                            paddingLeft: "43px",
                            textAlign: "left",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Poppins",
                                fontWeight: 600,
                                fontSize: "22px",
                                color: "#010424",
                                lineHeight: "21px",
                                letterSpacing: "0.1px",
                            }}
                        >
                            Link your Google Calendar
                        </Typography>
                        <Box
                            sx={{
                                paddingTop: "16px",
                                width: "100%",
                                maxWidth: "578px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "16px",
                                    color: "#2D2D2D",
                                    lineHeight: "30px",
                                }}
                            >
                                Share availability with guests and automatically
                                create events for any requests you accept.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "17px",
                            }}
                        >
                            <Divider
                                sx={{
                                    width: "442px",
                                    bgcolor: "#E5E5E5",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: "46px",
                            }}
                        >
                            <Button
                                disableElevation
                                variant="contained"
                                sx={{
                                    backgroundColor: "#00E4DA33",
                                    color: "white",
                                }}
                                onClick={() => googleLogin()}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textTransform: "none",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        color: "#010424",
                                        lineHeight: "21px",
                                        letterSpacing: "0.1px",
                                    }}
                                >
                                    Sign in with Google 🚀
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

const GoogleLogin = () => {
    const isMobile = useMediaQuery("(max-width:600px)");

    return (
        <div>
            {isMobile ? <GoogleLoginMobileUI /> : <GoogleLoginDesktopUI />}
        </div>
    );
};

export default GoogleLogin;
