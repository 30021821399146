import React, { useState, useEffect, createContext, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { secret, useCurrentServiceOrder, djangoEndpointPromise } from "../util";
import { Loading } from "../components/UI";

const stripePromise = loadStripe(secret.STRIPE_PUBLISHABLE_KEY);

const Loaded = ({ orderHook }) => {
    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Define an async function within the useEffect
        const fetchClientSecret = async () => {
            try {
                // Call the djangoEndpointPromise with the necessary arguments
                const response = await djangoEndpointPromise(
                    "stripe/create-checkout-session",
                    true
                );

                // Set the client secret in state
                setClientSecret(response.clientSecret);
            } catch (error) {
                console.error("Error fetching client secret:", error);
            }
        };

        // Call the async function
        fetchClientSecret();
    }, []);

    return (
        <div id="checkout">
            {clientSecret && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    );
};

const CheckoutForm = () => {
    const orderHook = useCurrentServiceOrder();
    const [order, tryAgain] = orderHook;

    return (
        <>
            <Loading
                fetchResult={order}
                onTryAgain={tryAgain}
                renderChildren={() => <Loaded orderHook={orderHook} />}
            />
        </>
    );
};

export default CheckoutForm;
