import React from "react";
import { HeaderFooterEmpty } from "../components/UI";
import { Typography, Box, useMediaQuery, Stack } from "@mui/material";
import nexusLogo from "../assets/nexuslogo.png";
import CircleIcon from '@mui/icons-material/Circle';
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

const InvalidOrderMobileUI = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            minWidth: "0px",
            width: "100%",
            overflowX: "auto",
            scrollBehavior: "smooth",
        }}>
            <HeaderFooterEmpty isDesktop={false}>
                <Box
                    sx={{
                        bgcolor: "#F2F2F2",
                        paddingBottom: "67px",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                    }}
                >
                    <Stack direction="column" spacing="12px" sx={{
                        alignItems: "center",
                        marginTop: "7vh",
                        marginLeft: "15vw",
                        marginRight: "15vw",
                    }}>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontSize: "30px",
                            fontWeight: "500",
                            lineHeight: "36.31px",
                            textAlign: "center"
                        }}>
                            Your order has expired or been completed.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontSize: "22px",
                            fontWeight: "500",
                            lineHeight: "24.2px",
                            textAlign: "center",
                            marginTop: "2vh",
                        }}>
                            Please return to your host's website to start a new order.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "26.63px",
                            textAlign: "center",
                            marginTop: "35px",
                        }}>
                            Your host partners with Nexus to provide these services.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "26.63px",
                            textAlign: "center",
                            marginTop: "0px",
                        }}>
                            If you have any questions, feel free to reach us at:
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "stretch",
                                marginTop: "10px",
                            }}
                        >
                            <PhoneIcon
                                sx={{
                                    color: "black",
                                    marginRight: "5px",
                                }}
                            />
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    marginRight: "5px",
                                    color: "black",
                                }}
                            >
                                385-200-0937
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "stretch",
                            }}
                        >
                            <MailIcon
                                sx={{
                                    color: "black",
                                    marginRight: "5px",
                                }}
                            />
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    marginRight: "5px",
                                    color: "black",
                                }}
                            >
                                support@nexusupsells.com
                            </Typography>
                        </Box>

                        <Box sx={{ marginTop: "40px", alignItems: "center" }}>
                            <img src={nexusLogo} alt="Nexus Logo" style={{ width: 'auto', height: '100px' }} />
                        </Box>
                    </Stack>
                </Box>
            </HeaderFooterEmpty>
        </Box>
    );
};

const InvalidOrderDesktopUI = () => {
    return (
        <Box sx={{
            flexGrow: 1,
            minWidth: "1050px",
            width: "100%",
            overflowX: "auto",
            scrollBehavior: "smooth",
            background: "white",
        }}>
            <HeaderFooterEmpty isDesktop={true}>
                <Box
                    sx={{
                        bgcolor: "#FDFDFD",
                        flexGrow: 1,
                        width: "100%",
                        overflowX: "auto",
                        overflowY: "auto",
                        paddingBottom: "100px",
                    }}
                >
                    <Stack direction="column" spacing="12px" sx={{
                        alignItems: "center",
                        marginTop: "15vh",
                        marginLeft: "15vw",
                        marginRight: "15vw",
                    }}>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontSize: "30px",
                            fontWeight: "500",
                            lineHeight: "36.31px",
                            textAlign: "center"
                        }}>
                            Your order has expired or been completed.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontSize: "20px",
                            fontWeight: "500",
                            lineHeight: "24.2px",
                            textAlign: "center",
                            marginTop: "2vh",
                        }}>
                            Please return to your host's website to start a new order.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "26.63px",
                            textAlign: "center",
                            marginTop: "35px",
                        }}>
                            Your host partners with Nexus to provide these services.
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Inter",
                            fontWeight: "500",
                            fontSize: "18px",
                            lineHeight: "26.63px",
                            textAlign: "center",
                            marginTop: "0px",
                        }}>
                            If you have any questions, feel free to reach us at:
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "stretch",
                                marginTop: "10px",
                            }}
                        >
                            <PhoneIcon
                                sx={{
                                    color: "black",
                                    marginRight: "5px",
                                }}
                            />
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    marginRight: "5px",
                                    color: "black",
                                }}
                            >
                                385-200-0937
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "stretch",
                            }}
                        >
                            <MailIcon
                                sx={{
                                    color: "black",
                                    marginRight: "5px",
                                }}
                            />
                            <Typography
                                component="div"
                                sx={{
                                    fontFamily: "Inter",
                                    fontWeight: 400,
                                    marginRight: "5px",
                                    color: "black",
                                }}
                            >
                                support@nexusupsells.com
                            </Typography>
                        </Box>

                        <Box sx={{ marginTop: "40px", alignItems: "center" }}>
                            <img src={nexusLogo} alt="Nexus Logo" style={{ width: 'auto', height: '100px' }} />
                        </Box>
                    </Stack>
                </Box>
            </HeaderFooterEmpty>
        </Box>
    );
};


const InvalidOrder = () => {
    const Mobile = useMediaQuery("(max-width:600px)");

    return Mobile ? <InvalidOrderMobileUI /> : <InvalidOrderDesktopUI />;
};

export default InvalidOrder;